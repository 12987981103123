import Box from '@mui/material/Box/Box';
import {
  StyledIconInfo,
  StyledTooltipContent,
  StyledTooltipBody,
  StyledTooltipHeader,
} from './RebalanceTimeTooltip.styles';
import { Typography } from '@mui/material';
import { orange } from 'theme/colors/baseColors';
import { TimeBar } from './components/TimeBar/TimeBar';
import { RebalanceWithDetails } from 'utils/types/rebalance';
import getUTCHours from 'utils/decorators/UTCHours';

export const RebalanceTimeTooltip = ({ rebalance }: { rebalance?: RebalanceWithDetails }) => {
  const isMarketClosed = getUTCHours.hours(new Date()) > 15; // @TODO we should be picking default market close time from rebalance object data
  return (
    <StyledTooltipContent>
      <StyledTooltipHeader>
        <StyledIconInfo />
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '40px' }}>
          <Typography variant="bodyMedium" lineHeight={1.5} fontWeight={600}>
            Price Source at{' '}
            {`${rebalance?.priceAt && getUTCHours.date(rebalance?.priceAt)} ${
              rebalance?.priceAt && getUTCHours.AMPM(rebalance?.priceAt)
            }`}{' '}
            UTC
          </Typography>
          {rebalance?.priceProvider && (
            <Typography variant="bodySmall" fontWeight={400} lineHeight={1.5}>
              Price source is {rebalance?.priceProvider}
            </Typography>
          )}
        </Box>
      </StyledTooltipHeader>
      <StyledTooltipBody gap={2}>
        {isMarketClosed && (
          <Box display={'flex'} flexDirection={'row'} gap={0.5}>
            <>
              <Box
                sx={{
                  borderRadius: '8px',
                  width: '8px',
                  height: '8px',
                  bgcolor: orange[60],
                  alignSelf: 'center',
                }}
              ></Box>
              <Typography variant="bodySmall">MARKET CLOSED</Typography>
            </>
          </Box>
        )}
        {rebalance?.priceAt && (
          <TimeBar
            date={rebalance?.priceAt}
            isCryptoCompare={rebalance.priceProvider === 'cryptocompare'}
            timeZoneOffset={getUTCHours.timezoneOffset()}
            width={324}
          />
        )}
      </StyledTooltipBody>
    </StyledTooltipContent>
  );
};
