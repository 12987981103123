import { NavSingleCounterCard } from './NavSingleCounterCard';
import {
  StyledNavInfoBarWrapper,
  StyledNavInfoBarWrapperCounters,
  StyledNavInfoBarWrapperFilters,
} from 'pages/Ledger/NavReviewPage/components/styles';
import { ParamsType } from 'hooks/useUrlParams';
import colors from 'utils/theme/designTokens/colors';
import SingleDatePicker from 'components/DatepickerSingleDate/index';
import { parseISO, format } from 'date-fns';
import { NavStatusBarInfo } from 'utils/types/nav';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { ProductSelectFilter } from 'pages/Ledger/PCF/components/PCFStyles';

interface NavReviewInforBarProps {
  setQueryParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  queryParams: ParamsType;
  overviewBarInfoData: NavStatusBarInfo | null;
}

export const NavReviewInforBar = ({
  queryParams,
  setQueryParams,
  overviewBarInfoData,
}: NavReviewInforBarProps) => {
  const date =
    queryParams.valuationDate && typeof queryParams.valuationDate === 'string'
      ? parseISO(queryParams.valuationDate)
      : null;

  const inputDateFormat = 'dd/MM/yyyy';

  return (
    <StyledNavInfoBarWrapper>
      <StyledNavInfoBarWrapperCounters>
        <NavSingleCounterCard
          number={overviewBarInfoData?.total}
          numberColor={colors['color-inverse-hover']}
          label={'Total NAVs'}
        ></NavSingleCounterCard>
        <NavSingleCounterCard
          number={overviewBarInfoData?.totalApproved}
          numberColor={colors['color-inverse-success']}
          label={'Approved'}
        ></NavSingleCounterCard>
        <NavSingleCounterCard
          number={overviewBarInfoData?.totalWithError}
          numberColor={colors['color-danger']}
          label={'Discrepancies'}
        ></NavSingleCounterCard>
      </StyledNavInfoBarWrapperCounters>
      <StyledNavInfoBarWrapperFilters>
        <ProductSelectFilter
          placeholder="All instruments"
          clearable
          onChange={(product) =>
            setQueryParams((prevState) => ({ ...prevState, productId: product ?? '' }))
          }
          value={String(queryParams.productId)}
        />
        <SingleDatePicker
          date={date}
          onChange={(date) =>
            setQueryParams((prevState) => ({
              ...prevState,
              valuationDate: format(date || new Date(), DEFAULT_DATE_FORMAT),
            }))
          }
          maxDate={new Date()}
          inputDateFormat={inputDateFormat}
        />
      </StyledNavInfoBarWrapperFilters>
    </StyledNavInfoBarWrapper>
  );
};
