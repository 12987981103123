import GenericTabs from 'shared/GenericTabs';
import WalletsTable from 'shared/Tables/WalletsTable/WalletsTable';
import useAppModal from 'hooks/useAppModal';
import { CompanyCustodianWallet, CompanyOwnWallet, WalletType } from 'utils/types/wallets';
import { MODAL_ACTIONS, WALLET_MODAL } from 'shared/Modals/constants';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { WALLETS_TABS, WalletTabType, defaultWalletsFiltersData } from 'utils/constants/wallets';
import { getWalletsQuery } from 'utils/constants/reactQueries';
import { privateRoutesUrls } from 'router/constants';
import { queryClient } from 'utils/api/queries/queryClient';
import { useAuthenticatedUser, useUserPermissions } from 'store/user/selectors';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useUrlParams } from 'hooks/useUrlParams';
import { useWalletsQuery } from './hooks/useWalletsQuery';

function WalletsPage() {
  const { user } = useAuthenticatedUser();
  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    defaultWalletsFiltersData,
    privateRoutesUrls.dashboardRoutes.adminWallets
  );

  const { data, isLoading: loading } = useWalletsQuery(
    user?.organization?.companyId ?? '',
    'OWN',
    urlParams
  );
  const wallets = data?.data ?? [];
  const pagination = useBackendPagination(data ?? null, setUrlParams);

  const openWalletModal = (
    type: MODAL_ACTIONS,
    wallet?: CompanyOwnWallet | CompanyCustodianWallet
  ) => {
    openModal(
      {
        modalName: WALLET_MODAL,
        modalData: {
          data: { wallet, type: WalletType.OWN_WALLET },
          companyData: {
            id: user?.organization?.companyId,
            name: user?.organization?.name,
          },
          type: type,
          custom: {
            isToken: user?.organization.type === 'AUTHORIZED_MERCHANT',
          },
        },
      },
      {
        onCloseModalAction: () => queryClient.invalidateQueries({ queryKey: [getWalletsQuery] }),
      }
    );
  };
  const canEditWallet = permissions?.isAuthorizedParticipant || permissions?.isAuthorizedMerchant;

  const createNewWallet = canEditWallet && (
    <StyledTableTopButton
      data-qa-id="newWalletButton"
      variant="interactive"
      size="medium"
      onClick={() => openWalletModal(MODAL_ACTIONS.CREATE)}
    >
      <span>New Wallet</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  return (
    <>
      <PageHeader actionComponent={createNewWallet} pageTitle={'Wallets'} />
      <GenericTabs
        active={urlParams.tab as WalletTabType}
        setUrlParamsWithDefaults={(params) =>
          setUrlParamsWithDefaults({ ...params, status: params.tab })
        }
        tabs={WALLETS_TABS}
      >
        <WalletsTable
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          tableProperties={{
            pagination: pagination,
          }}
          deleteAction={(wallet) => openWalletModal(MODAL_ACTIONS.DELETE, wallet)}
          editAction={(wallet) => openWalletModal(MODAL_ACTIONS.EDIT, wallet)}
          activateAction={(wallet) => openWalletModal(MODAL_ACTIONS.CUSTOM, wallet)}
          loadingData={loading}
          wallets={wallets ?? []}
        />
      </GenericTabs>
    </>
  );
}

export default WalletsPage;
