import Button from 'components/Button';
import Table, { TableBody } from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import copyToClipboard from 'utils/copyToClipboard';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { TableHeaderColumn } from 'shared/Tables/Table.types';
import { Typography } from '@mui/material';
import { WalletInfo, Order, ProductType } from 'utils/types';
import { isEmpty } from 'lodash';

const WalletsIssuerColumnsConfig = [
  { label: 'Issuer Wallets', propName: 'issuerWallets', propType: 'string', width: '60%' },
  { label: '', propName: 'actions', propType: 'actions', width: '40%' },
];

const AuthorizedParticipantColumnsConfig = [
  { label: 'AP Wallets', propName: 'apWallets', propType: 'string', width: '60%' },
  { label: '', propName: 'actions', propType: 'actions', width: '40%' },
];

const AuthorizedMerchantColumnsConfig = [
  { label: 'AM Wallets', propName: 'apWallets', propType: 'string', width: '60%' },
  { label: '', propName: 'actions', propType: 'actions', width: '40%' },
];

interface WalletsInformationProps {
  productType: ProductType;
  className?: string;
  order?: Order;
}

const WalletsInformation = ({ productType, className, order }: WalletsInformationProps) => {
  if (isEmpty(order?.product)) return null;

  return (
    <section className={className}>
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Deal Information</Typography>
      </MuiStyledSectionHeader>
      {Boolean(order?.authorizedParticipantWallets?.length) && (
        <WalletTable
          wallets={order?.authorizedParticipantWallets}
          columns={
            productType === 'ETP'
              ? AuthorizedParticipantColumnsConfig
              : AuthorizedMerchantColumnsConfig
          }
          isEtpWallet={productType === 'ETP'}
        />
      )}
      {Boolean(order?.issuerWallets?.length) && (
        <WalletTable
          isEtpWallet={productType === 'ETP'}
          wallets={order?.issuerWallets}
          columns={WalletsIssuerColumnsConfig}
        />
      )}
    </section>
  );
};

export default WalletsInformation;

const WalletTable = ({
  isEtpWallet,
  columns,
  wallets,
}: {
  isEtpWallet?: boolean;
  columns: TableHeaderColumn[];
  wallets?: WalletInfo[];
}) => {
  return (
    <Table noPadding={true}>
      <TableHeaderWithMultiSort columns={columns} />
      <TableBody>
        {wallets?.map((wallet: WalletInfo) => (
          <MuiStyledTableRow key={`wallet-${wallet.ticker}`}>
            <MuiStyledTableCell>
              {!isEtpWallet && wallet.description} {wallet.ticker} {wallet.address}
            </MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              <Button
                data-html2canvas-ignore
                variant="ghost"
                onClick={() => wallet.address && copyToClipboard(wallet.address)}
              >
                <CopyIcon />
              </Button>
            </MuiStyledTableCell>
          </MuiStyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};
