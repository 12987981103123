import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { useCurrentInstrument } from 'store/instruments/selectors';
import InstrumentForm from 'pages/Instruments/components/Form/ETPForm';
import { ETPPageHeader } from 'pages/Instruments/components/ETPPageHeader';
import { instrumentsActions } from 'store/instruments/slice';
import { loadInstrument } from 'store/instruments/actions';

function UpdateInstrument() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { removeCurrentInstrument } = instrumentsActions;
  const { error, instrument, loading } = useCurrentInstrument();

  useEffect(() => {
    if (!error && params.id && params.id !== instrument?._id && !loading) {
      dispatch(loadInstrument({ id: params.id, withWallets: true, withCustodians: true }));
    }
  }, [dispatch, params.id, instrument?._id, error, loading]);

  useEffect(() => {
    return () => {
      dispatch(removeCurrentInstrument());
    };
  }, []);

  return (
    <div>
      <ETPPageHeader instrument={instrument} />
      <InstrumentForm />
    </div>
  );
}

export default UpdateInstrument;
