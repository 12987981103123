import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { WalletModalData } from 'shared/Modals/Wallets/WalletsModal';
import { createNotification } from 'store/notifications/actions';
import { editEntityApiCall } from 'utils/api/crudActions';
import { useMutation } from 'react-query';
import { walletActivatedNotifications } from 'shared/Notifications/wallets.notifications';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const ActivateWalletModal = ({ onCloseModalAction, data, closeModal }: IModalWithData) => {
  const dispatch = useAppDispatch();

  const wallet = (data?.data as WalletModalData).wallet!;
  const activateUriPath = wallet._actions?.activate.uri;

  const activateWalletMutation = useMutation({
    mutationFn: () => editEntityApiCall(wallet, activateUriPath ?? ''),
    onSuccess: () => {
      dispatch(createNotification(walletActivatedNotifications.success(wallet.address)));
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(walletActivatedNotifications.error(error.message), error));
    },
  });

  if (!activateUriPath) return null;

  const handleActivate = () => {
    activateUriPath && activateWalletMutation.mutate();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button
        data-qa-id="activateButton"
        isLoading={activateWalletMutation.isLoading}
        variant="primary"
        fullWidth
        onClick={handleActivate}
        type="button"
      >
        Activate
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        title={<>Are you sure you want to activate wallet {wallet.address}?</>}
        label="Wallets"
        footer={Footer}
        onClose={closeModal}
      />
    </CustomModal>
  );
};
