import GenericTabs from 'shared/GenericTabs';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ProductsTable } from 'shared/Tables/ProductsTable/ProductsTable';
import { Instrument, ProductStatus, Token, TokenStandard } from 'utils/types/product';
import {
  TOKENS_TAB_STATUS,
  TOKENS_TAB_STATUS_LABEL,
  ProductTabType,
  tokenStandardMapping,
} from 'pages/Tokens/Tokens.constants';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { privateRoutesUrls } from 'router/constants';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useUrlParams } from 'hooks/useUrlParams';
import { useUserPermissions } from 'store/user/selectors';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { getProductStatus } from './helpers/getProductStatus';
import { generatePath, useNavigate } from 'react-router-dom';
import { useGetTokensQuery } from 'hooks/useTokens';
import { formatTokenTableURLparams } from './helpers/formatTokenTableURLparams';
import { MODAL_ACTIONS, TOKEN_MODAL } from 'shared/Modals/constants';
import useAppModal from 'hooks/useAppModal';
import { PaginationWithDataResponse } from 'utils/types';

function Tokens() {
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    {
      tab: TOKENS_TAB_STATUS.ACTIVE,
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_ITEMS_PER_PAGE,
      status: ProductStatus.ACTIVE,
    },
    privateRoutesUrls.dashboardRoutes.tokens
  );
  const openModal = useAppModal();

  const permissions = useUserPermissions();
  const pagination = useBackendPagination(null, setUrlParams);
  const navigate = useNavigate();

  const openEdit = async (product: Instrument | Token) => {
    navigate(
      generatePath(privateRoutesUrls.dashboardRoutes.editTokenDetails, {
        id: product._id,
      })
    );
  };

  const openTokenModal = (type: MODAL_ACTIONS, token?: Token, archive?: Boolean) => {
    openModal(
      {
        modalName: TOKEN_MODAL,
        modalData: {
          data: token,
          type: type,
          custom: {
            type: archive ? ProductStatus.ARCHIVED : null,
            isToken: true,
          },
        },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );
  };

  const createTokenButton = [
    permissions?.canCreateToken && (
      <StyledTableTopButton
        onClick={() => navigate(privateRoutesUrls.dashboardRoutes.newTokenDetails)}
        type="button"
        data-qa-id="createTokenButton"
        size="medium"
        key="toolbar-button"
      >
        <span>New Token</span>
        <PlusIcon />
      </StyledTableTopButton>
    ),
  ];

  const params = formatTokenTableURLparams(urlParams);

  const { data, isLoading: loading, refetch } = useGetTokensQuery(params, null, true);
  const tokens = (data as PaginationWithDataResponse<Token[]>)?.data;
  const productList = tokens?.map((token) => {
    return {
      ...token,
      // convert token.tokenStandard to user-friendly format
      tokenStandard:
        (tokenStandardMapping[token.tokenStandard] as TokenStandard) || token.tokenStandard,
    };
  });

  return (
    <>
      <PageHeader actionComponent={createTokenButton} pageTitle={'Tokens'} />
      <GenericTabs
        active={urlParams.tab as string}
        setUrlParamsWithDefaults={(params) => {
          setUrlParamsWithDefaults({
            status: getProductStatus(params.tab as ProductTabType),
            ...params,
          });
        }}
        tabs={[
          [TOKENS_TAB_STATUS_LABEL.ACTIVE, TOKENS_TAB_STATUS.ACTIVE],
          [TOKENS_TAB_STATUS_LABEL.IN_REVIEW, TOKENS_TAB_STATUS.IN_REVIEW],
          [TOKENS_TAB_STATUS_LABEL.PENDING, TOKENS_TAB_STATUS.DRAFT],
          [TOKENS_TAB_STATUS_LABEL.DELETED, TOKENS_TAB_STATUS.DELETED],
        ]}
      >
        <ProductsTable
          productType="Token"
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          pagination={pagination}
          productList={productList}
          editAction={openEdit}
          loading={loading}
          activateAction={(token) => openTokenModal(MODAL_ACTIONS.CREATE, token as Token)}
          deleteAction={(token) => openTokenModal(MODAL_ACTIONS.DELETE, token as Token)}
          archiveAction={(token) => openTokenModal(MODAL_ACTIONS.DELETE, token as Token, true)}
        />
      </GenericTabs>
    </>
  );
}

export default Tokens;
