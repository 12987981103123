import { MuiStyledTableRow } from 'components/Table/TableRow';
import { TABLE_CELL_ALIGNMENTS } from 'shared/Tables/table.utils';
import { TableHeaderColumn } from 'shared/Tables/Table.types';
import { useState } from 'react';
import { Checkbox, CheckboxProps } from 'components/BooleanInputs';
import { MuiStyledTableCellChevron } from 'shared/Tables/ContactsTable/ContactsTable.styles';
import {
  MuiStyledExchangeIcon,
  MuiStyledAscendingIcon,
  MuiStyledDescendingIcon,
  MuiStyledHeaderTableCells,
  StyledInfoSvg,
} from './components/TableHeaderWithMultiSort.styles';
import { ReactNode } from 'react';
import { StyledMuiTableHead } from './components/TableHeader.styles';
import Tooltip from 'components/Tooltip';

const NO_INDEX = -1;

interface TableHeaderWithMultiSortProps {
  columns: TableHeaderColumn[];
  columnAlignment?: { [key: string]: TABLE_CELL_ALIGNMENTS };
  onColumnSort?: (columns: string[]) => void;
  defaultSortedColumns?: string[] | string;
  showActionsCell?: boolean;
  checkboxProps?: CheckboxProps;
  showChevronColumn?: boolean;
  showInfoIcon?: boolean;
  toolTipContent?: ReactNode;
  toolTipArrow?: boolean;
}

const TableHeaderWithMultiSort = ({
  checkboxProps,
  columnAlignment = {},
  columns,
  defaultSortedColumns = [],
  onColumnSort,
  showActionsCell,
  showChevronColumn,
  toolTipContent,
  toolTipArrow = false,
}: TableHeaderWithMultiSortProps) => {
  const [sortedColumns, setSortedColumns] = useState<string[]>(
    Array.isArray(defaultSortedColumns) ? defaultSortedColumns : [defaultSortedColumns]
  );

  const [hoveredCellIndex, setHoveredCellIndex] = useState<number | null>(null);

  const findColumnIndex = (columns: string[], col: TableHeaderColumn, order: 'asc' | 'desc') => {
    return columns.findIndex((prop) => prop === `${col.propName}:${order}`);
  };

  const handleColumnClick = (col: TableHeaderColumn) => {
    if (onColumnSort && col.sort !== false) {
      const sortedAsc = findColumnIndex(sortedColumns, col, 'asc');
      const sortedDesc = findColumnIndex(sortedColumns, col, 'desc');

      let columnsTemp = [...sortedColumns];

      if (sortedDesc === NO_INDEX && sortedAsc === NO_INDEX) {
        columnsTemp = [...columnsTemp, `${col.propName}:asc`];
      } else if (sortedAsc > NO_INDEX) {
        columnsTemp[sortedAsc] = `${col.propName}:desc`;
      } else if (sortedDesc > NO_INDEX) {
        columnsTemp = columnsTemp.filter((column) => column !== `${col.propName}:desc`);
      }

      onColumnSort && onColumnSort(columnsTemp);
      setSortedColumns(columnsTemp);
    }
  };

  return (
    <StyledMuiTableHead>
      <MuiStyledTableRow>
        {checkboxProps && (
          <MuiStyledHeaderTableCells padding="checkbox">
            <Checkbox {...checkboxProps} />
          </MuiStyledHeaderTableCells>
        )}
        {showChevronColumn && <MuiStyledTableCellChevron />}
        {columns
          .filter((col) => !col.hideColumn)
          .map((col, index) => (
            <MuiStyledHeaderTableCells
              key={`${col.label}_${index}`}
              align={columnAlignment[col.propName] ?? 'left'}
              onClick={() => handleColumnClick(col)}
              colSpan={col.colSpan}
              onMouseEnter={() => setHoveredCellIndex(index)}
              onMouseLeave={() => setHoveredCellIndex(null)}
              style={{
                cursor: col.sort !== false ? 'pointer' : undefined,
                width: col.width,
                minWidth: col.minWidth,
              }}
            >
              {col.label}
              {col.sort != false &&
                findColumnIndex(sortedColumns, col, 'asc') === NO_INDEX &&
                findColumnIndex(sortedColumns, col, 'desc') === NO_INDEX && (
                  <MuiStyledExchangeIcon
                    hovered={index === hoveredCellIndex ? 'true' : undefined}
                  />
                )}
              {findColumnIndex(sortedColumns, col, 'asc') > NO_INDEX && (
                <MuiStyledAscendingIcon hovered={index === hoveredCellIndex ? 'true' : undefined} />
              )}
              {findColumnIndex(sortedColumns, col, 'desc') > NO_INDEX && (
                <MuiStyledDescendingIcon
                  hovered={index === hoveredCellIndex ? 'true' : undefined}
                />
              )}
              {col.showInfoIcon && (
                <Tooltip
                  arrow={toolTipArrow}
                  placement="bottom"
                  title={toolTipContent}
                  inline
                  wide
                  bgColor="white"
                >
                  <StyledInfoSvg fontSize={12} />
                </Tooltip>
              )}
            </MuiStyledHeaderTableCells>
          ))}
        {showActionsCell && (
          <MuiStyledHeaderTableCells
            style={{
              width: '8%',
            }}
          />
        )}
      </MuiStyledTableRow>
    </StyledMuiTableHead>
  );
};

export default TableHeaderWithMultiSort;
