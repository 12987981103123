import GenericTabs from 'shared/GenericTabs';
import UsersTable from 'shared/Tables/UsersTable/UsersTable';
import useAppModal from 'hooks/useAppModal';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { PartnersUser } from 'utils/types';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { USER_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { USER_TAB_STATUS, USERS_STATUS_LABEL } from 'utils/constants/users';
import { privateRoutesUrls } from 'router/constants';
import { useAuthenticatedUser, useUserPermissions } from 'store/user/selectors';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useState } from 'react';
import { useUrlParams } from 'hooks/useUrlParams';
import { useUsersPerCompany } from 'pages/Partners/hooks/useUsersPerCompany';

export const UsersPage = () => {
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    { page: DEFAULT_PAGE, pageSize: DEFAULT_ITEMS_PER_PAGE, tab: USER_TAB_STATUS.ACTIVE },
    privateRoutesUrls.dashboardRoutes.adminUsers
  );

  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const loggedInUser = useAuthenticatedUser();
  const [refreshData, setRefreshData] = useState(false);
  const {
    users,
    loading,
    pagination: paginationResponse,
  } = useUsersPerCompany(loggedInUser.user?.organization.companyId ?? '', refreshData, urlParams);
  const pagination = useBackendPagination(paginationResponse, setUrlParams);

  const canInviteUser = permissions?.canInviteCompanyUsers;

  const openUserModal = (type: MODAL_ACTIONS, user?: PartnersUser, reset2FA?: boolean) => {
    openModal(
      {
        modalName: USER_MODAL,
        modalData: {
          data: user,
          companyData: {
            id: loggedInUser.user?.organization.companyId,
            name: loggedInUser.user?.organization.name,
          },
          type: type,
          custom: {
            reset2FA: Boolean(reset2FA),
          },
        },
      },
      {
        onCloseModalAction: () => setRefreshData(!refreshData),
      }
    );
  };

  const createUserButton = canInviteUser && (
    <StyledTableTopButton
      data-qa-id="inviteUserButton"
      variant="interactive"
      size="medium"
      onClick={() => openUserModal(MODAL_ACTIONS.CREATE)}
    >
      <span>Invite User</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  return (
    <>
      <PageHeader actionComponent={createUserButton} pageTitle={'Users'} />
      <GenericTabs
        active={urlParams.tab as string}
        setUrlParamsWithDefaults={setUrlParamsWithDefaults}
        tabs={[
          [USERS_STATUS_LABEL.ACTIVE, USER_TAB_STATUS.ACTIVE],
          [USERS_STATUS_LABEL.PENDING, USER_TAB_STATUS.INVITED],
        ]}
      >
        <UsersTable
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          tableProperties={{
            pagination,
          }}
          users={users?.length ? users : []}
          loadingData={loading}
          deleteAction={(user) => openUserModal(MODAL_ACTIONS.DELETE, user)}
          editAction={(user) => openUserModal(MODAL_ACTIONS.EDIT, user)}
          resendInvitationAction={(user) => openUserModal(MODAL_ACTIONS.CUSTOM, user)}
          reset2FA={(user) => openUserModal(MODAL_ACTIONS.CUSTOM, user, true)}
        />
      </GenericTabs>
    </>
  );
};
