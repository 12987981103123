import { PropsWithChildren } from 'react';

import TabNavigation from 'components/TabNavigation';
import { TAB_TYPE } from 'components/TabNavigation/TabNavigation.types';

export const INSTRUMENT_DETAILS_TAB = 'ETP Details';
export const PARTNER_DETAILS_TAB = 'Partner Details';

interface ETPsTabProps extends PropsWithChildren {
  active: string;
  onChangeTab?: (activeTab: string) => void;
}

function ETPTabs({ active, children, onChangeTab }: ETPsTabProps) {
  const tabs = [
    {
      name: INSTRUMENT_DETAILS_TAB,
      onSelect: () => {
        if (onChangeTab) {
          onChangeTab(INSTRUMENT_DETAILS_TAB);
        }
      },
    },
    {
      name: PARTNER_DETAILS_TAB,
      onSelect: () => {
        if (onChangeTab) {
          onChangeTab(PARTNER_DETAILS_TAB);
        }
      },
    },
  ];

  return (
    <TabNavigation active={active} tabs={tabs} type={TAB_TYPE.CONTAINER}>
      {children}
    </TabNavigation>
  );
}

export default ETPTabs;
