import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TokenForm from 'pages/Tokens/components/Form/TokenForm';
import { TokensPageHeader } from 'pages/Tokens/components/TokensPageHeader';
import { useTokenContext } from 'contexts/tokens';
import { useTokenQuery } from 'pages/Tokens/hooks/useTokenQuery';
import Loader from 'components/Loader';

function Token() {
  const params = useParams();
  const { currentToken, setCurrentToken } = useTokenContext();
  const { isLoading } = useTokenQuery(
    params,
    Boolean(params.id && params.id !== currentToken?._id)
  );

  useEffect(() => {
    return () => {
      setCurrentToken(null);
    };
  }, []);

  return (
    <div>
      <TokensPageHeader
        token={currentToken}
        isNewProduct={!Boolean(params.id && params.id !== currentToken?._id)}
      />
      {isLoading ? <Loader /> : <TokenForm />}
    </div>
  );
}

export default Token;
