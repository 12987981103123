import Table, { TableHead, TableRow, TableCell, TableBody } from 'components/Table';
import {
  useCurrentInstrumentFormData,
  useDirtyInstrumentFormData,
  useFormDataDirtyFields,
} from 'store/instruments/selectors';
import formatFieldName from 'pages/Instruments/components/Form/EtpDetails/components/ReviewStep/utils/formatFieldName';
import { CompanySuggestionDto } from 'utils/types';
import { MARKET_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { MarketDetailsProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { PartnerType } from 'utils/types/partner';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { useCallback } from 'react';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';

export const MarketDifferencesSection = () => {
  const { deleteUpdatedField } = useSaveETPHook();
  const instrumentFormsData = useCurrentInstrumentFormData(MARKET_STEP);
  const dirtyInstrumentFormsData = useDirtyInstrumentFormData(MARKET_STEP);
  const formDataDirtyFields = useFormDataDirtyFields(MARKET_STEP);

  const { data: companiesSuggestions } = useCompaniesSuggestion({
    type: PartnerType.AUTHORIZED_PARTICIPANT,
  });

  const getMarketMakerFieldsValues = useCallback(
    (
      dirtyInstrumentFormsData: MarketDetailsProps,
      fieldName: string,
      suggestions: CompanySuggestionDto[]
    ) => {
      let fieldValue = (dirtyInstrumentFormsData as { [key: string]: string | string[] })[
        fieldName
      ];
      if (fieldName === 'marketMakers') {
        const selectedMarketMakers = fieldValue as string[];
        fieldValue = suggestions
          ?.filter((suggestion) => selectedMarketMakers?.includes(suggestion._id))
          .map((suggestion) => suggestion.name)
          .join(', ');
      }
      return fieldValue;
    },
    []
  );

  return formDataDirtyFields.length && companiesSuggestions?.length ? (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell min-width="20%">Name</TableCell>
            <TableCell width="35%">Old Value</TableCell>
            <TableCell width="35%">Proposed Value</TableCell>
            <TableCell width="32px"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formDataDirtyFields?.map((selectedValue, index) => (
            <TableRow key={`${selectedValue + index}`}>
              <TableCell>{formatFieldName(selectedValue)}</TableCell>
              <TableCell>
                {getMarketMakerFieldsValues(
                  instrumentFormsData as MarketDetailsProps,
                  selectedValue,
                  companiesSuggestions
                )}
              </TableCell>
              <TableCell>
                {getMarketMakerFieldsValues(
                  dirtyInstrumentFormsData as MarketDetailsProps,
                  selectedValue,
                  companiesSuggestions
                )}
              </TableCell>
              <TableCell>
                <TrashIcon
                  onClick={() => {
                    deleteUpdatedField(selectedValue);
                  }}
                  style={{ paddingLeft: 0 }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  ) : null;
};
