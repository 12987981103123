import Button from 'components/Button';
import Card from 'components/Card';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import { Checkbox } from 'components/BooleanInputs';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { ProductExchangeModalData } from 'shared/Modals/ProductExchange/ProductExchangeModal';
import { ProductStatus } from 'utils/types/product';
import { StyledInfoFrame } from './CreateProductExchangeModal.styles';
import { useCurrentInstrument, useCurrentInstrumentFormData } from 'store/instruments/selectors';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { EXCHANGE_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import {
  ExchangeType,
  ExchangesProps,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { saveInstrumentApiCall } from 'utils/api/instruments';
import { instrumentsActions } from 'store/instruments/slice';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { MuiStyledFooterWrapper } from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';

export const CreateProductExchangeModal = ({
  closeModal,
  onCloseModalAction,
  data,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const { instrument } = useCurrentInstrument();
  const { exchanges, exchangeFormData } = data?.data as ProductExchangeModalData;
  const isNewExchange = data.type === MODAL_ACTIONS.CREATE;
  const exchangesOptions = isNewExchange
    ? exchanges?.map((exchange) => ({
        label: exchange.name,
        value: exchange._id,
      }))
    : exchanges
        ?.map((exchange) => ({
          label: exchange.name,
          value: exchange._id,
        }))
        .concat([{ label: exchangeFormData?.name ?? '', value: exchangeFormData?.id ?? '' }]);

  const productExchangesFormData = useCurrentInstrumentFormData(EXCHANGE_STEP) as ExchangesProps;
  const { populateCurrentInstrument } = instrumentsActions;

  const [exchange, setExchange] = useState<ExchangeType | undefined>(exchangeFormData);
  const [isPrimary, setIsPrimary] = useState<boolean>(Boolean(exchangeFormData?.isPrimary));
  const [isConstituentExchange, setIsConstituentExchange] = useState<boolean>(
    Boolean(exchangeFormData?.constituentExchange)
  );

  const createUpdateProductExchangeMutation = useMutation({
    mutationFn: (updatedExchangesList: ExchangeType[]) => {
      return saveInstrumentApiCall(instrument?._id ?? '', {
        [EXCHANGE_STEP]: updatedExchangesList,
      });
    },
    onSuccess: (res) => {
      dispatch(populateCurrentInstrument(res));
      dispatch(
        createNotification({
          message: isNewExchange
            ? 'Product Exchange has been added to the product.'
            : `Product Exchange has been updated successfully`,
          title: 'Product Updated',
          type: 'success',
        })
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(errorNotification(err.message ?? ''), err));
    },
  });

  const newEchangeUpdate = (productExchange: ExchangeType) => {
    if (productExchangesFormData.length < 1) {
      createUpdateProductExchangeMutation.mutate([
        {
          ...productExchange,
          isPrimary: true,
        },
      ] as ExchangeType[]);
    } else {
      const updatedExchangesList = productExchangesFormData.map((exc) => {
        if (productExchange.isPrimary === true) return { ...exc, isPrimary: false };
        return exc;
      });
      createUpdateProductExchangeMutation.mutate([
        ...updatedExchangesList,
        productExchange,
      ] as ExchangeType[]);
    }
  };

  const existingExchangeUpdate = (productExchange: ExchangeType) => {
    if (exchange?.isPrimary !== isPrimary && !isPrimary) {
      return dispatch(
        createNotification({
          message: `Product needs to have one primary Exchange.`,
          title: 'Primary Exchange Update Failed',
          type: 'error',
        })
      );
    }
    const updatedExchangesList = productExchangesFormData.map((exc) => {
      if (exc.id === String(productExchange.id)) {
        return productExchange;
      } else if (isPrimary === true) return { ...exc, isPrimary: false };
      return exc;
    });
    createUpdateProductExchangeMutation.mutate(updatedExchangesList as ExchangeType[]);
  };

  const handleSubmit = () => {
    const productExchange = {
      name: exchange?.name ?? '',
      id: exchange?.id ?? '',
      isPrimary: isPrimary,
      constituentExchange: isConstituentExchange,
      localTickers: exchange?.localTickers,
    };
    if (isNewExchange) {
      newEchangeUpdate(productExchange);
    } else {
      existingExchangeUpdate(productExchange);
    }
  };

  const Footer = (
    <MuiStyledFooterWrapper>
      <Button data-qa-id="cancelButton" variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
      <Button
        data-qa-id="addButton"
        disabled={!exchange}
        fullWidth
        onClick={handleSubmit}
        type="button"
        isLoading={createUpdateProductExchangeMutation.isLoading}
      >
        {instrument?.status === ProductStatus.ACTIVE ? 'Apply and Save' : 'Apply'}
      </Button>
    </MuiStyledFooterWrapper>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card footer={Footer} label="Exchanges" title="Select Exchange" onClose={closeModal}>
        <Select
          data-qa-id="Exchange Name"
          label="Exchange Name"
          placeholder="Select"
          options={exchangesOptions}
          onChange={(exchangeId) => {
            const selectedExchange = exchanges?.find((exchange) => exchange?._id === exchangeId);
            setExchange({ id: selectedExchange?._id ?? '', name: selectedExchange?.name ?? '' });
          }}
          value={exchange?.id}
          disabled={!isNewExchange}
        />
        <Checkbox
          data-qa-id="Primary Exchange"
          label="Primary Exchange"
          onChange={() => {
            if (!isPrimary) {
              setIsConstituentExchange(false);
            }
            setIsPrimary(!isPrimary);
          }}
          checked={isPrimary}
        />
        <StyledInfoFrame>
          <div>This exchange will be the primary listing exchange for this product</div>
        </StyledInfoFrame>
        <Checkbox
          data-qa-id="Constituent Exchange"
          label="Constituent Exchange"
          onChange={() => {
            if (!isConstituentExchange) {
              setIsPrimary(false);
            }
            setIsConstituentExchange(!isConstituentExchange);
          }}
          checked={isConstituentExchange}
        />
        <StyledInfoFrame>
          <div>
            This exchange is linked to a constituent of this product, not directly to the product
            itself.
          </div>
        </StyledInfoFrame>
      </Card>
    </CustomModal>
  );
};
