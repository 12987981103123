import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { RebalanceDetailsTableRow } from './RebalanceDetailsTableRow';
import { RebalanceDetailsTableConfig } from './tableConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { isEmpty } from 'lodash';
import { RebalanceDetails, RebalanceWithDetails } from 'utils/types/rebalance';
import { RebalanceDetailsTotalsTableRow } from './RebalanceDetailsTotalsTableRow';
import { renderSaving } from 'pages/Instruments/components/SaveProgress';
import { StyledCalculating } from './EditableTableCell/EditableTableCell.styles';
import { RebalanceTimeTooltip } from './components/RebalanceTimeTooltip/RebalanceTimeTooltip';

interface RebalanceDetailsTableProps {
  rebalance?: RebalanceWithDetails;
  initialRebalance?: RebalanceWithDetails;
  isLoading?: boolean;
  editRowData: (data: RebalanceDetails) => void;
  isCalculating?: boolean;
}

const renderCalculating = (isCalculating?: boolean) => {
  return isCalculating ? <StyledCalculating>{renderSaving('')}</StyledCalculating> : null;
};

export const RebalanceDetailsTable = ({
  isLoading = false,
  editRowData,
  rebalance,
  initialRebalance,
  isCalculating,
}: RebalanceDetailsTableProps) => {
  const toolTipContent = <RebalanceTimeTooltip rebalance={rebalance} />;

  return (
    <Table title={<>Rebalance Details {renderCalculating(isCalculating)}</>}>
      <TableHeaderWithMultiSort
        showActionsCell={false}
        columns={RebalanceDetailsTableConfig}
        columnAlignment={{
          price: 'right',
          balancePre: 'right',
          targetWeight: 'right',
        }}
        toolTipContent={toolTipContent}
        toolTipArrow
      />
      <TableBodyWithStates
        loadingData={Boolean(isLoading)}
        hasContent={!isEmpty(rebalance?.details)}
        noContentLabel="There is no Rebalance details at the moment."
      >
        {rebalance?.details.map((details, index) => (
          <RebalanceDetailsTableRow
            key={index}
            rebalanceDetails={details}
            updateRow={editRowData}
            initialRebalanceDetails={initialRebalance?.details[index]}
            rebalance={rebalance}
          />
        ))}
        {rebalance?.detailsSum && (
          <RebalanceDetailsTotalsTableRow
            rebalanceDetails={rebalance?.detailsSum}
            rebalance={rebalance}
          />
        )}
      </TableBodyWithStates>
    </Table>
  );
};
