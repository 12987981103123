import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import { StyledCustomHeader } from 'pages/Orders/Register/RegisterDetails/RegisterDetails.styles';
import PageTitle from 'components/PageTitle';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { Partner } from 'utils/types';
import { BackButton } from 'shared/BackButton';

interface PartnerDetailsHedearProps {
  company: Partner | undefined;
}

export const PartnerDetailsHedear = ({ company }: PartnerDetailsHedearProps) => {
  return (
    <PageHeader
      customComponent={
        <StyledCustomHeader>
          <BackButton />
          <StyledTableCellContentWithIcon>
            {company ? (
              <PageTitle>{company?.name} Partner Details</PageTitle>
            ) : (
              <PageTitle>Create Partner</PageTitle>
            )}
          </StyledTableCellContentWithIcon>
        </StyledCustomHeader>
      }
      customComponentPosition="start"
    />
  );
};
