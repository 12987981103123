import Card from 'components/Card';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { PCFDetailsListColumnConfig } from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/tableColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table from 'components/Table';
import { PortfolioComposition } from 'utils/types/pcfs';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';

export interface PCFBenchmarkInformationProps {
  pcf?: PortfolioComposition;
  isLoading?: boolean;
}

function PCFBenchmarkInformation({ pcf, isLoading }: PCFBenchmarkInformationProps) {
  return (
    <Card title="Benchmark Information">
      <Table noPadding>
        <TableHeaderWithMultiSort columns={PCFDetailsListColumnConfig} />
        <TableBodyWithStates
          loadingData={isLoading}
          hasContent={!!(pcf?.product?.underling?.name && pcf?.product?.underling?.isin)}
          noContentLabel="No PCF data."
        >
          <PCFCompositionRow pcf={pcf} />
        </TableBodyWithStates>
      </Table>
    </Card>
  );
}

export default PCFBenchmarkInformation;

const PCFCompositionRow = ({ pcf }: { pcf?: PortfolioComposition }) => {
  return (
    <>
      <MuiStyledTableRow>
        <MuiStyledTableCell>Benchmark</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product?.underling?.name}</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.official?.product?.underling.name}</MuiStyledTableCell>
      </MuiStyledTableRow>
      <MuiStyledTableRow>
        <MuiStyledTableCell>Benchmark ISIN</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product?.underling?.isin}</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.official?.product?.underling.isin}</MuiStyledTableCell>
      </MuiStyledTableRow>
    </>
  );
};
