import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import ProductIcon from 'components/ProductIcon';
import PageTitle from 'components/PageTitle';
import { StyledCustomHeader } from 'pages/Orders/Register/RegisterDetails/RegisterDetails.styles';
import { SecuritiesRegister } from 'pages/Orders/Register/types';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { BackButton } from 'shared/BackButton';

interface RegisterDetailsHeaderProps {
  registerProductData?: SecuritiesRegister;
}

export const RegisterDetailsHeader = ({ registerProductData }: RegisterDetailsHeaderProps) => {
  return (
    <PageHeader
      customComponent={
        <StyledCustomHeader>
          <BackButton />
          <StyledTableCellContentWithIcon>
            {registerProductData && (
              <>
                <ProductIcon
                  className="product-icon"
                  iconUrl={registerProductData?.fundIcon}
                  ticker={registerProductData?.ticker}
                />
                <PageTitle>{registerProductData?.ticker}</PageTitle>
                <PageTitle>{' Register Details'}</PageTitle>
              </>
            )}
          </StyledTableCellContentWithIcon>
        </StyledCustomHeader>
      }
      customComponentPosition="start"
    />
  );
};
