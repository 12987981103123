import { useState } from 'react';
import { UserSettingsProfile } from './Profile/UserSettingsProfile';
import TabNavigation from 'components/TabNavigation';
import { UserSettingsSecurity } from './Security/UserSettingsSecurity';
import PageTitle from 'components/PageTitle';
import { format, parseISO } from 'date-fns';
import { useAuthenticatedUser } from 'store/user/selectors';
import { dateFormat } from 'shared/Tables/table.utils';
import { StyledUserSettingPageHeader } from './UserSettingsPage.styles';

enum USER_SETTINGS_LABEL {
  PROFILE = 'Profile',
  SECURITY = 'Security',
}

function renderCurrentTab(activeTab: USER_SETTINGS_LABEL) {
  const tabs = {
    [USER_SETTINGS_LABEL.PROFILE]: UserSettingsProfile,
    [USER_SETTINGS_LABEL.SECURITY]: UserSettingsSecurity,
  };
  const Tab = tabs[activeTab];
  return <Tab />;
}

export const UserSettingsPage = () => {
  const [tab, setTab] = useState<USER_SETTINGS_LABEL>(USER_SETTINGS_LABEL.PROFILE);
  const { user } = useAuthenticatedUser();

  return (
    <>
      <StyledUserSettingPageHeader>
        <PageTitle>User Settings</PageTitle>
        {user?.updatedAt && (
          <span>Last updated: {format(parseISO(user.updatedAt.toString()), dateFormat)}</span>
        )}
      </StyledUserSettingPageHeader>
      <TabNavigation
        active={tab}
        tabs={[
          {
            name: USER_SETTINGS_LABEL.PROFILE,
            onSelect: () => setTab(USER_SETTINGS_LABEL.PROFILE),
          },
          {
            name: USER_SETTINGS_LABEL.SECURITY,
            onSelect: () => setTab(USER_SETTINGS_LABEL.SECURITY),
          },
        ]}
      >
        {renderCurrentTab(tab)}
      </TabNavigation>
    </>
  );
};
