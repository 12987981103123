import { styled } from '@mui/material';
import { TableHead as MuiTableHead } from '@mui/material';

export const StyledMuiTableHead = styled(MuiTableHead)(({ theme }) => ({
  '& .MuiTableRow-root': {
    borderRadius: theme.spacers['spacing-08'],
  },
  '& .MuiTableCell-root': {
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightSemiBold,
    backgroundColor: theme.palette.customBackground.default.strong,
  },
}));
