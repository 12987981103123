import { PortfolioComposition } from 'utils/types/pcfs';

import Card from 'components/Card';
import Table, { TableBody, TableCell, TableRow } from 'components/Table';

interface PCFEtpInformationProps {
  pcf?: PortfolioComposition;
}

function PCFEtpInformation({ pcf }: PCFEtpInformationProps) {
  return (
    <Card title="ETP Information" subtitle={pcf?.product?.name}>
      <Table noPadding>
        <TableBody>
          <TableRow>
            <TableCell>Currency</TableCell>
            <TableCell align="right">{pcf?.product.baseCurrency}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Product ISIN</TableCell>
            <TableCell align="right">{pcf?.product.isin}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell align="right">{pcf?.product.name}</TableCell>
          </TableRow>
          {pcf?.currencyPrices?.map((currencyPrice) => (
            <TableRow key={currencyPrice.baseCurrency}>
              <TableCell>Total NAV {currencyPrice.baseCurrency}</TableCell>
              <TableCell align="right">
                {
                  pcf?.official?.totalNav?.find(
                    (total) => total.currency === currencyPrice.baseCurrency
                  )?.value
                }
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>Total Units Outstanding</TableCell>
            <TableCell align="right">{pcf?.official?.totalUnitsOutstanding}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Creation Unit Size</TableCell>
            <TableCell align="right">{pcf?.product?.unitSize}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}

export default PCFEtpInformation;
