import useAppDispatch from 'hooks/useAppDispatch';
import { ParamsType } from 'hooks/useUrlParams';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { fetchAllPCFs } from 'utils/api/pcfs';
import { getProductsTradingToday } from 'utils/api/instruments';
import { useQuery } from 'react-query';
import { useAuthenticatedUser } from 'store/user/selectors';
import { PartnerType } from 'utils/types/partner';
import { PCF_TAB_STATUS } from 'utils/constants/pcf';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { format } from 'date-fns';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import { usePCFsQuery } from 'utils/constants/reactQueries';
import { User } from 'utils/types';
import { SerializedError } from '@reduxjs/toolkit';
import { DateTime } from 'utils/datetime';

const statusDefaultParams = (
  user: {
    error: string | SerializedError | null;
    loading: boolean;
    user: User | null;
  },
  urlParams: ParamsType
) => {
  const currentField =
    user.user?.organization.type === PartnerType.AUTHORIZED_PARTICIPANT
      ? 'effectiveDate'
      : 'valuationDate';

  let sortParam = [`${currentField}:desc`];
  if (urlParams.sort) {
    sortParam = Array.isArray(urlParams.sort)
      ? [...urlParams.sort, ...sortParam]
      : [urlParams.sort, ...sortParam];
  }

  const defaultParams =
    urlParams?.tab === PCF_TAB_STATUS.PAST
      ? {
          [`${currentField}End`]: format(new DateTime().getPreviousDay(1), DEFAULT_DATE_FORMAT),
          ...urlParams,
          sort: urlParams.sort ?? sortParam,
        }
      : {
          ...urlParams,
          [currentField]: format(new Date(), DEFAULT_DATE_FORMAT),
        };

  return defaultParams;
};

export const usePCFSQuery = (urlParams: ParamsType, enabled?: boolean) => {
  const dispatch = useAppDispatch();
  const user = useAuthenticatedUser();

  return useQuery(
    [usePCFsQuery, urlParams],
    async () => {
      const canOverridePcf = user.user?.permissions?.canOverridePcf;
      const params = {
        ...urlParams,
        ...statusDefaultParams(user, urlParams),
        ...(!canOverridePcf && { status: PortfolioCompositionStatus.APPROVED }),
      };
      try {
        const { data } = await fetchAllPCFs(params);
        const { trading, notTrading } = await getProductsTradingToday();
        return {
          data: data.data,
          pagination: { page: data.page, pageSize: data.pageSize, total: data.total },
          overviewBarInfo: {
            total: data.total,
            totalConfirmed: data.totalConfirmed,
            totalPendingApproval: data.totalPendingApproval,
            totalPendingUpload: data.totalPendingUpload,
          },
          trading: trading,
          notTrading: notTrading,
        };
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: enabled,
    }
  );
};
