import * as React from 'react';
import { ComponentPropsWithoutRef } from 'react';
import { TAB_TYPE, TabProps } from 'components/TabNavigation/TabNavigation.types';
import { Box, Tab, Tabs } from '@mui/material';
import {
  StyledTabsContainer,
  MuiTabContentContainer,
} from 'components/TabNavigation/TabNavigation.styles';

// Info: activeParam added for backward compatibility, in the futere we can use active prop to pass value instead of label
export interface TabNavigationProps extends ComponentPropsWithoutRef<'div'> {
  active?: TabProps['name'];
  activeParam?: TabProps['param'];
  tabs: Array<TabProps>;
  type?: TAB_TYPE;
}

const isActive = (param1: string, param2?: string) => {
  if (param1 === param2) return 'true';
  return 'false';
};

function TabNavigation({
  active,
  tabs,
  children,
  type = TAB_TYPE.DEFAULT,
  activeParam,
}: TabNavigationProps) {
  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    const selectedTab = tabs.find((tab) => tab.name === newValue);
    if (selectedTab && selectedTab.onSelect) {
      selectedTab.onSelect(newValue);
    }
  };

  return (
    <>
      <Tabs value={activeParam || active} onChange={handleTabChange} aria-label="tab navigation">
        {tabs.map((tab, index) => {
          return (
            <Tab
              tabIndex={0}
              key={index}
              label={<Box>{tab.name}</Box>}
              value={tab.name}
              active={activeParam ? isActive(activeParam, tab.param) : isActive(tab.name, active)}
              size={tab.size ?? 'large'}
            />
          );
        })}
      </Tabs>
      {children && (
        <MuiTabContentContainer tabtype={type} active={'false'}>
          {children}
        </MuiTabContentContainer>
      )}
    </>
  );
}

export { StyledTabsContainer as TabsContainer };

export default TabNavigation;
