import { Box, Typography } from '@mui/material';
import getUTCHours from 'utils/decorators/UTCHours';
import { TimeSlider } from './TimeSlider';

function isBeforeTime(dateHours: number, closingTime: number) {
  return dateHours < closingTime;
}

function getAMPM(hourOfDay: number = 17) {
  const isPM = hourOfDay >= 12;
  const formattedHours = hourOfDay % 12 || 12;
  const amPm = isPM ? 'PM' : 'AM';
  return `${formattedHours} ${amPm}`;
}

const getSliderValue = (
  dateHoursUTC: number,
  isDuringWorkingHours: Boolean,
  border: number,
  range: number
) => {
  return isDuringWorkingHours
    ? 100 - ((border - dateHoursUTC) / range) * 100
    : ((dateHoursUTC - border) / range) * 100;
};

const getLengthOfGreenRange = (
  isInRange: Boolean,
  dateHoursZurichTime: number,
  closingTime: number,
  openingTime: number
) => {
  if (isInRange && dateHoursZurichTime > closingTime) {
    if (dateHoursZurichTime - openingTime > 12) return 12;
    return dateHoursZurichTime - openingTime;
  }
  return 8;
};

// Default Start time is used for the TimeBar presentation
export const TimeBar = ({
  date,
  timeZoneOffset = 0,
  startTime = 9,
  width = 300,
  isCryptoCompare,
}: {
  date: Date | string;
  startTime?: number;
  timeZoneOffset?: number;
  width?: number;
  isCryptoCompare: boolean;
}) => {
  // Since we are using Zurich time as default we need utc values for calculations
  const zurichToUTCtimeZoneOffset = 2;
  const zurichClosingTimeInUTC = 17 - zurichToUTCtimeZoneOffset;
  const zurichOpeningTimeInUTC = 9 - zurichToUTCtimeZoneOffset;
  const dateHoursUTC = getUTCHours.hours(date) + new Date(date).getUTCMinutes() / 60;
  const currentHours = getUTCHours.hours(new Date());

  // In case of crytpocompare price if it is provided after closing hours we are extending closing hours
  const hoursOfGreenRange = getLengthOfGreenRange(
    isCryptoCompare,
    dateHoursUTC,
    zurichClosingTimeInUTC,
    zurichOpeningTimeInUTC
  );

  // Time border that is used for split between green and dark bar part
  const timeBorder = Math.round(startTime + hoursOfGreenRange);

  const isDuringWorkingHours = isBeforeTime(currentHours, timeBorder);

  const sliderBarPositionValue = getSliderValue(
    currentHours,
    isDuringWorkingHours,
    timeBorder,
    isDuringWorkingHours ? hoursOfGreenRange : 12 - hoursOfGreenRange
  );

  return (
    <Box width={`${width}px`}>
      <Box display={'flex'} justifyContent={'space-between'} marginBottom={0.5}>
        <Typography variant="bodySmall">Cryptocompare source</Typography>
        <Typography variant="bodySmall">Index provider source</Typography>
      </Box>
      <Box height={'6px'} display={'flex'} marginBottom={0.5}>
        <TimeSlider
          withingWorkingHours={isDuringWorkingHours}
          workingHoursRangeLength={(hoursOfGreenRange * 100) / 12}
          nonWorkingHoursRangeLength={100 - (hoursOfGreenRange * 100) / 12}
          sliderValue={sliderBarPositionValue}
        />
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} marginBottom={0.5}>
        <Typography
          fontSize={'10px'}
          lineHeight={'10px'}
          marginLeft={`${width * ((timeBorder - startTime) / 12) - 10}px`}
        >
          {`${getAMPM(timeBorder)}`}
        </Typography>
      </Box>
    </Box>
  );
};
