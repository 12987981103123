import ProductSelector from 'components/ProductSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { RegisterPdf } from './RegisterPdf/RegisterPdf';
import { RegisterTable } from 'shared/Tables/RegisterTable/RegisterTable';
import { SecuritiesRegister } from './types';
import { StyledToolbar } from './Register.styles';
import { compileUrl } from 'utils/url';
import { createNotification } from 'store/notifications/actions';
import { downloadPdf, downloadXLS } from 'pages/Orders/Register/utils';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { getSecuritiesRegisterProductData } from 'utils/api/securities-registry';
import { privateRoutesUrls } from 'router/constants';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegistryQuery } from './hooks/useRegistry';
import { useUrlParams } from 'hooks/useUrlParams';

export const Register = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { urlParams, setUrlParams } = useUrlParams({}, '/securities-registry');
  const { data: paginationResponse, isLoading } = useRegistryQuery(urlParams);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [singleRegister, setSingleRegister] = useState<SecuritiesRegister>();

  useEffect(() => {
    setUrlParams((urlParams) => ({ ...urlParams, productId: selectedProduct }));
  }, [selectedProduct, setUrlParams]);

  // This is done this way since we need to render RegisterPdf first in order to access it in downloadPdf function
  useEffect(() => {
    if (singleRegister) {
      downloadPdf(singleRegister);
    }
  }, [singleRegister]);

  const downloadSingleRegister = useCallback(
    (register: SecuritiesRegister, downloadType: 'PDF' | 'XLS') => {
      if (register._id === singleRegister?._id) {
        if (downloadType === 'PDF') {
          setSingleRegister(register);
        } else {
          downloadXLS(singleRegister);
        }
      } else {
        getSecuritiesRegisterProductData(register._id)
          .then((res) => {
            if (downloadType === 'PDF') {
              setSingleRegister(res.data);
            } else {
              downloadXLS(res.data);
            }
          })
          .catch((err) => {
            const error = err as Error;
            dispatch(createNotification(errorNotification(error.message ?? ''), error));
          });
      }
    },
    [dispatch, singleRegister]
  );

  const RegisterToolbar = (
    <StyledToolbar>
      <ProductSelector
        clearable
        onChange={(product) => setSelectedProduct(product ?? '')}
        value={selectedProduct}
      />
    </StyledToolbar>
  );

  return (
    <>
      <PageHeader pageTitle={'Register'} />
      <RegisterTable
        urlParams={urlParams}
        setUrlParams={setUrlParams}
        registers={paginationResponse?.data ?? []}
        editAction={(register) => {
          const viewRegisterItemDetailsUrl = compileUrl(
            privateRoutesUrls.dashboardRoutes.registerDetails,
            {
              label: 'id',
              value: String(register._id!),
            }
          );
          navigate(viewRegisterItemDetailsUrl, {
            state: { product: selectedProduct },
          });
        }}
        loadingData={isLoading}
        tableProperties={{
          toolbarTools: [RegisterToolbar],
        }}
        onDonwloadPdf={(register: SecuritiesRegister) => {
          downloadSingleRegister(register, 'PDF');
        }}
        onDonwloadXLS={(register: SecuritiesRegister) => {
          downloadSingleRegister(register, 'XLS');
        }}
      />
      <RegisterPdf singleRegister={singleRegister} />
    </>
  );
};
