/* eslint-disable complexity */
import Product from 'components/Product';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import Tag from 'components/Tag';
import Tooltip from 'components/Tooltip';
import { format } from 'date-fns';
import titelize from 'utils/decorators/titelize';
import {
  StyledButtonsTagWrapper,
  StyledTagButton,
} from 'shared/Tables/OrdersTable/OrdersTable.styles';
import { Deliverable, Order } from 'utils/types/orders';
import { useButtonsActions } from 'pages/Orders/Orders/hooks/useButtonsActions';
import { useUserPermissions } from 'store/user/selectors';
import { dailyTableDateFormat } from 'shared/Tables/table.utils';
import { replace } from 'lodash';
import { orderStatusTagVariants } from 'utils/constants/orders';
import { variantTypes } from 'components/Tag/Tag.types';
import { TableHeaderColumn } from 'shared/Tables/Table.types';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
import useAppModal from 'hooks/useAppModal';
import { DEFER_ORDER_MODAL, MODAL_ACTIONS, ORDERS_MODAL } from 'shared/Modals/constants';
import { useMemo } from 'react';
import { parseDate } from 'utils/date';
import DeliverableValue from 'pages/Orders/Orders/Order/components/OrderDetails/components/DeliverableValue';
import { getOrdersQueryKey, getTokenOrdersQueryKey } from 'utils/constants/reactQueries';
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import Button from 'components/Button';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { ProductType } from 'utils/types/product';
import { common } from 'theme/utils/paletteColors';

const renderDeliverables = (deliverablesEntries: Deliverable[]) => {
  if (deliverablesEntries.length === 0) {
    return `${deliverablesEntries.length}`;
  } else if (deliverablesEntries.length === 1) {
    return `${deliverablesEntries[0].amount}`;
  } else {
    return (
      <Tooltip
        title={
          <Box p={1}>
            {deliverablesEntries.map((entry, index) => (
              <Stack direction="row" spacing={1} key={`${index}-${entry.ticker}`}>
                <Typography color={common.white} variant="bodyMedium">
                  {entry.ticker}:
                </Typography>
                <Typography color={common.white} variant="bodyMedium">
                  {entry.amount}
                </Typography>
              </Stack>
            ))}
          </Box>
        }
      >
        <Tag label={`${deliverablesEntries.length} deliverables`} variant="05" size="small" />
      </Tooltip>
    );
  }
};

type OrdersTableProps = {
  productType: ProductType;
  order: Order;
  editAction: () => void;
  tableColumns: TableHeaderColumn[];
};

export const OrdersTableRow = ({
  productType,
  order,
  editAction,
  tableColumns,
}: OrdersTableProps) => {
  const stateVariant = order.status ? orderStatusTagVariants[order.status] : '01';
  const permissions = useUserPermissions();
  const actions = useButtonsActions(
    order,
    productType === 'ETP' ? getOrdersQueryKey : getTokenOrdersQueryKey,
    productType
  );

  const shouldShowCompanyName = permissions?.isIssuer || permissions?.isSystemAdmin;
  const openModal = useAppModal();
  const filteredActions = useMemo(
    () =>
      actions.filter(
        (action) =>
          action.modal !== DEFER_ORDER_MODAL && action.status !== order.status && action.permission
      ),
    [actions, order.status]
  );

  return (
    <MuiStyledTableRow key={order._id} onClick={editAction}>
      {tableColumns.map((column) => {
        switch (column.propName) {
          case 'product.ticker':
            return (
              <MuiStyledTableCell nowrap="true" key={column.propName + order._id}>
                <Stack direction="row" spacing={2}>
                  <Product instrument={order.product} />
                </Stack>
              </MuiStyledTableCell>
            );
          case 'companyName':
            return (
              shouldShowCompanyName && (
                <MuiStyledTableCell nowrap="true" key={column.propName + order._id}>
                  {order.createdBy.onBehalfOf?.companyName ?? order?.createdBy?.companyName}
                </MuiStyledTableCell>
              )
            );
          case 'type':
            return (
              <MuiStyledTableCell nowrap="true" key={column.propName + order._id}>
                <Tag size="small" label={titelize(order.type)} variant={'01'} />
              </MuiStyledTableCell>
            );
          case 'deliveryType':
            return (
              <MuiStyledTableCell nowrap="true" key={column.propName + order._id}>
                <Tag size="small" label={titelize(order.deliveryType)} variant={'01'} />
              </MuiStyledTableCell>
            );
          case 'externalId':
            return (
              <MuiStyledTableCell nowrap="true" key={column.propName + order._id}>
                {order.externalId}
              </MuiStyledTableCell>
            );
          case 'numberOfUnits':
            return (
              <MuiStyledTableCell nowrap="true" align="right" key={column.propName + order._id}>
                {order.numberOfUnits}
              </MuiStyledTableCell>
            );
          case 'amount':
            return (
              <MuiStyledTableCell nowrap="true" align="right" key={column.propName + order._id}>
                <DeliverableValue order={order} expectedTotalCash cashValue />
              </MuiStyledTableCell>
            );
          case 'dealDate':
            return (
              <MuiStyledTableCell nowrap="true" key={column.propName + order._id}>
                {order.dealDate
                  ? format(new Date(parseDate(order.dealDate)), dailyTableDateFormat)
                  : ''}
              </MuiStyledTableCell>
            );
          case 'settlement.date':
            return productType === 'ETP' ? (
              <MuiStyledTableCell nowrap="true" align="center" key={column.propName + order._id}>
                {order.settlement?.date
                  ? format(new Date(parseDate(order.settlement.date)), dailyTableDateFormat)
                  : ''}
              </MuiStyledTableCell>
            ) : (
              <MuiStyledTableCell nowrap="true" align="center" key={column.propName + order._id}>
                {order?.settlementDate}
              </MuiStyledTableCell>
            );
          case 'status':
            return (
              <MuiStyledTableCell nowrap="true" key={column.propName + order._id}>
                {filteredActions.length > 0 ? (
                  <TablePopoverMenu
                    id={order._id ?? order.createdAt}
                    anchor={
                      <StyledTagButton
                        label={replace(titelize(order.status), 'Ap ', 'AP ')}
                        variant={stateVariant as variantTypes}
                        expandable
                        size="small"
                      />
                    }
                    anchorOrigin={{ vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'center' }}
                  >
                    <StyledButtonsTagWrapper>
                      {filteredActions.map((action, index) => (
                        <Button
                          fullWidth
                          variant="ghost"
                          onClick={() =>
                            openModal({
                              modalName: ORDERS_MODAL,
                              modalData: {
                                data: {
                                  targetStatus: action.status,
                                  order,
                                  refreshQueryKey:
                                    productType === 'Token'
                                      ? getTokenOrdersQueryKey
                                      : getOrdersQueryKey,
                                },
                                type: MODAL_ACTIONS.CUSTOM,
                                custom: {
                                  isToken: productType === 'Token',
                                },
                              },
                            })
                          }
                          key={action.label + index}
                        >
                          <StyledTagButton
                            label={replace(titelize(action.status), 'Ap ', 'AP ')}
                            variant={orderStatusTagVariants[action.status] as variantTypes}
                            size="small"
                          />
                        </Button>
                      ))}
                    </StyledButtonsTagWrapper>
                  </TablePopoverMenu>
                ) : (
                  <StyledTagButton
                    label={replace(titelize(order.status), 'Ap ', 'AP ')}
                    variant={stateVariant as variantTypes}
                    size="small"
                  />
                )}
              </MuiStyledTableCell>
            );
          case 'deliveries.expected':
            return (
              <MuiStyledTableCell nowrap="true" align="right" key={column.propName + order._id}>
                {order.deliveries?.expected && renderDeliverables(order.deliveries?.expected)}
              </MuiStyledTableCell>
            );
          case 'deliveries.actual':
            return (
              <MuiStyledTableCell nowrap="true" align="right" key={column.propName + order._id}>
                {order.deliveries?.actual && renderDeliverables(order.deliveries.actual)}
              </MuiStyledTableCell>
            );
          case 'actions':
            return (
              <MuiStyledActionsTableCell data-qa-id="actionBtn">
                <TablePopoverMenu id={order._id} className="actionsCell">
                  <Button
                    data-qa-id="deferSettlement"
                    fullWidth
                    variant="ghost"
                    onClick={() => {
                      const deferAction = actions.find(
                        (action) => action.modal === DEFER_ORDER_MODAL
                      )!;
                      openModal({
                        modalName: deferAction.modal,
                        modalData: {
                          data: {
                            targetStatus: deferAction.status,
                            order,
                            refreshQueryKey: getOrdersQueryKey,
                          },
                          type: MODAL_ACTIONS.CUSTOM,
                        },
                      });
                    }}
                  >
                    Defer Settlement
                  </Button>
                </TablePopoverMenu>
              </MuiStyledActionsTableCell>
            );
          default:
            return null;
        }
      })}
    </MuiStyledTableRow>
  );
};
