import { Box, styled as MuiStyled } from '@mui/material';

export const StyledContainer = MuiStyled('section')`
  background-color: ${({ theme }) => theme.palette.common.white};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  overflow-y: auto;
`;

export const StyledInformationWrapper = MuiStyled(Box)`
  background: #FCEDCF;
  height: 56px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 24px 16px;
`;

export const StyledText = MuiStyled(Box)`
  font-size: 14px;
  word-wrap: break-word;
  text-align: center;
  padding: 8px;
  line-height: 20px;
`;

export const StyledCustomHeader = MuiStyled(Box)`
  display: flex;
  gap: 24px;
  h4 {
    align-self: center;
  }
  .product-icon {
    align-self: center;
    span {
      font-size: 16px;
      height: 32px;
      width: 32px;
      line-height: 32px;
    }
    img {
      height: 32px;
      width: 32px;
    }
  }
`;
