import { PortfolioComposition } from 'utils/types/pcfs';

import Card from 'components/Card';
import Table, { TableBody, TableCell, TableRow } from 'components/Table';

interface PCFUnderlyingInformationProps {
  pcf?: PortfolioComposition;
}

function PCFUnderlyingInformation({ pcf }: PCFUnderlyingInformationProps) {
  return pcf?.official?.product?.underling?.name && pcf?.official?.product?.underling?.isin ? (
    <Card title="Underlying Information">
      <Table noPadding>
        <TableBody>
          <TableRow>
            <TableCell>{pcf?.official?.product?.underling.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{pcf?.official?.product?.underling.isin}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  ) : null;
}

export default PCFUnderlyingInformation;
