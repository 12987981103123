import {
  add,
  format,
  getDate,
  getDay,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  set,
  startOfDay,
  sub,
  subDays,
  toDate,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

type DateTimeInput = string | Date | null | undefined | DateTime;

type DateTimeUnit = 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds';

export enum WeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

// TODO: would be nice to extend this class same as on backend, in futher would be nice to replace date-fns with dayjs
export class DateTime {
  private value: Date;

  constructor(input?: DateTimeInput) {
    if (input instanceof DateTime) {
      this.value = new Date(input.value);
    } else if (input instanceof Date) {
      this.value = new Date(input);
    } else {
      this.value = input ? new Date(input) : new Date();
    }
  }

  static now(): DateTime {
    return new DateTime();
  }

  static default(): DateTime {
    return new DateTime('1970-01-01T00:00:00');
  }

  add(value: number, unit: DateTimeUnit): DateTime {
    return new DateTime(add(this.value, { [unit]: value }));
  }

  subtract(value: number, unit: DateTimeUnit): DateTime {
    return new DateTime(sub(this.value, { [unit]: value }));
  }

  toISOString(): string {
    return this.value.toISOString();
  }

  isSameDate(date: DateTime): boolean {
    return isEqual(startOfDay(this.value), startOfDay(date.value));
  }

  isSameDateTime(date: DateTime): boolean {
    return isEqual(this.value, date.value);
  }

  isSameTime(date: DateTime): boolean {
    return format(this.value, 'HH:mm:ss') === format(date.value, 'HH:mm:ss');
  }

  isValid(): boolean {
    return isValid(this.value);
  }

  utc(): DateTime {
    return new DateTime(this.value);
  }

  format(formatString: string): string {
    return format(this.value, formatString);
  }

  toDateString(): string {
    return format(this.value, 'yyyy-MM-dd');
  }

  toDateTimeString(): string {
    return format(this.value, "yyyy-MM-dd'T'HH:mm:ss");
  }

  toTimeString(): string {
    return format(this.value, 'HH:mm:ss');
  }

  toDate(): Date {
    return toDate(this.value);
  }

  set(unit: DateTimeUnit, value: number): DateTime {
    return new DateTime(set(this.value, { [unit]: value }));
  }

  tzAware(timezone?: string): DateTime {
    return new DateTime(utcToZonedTime(this.value, timezone || 'UTC'));
  }

  toHourMinutes(): string {
    return format(this.value, 'HH:mm');
  }

  tzOffset(): string {
    return format(this.value, 'XXX');
  }

  roundToMinuteFloor(minuteFloor: number): DateTime {
    const roundedMinutes = Math.floor(getDate(this.value) / minuteFloor) * minuteFloor;
    const date = set(this.value, { minutes: roundedMinutes, seconds: 0, milliseconds: 0 });
    return new DateTime(date);
  }

  isGreaterThan(input: DateTime): boolean {
    return isAfter(this.value, input.value);
  }

  isGreaterOrEqualTo(input: DateTime): boolean {
    return isAfter(this.value, input.value) || isEqual(this.value, input.value);
  }

  isLessThan(input: DateTime): boolean {
    return isBefore(this.value, input.value);
  }

  isLessOrEqualThan(input: DateTime): boolean {
    return isBefore(this.value, input.value) || isEqual(this.value, input.value);
  }

  getFullYear(): number {
    return getYear(this.value);
  }

  getMonth(): number {
    return getMonth(this.value); // 0 - 11
  }

  getWeekday(): WeekDay {
    const days = [
      WeekDay.SUNDAY,
      WeekDay.MONDAY,
      WeekDay.TUESDAY,
      WeekDay.WEDNESDAY,
      WeekDay.THURSDAY,
      WeekDay.FRIDAY,
      WeekDay.SATURDAY,
    ];
    const dayOfWeek = getDay(this.value); // Day of Week (Sunday as 0, Saturday as 6)
    return days[dayOfWeek];
  }

  getPreviousDay(daysInPast: number): Date {
    const today = new Date();
    return subDays(today, daysInPast);
  }

  convertToTz(timezone?: string): DateTime {
    return new DateTime(utcToZonedTime(this.value, timezone || 'UTC'));
  }
}
