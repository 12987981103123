/* eslint-disable complexity */
import BigNumber from 'bignumber.js';
import { pickBy, identity, isString, isNil } from 'lodash';

import { ConstituentAssetStatus, Instrument } from 'utils/types/product';
import {
  GENERAL_DETAILS_STEP,
  MARKET_STEP,
  BENCHMARK_STEP,
  CONSTITUENT_STEP,
  EXCHANGE_STEP,
  FACTSHEET_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import {
  PARTNERS_STEP,
  CUSTODIAN_INFO_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { InstrumentFormDataProps } from 'pages/Instruments/Instruments.types';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { DateTime } from 'utils/datetime';

function mapPropToList(prop?: { id: string }[]): string[] | undefined {
  return prop?.map((property) => property.id);
}

function parseInstrumentToFormData(instrument: Instrument | null): InstrumentFormDataProps {
  return {
    [GENERAL_DETAILS_STEP]: pickBy(
      {
        adminCreationFee:
          instrument?.fees?.adminCreationFee &&
          new BigNumber(instrument?.fees?.adminCreationFee).toString(),
        adminRedemptionFee:
          instrument?.fees?.adminRedemptionFee &&
          new BigNumber(instrument?.fees?.adminRedemptionFee).toString(),
        allowedDeliveries: instrument?.allowedDeliveries,
        annualFee: instrument?.annualFee,
        currency: instrument?.currency,
        quoteCurrency: instrument?.quoteCurrency,
        inKindCutOffTime: instrument?.cutoffs?.inKindCutOffTime,
        cashCutOffTime: instrument?.cutoffs?.cashCutOffTime,
        fundIcon: instrument?.fundIcon,
        isin: instrument?.isin,
        jurisdiction: instrument?.jurisdiction,
        name: instrument?.name,
        productType: instrument?.type,
        seriesLetter: instrument?.seriesLetter,
        seriesName: instrument?.seriesName,
        standardSettlement: instrument?.standardSettlement,
        ticker: instrument?.ticker,
        unitSize: instrument?.unitSize ? `${instrument?.unitSize}` : null,
        // NAV related props
        pricingTypeThreshold: instrument?.pricingTypeThreshold,
        navPlusCreationExecutionFee:
          instrument?.fees?.navPlusCreationExecutionFee &&
          new BigNumber(instrument?.fees?.navPlusCreationExecutionFee).toString(),
        navPlusRedemptionExecutionFee:
          instrument?.fees?.navPlusRedemptionExecutionFee &&
          new BigNumber(instrument?.fees?.navPlusRedemptionExecutionFee).toString(),
        navGuaranteedCreationExecutionFee:
          instrument?.fees?.navGuaranteedCreationExecutionFee &&
          new BigNumber(instrument?.fees?.navGuaranteedCreationExecutionFee).toString(),
        navGuaranteedRedemptionExecutionFee:
          instrument?.fees?.navGuaranteedRedemptionExecutionFee &&
          new BigNumber(instrument?.fees?.navGuaranteedRedemptionExecutionFee).toString(),
      },
      identity
    ),
    [MARKET_STEP]: pickBy(
      {
        bloombergTicker: instrument?.bloombergTicker,
        collateralAgent: instrument?.collateralAgent,
        cusip: instrument?.cusip,
        inav: instrument?.inav,
        kurzel: instrument?.kurzel,
        marketMakers: instrument?.marketMakers,
        reutersTicker: instrument?.reutersTicker,
        sedol: instrument?.sedol,
        valor: instrument?.valor,
        wkn: instrument?.wkn,
      },
      identity
    ),
    [BENCHMARK_STEP]: pickBy(
      {
        indexProvider:
          instrument?.indexProvider &&
          isString(instrument?.indexProvider) &&
          instrument?.indexProvider,
        indexProviderSpecificId:
          instrument?.indexProviderSpecificId &&
          isString(instrument?.indexProviderSpecificId) &&
          instrument?.indexProviderSpecificId,
        rebalancingFrequency:
          instrument?.rebalancingFrequency &&
          isString(instrument?.rebalancingFrequency) &&
          instrument?.rebalancingFrequency,
        rebalancingStrategy:
          instrument?.rebalancingStrategy &&
          isString(instrument?.rebalancingStrategy) &&
          instrument?.rebalancingStrategy,
        underlyingCurrency: instrument?.underlyingCurrency,
        underlyingIsin: instrument?.underlyingIsin,
        underlyingName: instrument?.underlyingName,
        underlyingTicker: instrument?.underlyingTicker,
        underlyingWkn: instrument?.underlyingWkn,
      },
      identity
    ),
    [CONSTITUENT_STEP]: instrument?.constituentAssets?.map((constituent) => ({
      _id: constituent._id,
      coingeckoId: constituent.coingeckoId ?? undefined,
      ticker: constituent.ticker,
      name: constituent.name,
      assetType: constituent.assetType,
      rounding: constituent.rounding,
      isStaking: constituent.isStaking,
      isMev: constituent.isMev,
      stakingFee: constituent.stakingFee,
      rebalanceFeeBuyBps: constituent.rebalanceFeeBuyBps,
      rebalanceFeeSellBps: constituent.rebalanceFeeSellBps,
      interestGenerating: constituent.interestGenerating,
      isLeveraged: constituent.isLeveraged,
      leverageRatio: constituent.leverageRatio,
      pairAsset: constituent.pairAsset,
      isActive: constituent.status === ConstituentAssetStatus.ACTIVE,
      updatedAt: constituent.updatedAt && new DateTime(constituent.updatedAt).toDateTimeString(),
      updatedBy: constituent.updatedBy,
    })),
    [EXCHANGE_STEP]: instrument?.exchanges?.map((exchange) => ({
      id: exchange.id,
      name: exchange.name,
      isPrimary: exchange.primary,
      localTickers: exchange.localTickers.map((localTicker) => ({
        ...localTicker,
        listingDate:
          localTicker.listingDate && format(new Date(localTicker.listingDate), DEFAULT_DATE_FORMAT),
      })),
      constituentExchange: exchange.constituentExchange,
    })),
    [FACTSHEET_STEP]: pickBy(
      {
        ucitsEligibility: instrument?.factsheet?.ucitsEligibility,
        lendingEligibility: instrument?.factsheet?.lendingEligibility,
        replicationMethod: instrument?.factsheet?.replicationMethod,
        domicile: instrument?.factsheet?.domicile,
        legalStructure: instrument?.factsheet?.legalStructure,
        shariahCompliant: instrument?.factsheet?.shariahCompliant,
        registeredCountries: instrument?.factsheet?.registeredCountries,
      },
      (value) => !isNil(value)
    ),
    [PARTNERS_STEP]: pickBy(
      {
        issuer: instrument?.issuer?.id,
        pcfUploaders: mapPropToList(instrument?.partners?.pcfUploaders),
        authorizedParticipants: mapPropToList(instrument?.authorizedParticipants),
        custodians: Array.isArray(instrument?.partners?.custodians)
          ? instrument?.partners?.custodians
          : undefined,
        calculationAgents: mapPropToList(instrument?.partners?.calculationAgents),
        technicalListingAgent: instrument?.partners?.technicalListingAgent?.id,
      },
      identity
    ),
    [CUSTODIAN_INFO_STEP]:
      instrument?.custodianAccounts?.map((custodian) => ({
        _id: custodian._id,
        apiKey: custodian.apiKey,
        apiPassphrase: custodian.apiPassphrase,
        provider: custodian.provider,
        name: custodian.name,
        description: custodian.description,
        signatureKey: custodian.signatureKey,
        status: custodian.status,
      })) || [],
    [WALLETS_INFO_STEP]: {
      custodianWallets:
        instrument?.custodianWallets?.map((wallet) => ({
          _id: wallet._id,
          address: wallet.address,
          chain: wallet.chain,
          constituentId: wallet.constituentId,
          custodianAccount: wallet.custodianAccount,
          description: wallet.description,
          idAtCustodian: wallet.idAtCustodian,
          transactingCompany: wallet.transactingCompany,
          status: wallet.status,
          _actions: wallet._actions,
        })) || [],
      unifiedWallets:
        instrument?.unifiedWallets?.map((wallet) => ({
          address: wallet.address,
          chain: wallet.chain,
          constituentId: wallet.constituentId,
          custodianAccount: wallet.custodianAccount,
          description: wallet.description,
          idAtCustodian: wallet.idAtCustodian,
          transactingCompany: wallet.transactingCompany,
          status: wallet.status,
        })) || [],
    },
  };
}

export default parseInstrumentToFormData;
