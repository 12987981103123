import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { PartnersUser } from 'utils/types';
import { SuccessRequest } from 'utils/types/auth';
import { createEntityApiCallWithCustomResponse } from 'utils/api/crudActions';
import { createNotification } from 'store/notifications/actions';
import { successNotification } from 'shared/Notifications/general.notifications';
import { useMutation } from 'react-query';
import { userCreatedNotifications } from 'shared/Notifications/users.notifications';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const Reset2FA = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const user = data.data as PartnersUser;
  const usersName = `${user.firstName} ${user.lastName}`;

  const reset2FAMutation = useMutation({
    mutationFn: () =>
      createEntityApiCallWithCustomResponse<string, SuccessRequest>(user?._actions.reset2fa.uri),
    onSuccess: (res) => {
      dispatch(
        createNotification(successNotification(`${res.data.message} ${usersName}`, '2FA reset'))
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(userCreatedNotifications.error(error.message), error));
    },
  });

  const handleUpdate = () => {
    reset2FAMutation.mutate();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={handleUpdate}
        type="button"
        data-qa-id="resendButton"
      >
        Reset 2FA
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        onClose={closeModal}
        footer={Footer}
        title={
          <>
            Are you sure you want to reset 2FA of <b>{usersName}</b>?
          </>
        }
        label={'User'}
      ></Card>
    </CustomModal>
  );
};
