import Button from 'components/Button';
import Card from 'components/Card';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import ProductSelector from 'components/ProductSelector';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import { CONTACT_LIST_TYPE, ContactList, ContactListRequestDto } from 'utils/types/contacts';
import { DEFAULT_PAGE, UNLIMITED_ITEMS_PER_PAGE } from 'shared/Tables/table.utils';
import { IModalWithData } from 'shared/Modals/types';
import { PartnerType } from 'utils/types/partner';
import { Toggle } from 'components/BooleanInputs';
import { contactListSchemaUrl } from 'components/Form/formSchemas';
import { generatePath, useNavigate } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';
import { useEffect, useMemo, useState } from 'react';
import { CustomModal } from 'shared/Modals/Modal';
import { useRequestCompaniesMappedQuery } from 'pages/Admin/ContactsPage/hooks/api/useRequestCompaniesMappedQuery';
import { createContactList, updateContactList } from 'utils/api/contacts';
import { useMutation } from 'react-query';
import { createNotification } from 'store/notifications/actions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { useAuthenticatedUser } from 'store/user/selectors';

export const EditContactListModal = ({ closeModal, onCloseModalAction, data }: IModalWithData) => {
  const navigate = useNavigate();
  const { user } = useAuthenticatedUser();
  const [values, setValues] = useState<Partial<ContactList>>();
  const [contactlistType, setContactListType] = useState<CONTACT_LIST_TYPE>();
  const dispatch = useAppDispatch();

  const { data: partnerOptions, isLoading: isLoadingPartners } = useRequestCompaniesMappedQuery(
    { page: DEFAULT_PAGE, pageSize: UNLIMITED_ITEMS_PER_PAGE },
    contactlistType === CONTACT_LIST_TYPE.ORDER ||
      contactlistType === CONTACT_LIST_TYPE.CONSOLIDATED_PCFS
  );

  const authorizedParticipantsOptions = useMemo(
    () =>
      partnerOptions?.filter(
        (company) =>
          company.type === PartnerType.AUTHORIZED_PARTICIPANT ||
          company.type === PartnerType.AUTHORIZED_MERCHANT
      ),
    [partnerOptions]
  );

  const issuersOptions = useMemo(
    () => partnerOptions?.filter((company) => company.type === PartnerType.ISSUER),
    [partnerOptions]
  );

  useEffect(() => {
    if (data?.type) {
      setContactListType((data.data as ContactList)?.type);
    }
  }, [data?.data, data?.type]);

  const contactList = data.data as ContactList;
  const isEdit = Boolean(contactList);
  const isValid = !Boolean(values?.name) || !Boolean(values?.type);
  const handleOnChange = (
    contactList: Partial<Omit<ContactList, '_actions' | 'product' | 'issuer'>>
  ) => {
    setValues(contactList);
  };

  const createUpdateContactlistMutation = useMutation({
    mutationFn: (contactList: ContactListRequestDto) => {
      const action = isEdit ? updateContactList : createContactList;
      return action(contactList);
    },
    onSuccess: (res, contactList) => {
      dispatch(
        createNotification(
          successNotification(
            `${contactList.name} list has been updated successfully.`,
            'Contact list updated'
          )
        )
      );
      navigate(
        generatePath(privateRoutesUrls.dashboardRoutes.adminContactsListEdit, {
          id: res.data._id,
        })
      );
      if (onCloseModalAction) {
        onCloseModalAction();
      }
      closeModal();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const handleSubmit = (contactList: ContactListRequestDto) => {
    if (
      contactlistType === CONTACT_LIST_TYPE.CONSOLIDATED_PCFS &&
      user?.organization.type === PartnerType.ISSUER
    ) {
      createUpdateContactlistMutation.mutate({
        ...contactList,
        issuerId: user?.organization.companyId,
      });
      return;
    }

    createUpdateContactlistMutation.mutate(contactList);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button data-qa-id="cancelButton" fullWidth variant="secondary" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        data-qa-id="addButton"
        disabled={isValid}
        isLoading={createUpdateContactlistMutation.isLoading}
        fullWidth
        variant="interactive"
        type="submit"
      >
        {isEdit ? 'Save' : 'Add'}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Form
        initialValues={{
          ...contactList,
          sendAsBcc: Boolean(contactList?.sendAsBcc ?? true),
          ...(contactList?.product?.id && { productId: contactList?.product?.id }),
          ...(contactList?.issuer?.id && { issuerId: contactList?.issuer?.id }),
        }}
        schemaUrl={contactListSchemaUrl}
        onSubmit={handleSubmit}
        onChange={handleOnChange}
      >
        <Card
          footer={Footer}
          label={isEdit ? `Edit Distribution List` : 'Add Distribution List'}
          title={isEdit ? `Edit ${contactList.name}` : 'Distribution List Info'}
          onClose={closeModal}
        >
          <Input name="name" />
          <Select
            data-qa-id="type"
            data-qa-options-id="type"
            name="type"
            onChange={setContactListType}
          />
          {contactlistType === CONTACT_LIST_TYPE.PRODUCT && (
            <ProductSelector clearable fullWidth name="productId" />
          )}
          {contactlistType === CONTACT_LIST_TYPE.ORDER && (
            <Select
              name="orderCompanyAp"
              disabled={isLoadingPartners}
              options={authorizedParticipantsOptions}
            />
          )}
          {contactlistType === CONTACT_LIST_TYPE.CONSOLIDATED_PCFS &&
            user?.organization.type !== PartnerType.ISSUER && (
              <Select name="issuerId" disabled={isLoadingPartners} options={issuersOptions} />
            )}
          <Toggle
            data-qa-id="bccToggle"
            label="On"
            name="sendAsBcc"
            sizeVariant="large"
            title="Send emails as Bcc (recipients are unaware of each other)"
            switchLabelWithTitle
          />
        </Card>
      </Form>
    </CustomModal>
  );
};
