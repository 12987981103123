import { ReactNode } from 'react';
import { FULL_SPAN, MuiStyledEmptyTableCell } from './TableCell';
import CircularProgress from 'components/CircularProgress';
import { MuiStyledEmptyTableRow, MuiStyledTableRow } from './TableRow';
import TableBody from './TableBody';

interface TableBodyWithStatesProps {
  children: ReactNode;
  loadingData?: Boolean;
  hasContent?: Boolean;
  noContentLabel?: string | ReactNode;
  'data-qa-id'?: string;
}

const TableBodyWithStates = ({
  children,
  loadingData,
  hasContent,
  noContentLabel = 'No content.',
  ...props
}: TableBodyWithStatesProps) => {
  const muiContent = hasContent ? (
    children
  ) : (
    <MuiStyledTableRow>
      <MuiStyledEmptyTableCell colSpan={FULL_SPAN}>{noContentLabel}</MuiStyledEmptyTableCell>
    </MuiStyledTableRow>
  );

  return (
    <TableBody {...props}>
      {loadingData ? (
        <MuiStyledEmptyTableRow>
          <MuiStyledEmptyTableCell colSpan={FULL_SPAN}>
            <CircularProgress />
          </MuiStyledEmptyTableCell>
        </MuiStyledEmptyTableRow>
      ) : (
        muiContent
      )}
    </TableBody>
  );
};

export default TableBodyWithStates;
