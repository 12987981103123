import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { ExchangeCalendar } from 'utils/types';
import { Order } from 'utils/types/orders';
import { format, addBusinessDays } from 'date-fns';
import { zonedTimeToUtc as zonedTimeToDate } from 'date-fns-tz';

const ONE_DAY = 1;

function isHoliday(date: Date, calendar?: ExchangeCalendar) {
  const dateFormatted = format(date, DEFAULT_DATE_FORMAT);

  return Boolean(calendar?.events?.find((event) => event.date === dateFormatted));
}

function getNextBusinessDay(date: Date, calendar?: ExchangeCalendar) {
  let nextBusinessDay = addBusinessDays(date, ONE_DAY);
  while (isHoliday(nextBusinessDay, calendar)) {
    nextBusinessDay = addBusinessDays(nextBusinessDay, ONE_DAY);
  }

  return nextBusinessDay;
}

function getOpenTime(product?: Order['product']) {
  const opensAt =
    product && product.primaryExchange.calendar.timezone
      ? zonedTimeToDate(
          `${format(new Date(), DEFAULT_DATE_FORMAT)} ${product.primaryExchange.marketOpen}`,
          product.primaryExchange.calendar.timezone
        )
      : new Date();

  return getNextBusinessDay(opensAt, product?.primaryExchange.calendar);
}

export default getOpenTime;
