//constants
import { NOTIFICATION_TIME } from 'shared/Notifications/Notifications.constants';
import { UNAUTHORIZED_ERROR_MESSAGE } from 'utils/constants/errors';
//store
import notificationsSlice from 'store/notifications/slice';
// types
import { Notification, NotificationWithoutId } from 'utils/types';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';

export const { removeNotification, addNotification } = notificationsSlice.actions;

export const createNotification =
  (notification: NotificationWithoutId, error?: Error | AxiosError | String) =>
  (dispatch: Dispatch) => {
    const id = `${Math.floor(Math.random() * Date.now())}`;
    const newNotification: Notification = { ...notification, id };
    if (
      (typeof error === 'string' && error.toUpperCase() === UNAUTHORIZED_ERROR_MESSAGE) ||
      (error instanceof Error && error.message.toUpperCase() === UNAUTHORIZED_ERROR_MESSAGE) ||
      (error instanceof AxiosError && error.message.toUpperCase() === UNAUTHORIZED_ERROR_MESSAGE)
    )
      return;
    dispatch(addNotification(newNotification));
    setTimeout(() => {
      dispatch(removeNotification(newNotification.id));
    }, NOTIFICATION_TIME);
  };
