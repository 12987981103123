import ContactsListToolbar from 'pages/Admin/ContactsPage/components/ContactsListToolbar';
import useAppModal from 'hooks/useAppModal';
import { CONTACT_LIST_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { ContactList } from 'utils/types';
import { ContactsListsTable } from 'shared/Tables/ContactsListsTable/ContactsListsTable';
import { ParamsType } from 'hooks/useUrlParams';
import { compileUrl } from 'utils/url';
import { privateRoutesUrls } from 'router/constants';
import { useContactsListsQuery } from 'pages/Admin/ContactsPage/hooks/api/useContactsListsQuery';
import { useNavigate } from 'react-router-dom';

interface ContactsListsTabProps {
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
}

const ContactsListsTab = ({ urlParams, setUrlParams }: ContactsListsTabProps) => {
  const openModal = useAppModal();
  const navigate = useNavigate();

  const openContactListModal = (modalType: MODAL_ACTIONS, contactList?: ContactList) =>
    openModal(
      {
        modalName: CONTACT_LIST_MODAL,
        modalData: {
          type: modalType,
          data: contactList,
        },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );

  const {
    data: contactListsData,
    isLoading,
    refetch,
    isRefetching,
  } = useContactsListsQuery(urlParams, true);
  const filteredContacts = contactListsData?.data;

  const toolbar = <ContactsListToolbar urlParams={urlParams} setUrlParams={setUrlParams} />;

  return (
    <ContactsListsTable
      contactLists={filteredContacts ?? []}
      urlParams={urlParams}
      setUrlParams={setUrlParams}
      loading={isLoading || isRefetching}
      editAction={(contact) => {
        const editListUrl = compileUrl(privateRoutesUrls.dashboardRoutes.adminContactsListEdit, {
          label: 'id',
          value: String(contact._id),
        });
        navigate(editListUrl);
      }}
      editListInfoAction={(contactList: ContactList) =>
        openContactListModal(MODAL_ACTIONS.EDIT, contactList)
      }
      tableProperties={{
        toolbarTools: [toolbar],
      }}
      addContactsToListAction={(contactList: ContactList) =>
        openContactListModal(MODAL_ACTIONS.CUSTOM, contactList)
      }
      deleteListAction={(contactList: ContactList) =>
        openContactListModal(MODAL_ACTIONS.DELETE, contactList)
      }
    />
  );
};

export default ContactsListsTab;
