import { Box, styled as MuiStyled } from '@mui/material';

export const StyledEditContactsListViewPageHeader = MuiStyled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  padding-bottom: 32px;
  width: 100%;
`;

export const StyledLeftContainer = MuiStyled(Box)`
  display: flex;
  gap: 24px;
  flex: 1;
`;

export const StyledRightContainer = MuiStyled(Box)`
  display: flex;
  gap: 24px;
`;

export const ChevronBox = MuiStyled(Box)`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.secondary.lighter};
  cursor: pointer;
`;

export const TitleContainer = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const TitleRow = MuiStyled(Box)`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const IconsContainer = MuiStyled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;

  .editIcons {
    cursor: pointer;
  }
`;

export const StyledHeaderSpan = MuiStyled('span')`
  color: ${({ theme }) => theme.palette.secondary.light}
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
  display: flex;
  gap: 8px;
`;
