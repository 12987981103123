// hooks
import { useLocation, useNavigate } from 'react-router-dom';

// styles, assets
import { StyledLoginContainer, StyledLoginLogoWrapper } from 'pages/Login/Login.styles';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { useEffect, useState } from 'react';
import { SuccessfullSetup } from 'shared/CompositeComponents/2FASetupFlow/SuccessfullSetup';
import { Styled2FaSetupContentWrapper } from 'pages/Login/Setup2fa/Setup2FA.styles';
import { Request2FAResponse } from 'utils/types/auth';
import { enable2fa, request2FA } from 'utils/api/auth';
import { privateRoutesUrls, publicRoutesUrls } from 'router/constants';
import { removeWeakToken, storeStrongToken } from 'utils/user/storage';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { Initialise2faSetup } from 'shared/CompositeComponents/2FASetupFlow/Initialise2faSetup';
import { SetupQRCode } from 'shared/CompositeComponents/2FASetupFlow/SetupQRCode';
import { Passcode } from 'shared/CompositeComponents/2FASetupFlow/Passcode';

type TwoFaFlowScreens = 'INITIALISE_2FA_SETUP' | 'SETUP_QR_CODE' | 'PASSCODE' | 'SUCCESFULL_SETUP';

const Setup2FA = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const uri = state?.uri;
  const [twoFaFlowScreen, setTwoFaFlowScreen] = useState<TwoFaFlowScreens>('INITIALISE_2FA_SETUP');
  const [twoFARequest, setTwoFARequest] = useState<Request2FAResponse>();
  const [twoFAOTPBackupCode, setTwoFAOTPBackupCode] = useState<string>('');

  useEffect(() => {
    if (!state) navigate(publicRoutesUrls.login);
  }, []);

  const handleSkip = () => {
    navigate(privateRoutesUrls.dashboardRoutes.rootPath, { replace: true });
  };

  const handleFinishSetup = () => {
    navigate(privateRoutesUrls.dashboardRoutes.rootPath, { replace: true });
  };

  const handleRequest2FASetup = () => {
    request2FA(uri)
      .then((res) => {
        setTwoFARequest(res.data);
        setTwoFaFlowScreen('SETUP_QR_CODE');
      })
      .catch((err) => {
        dispatch(createNotification(errorNotification(err.message, err.name), err));
      });
  };

  const Setup2faScreens = {
    INITIALISE_2FA_SETUP: (
      <Initialise2faSetup handleNext={handleRequest2FASetup} handleSkip={handleSkip} />
    ),
    SETUP_QR_CODE: twoFARequest ? (
      <SetupQRCode
        twoFARequest={twoFARequest}
        handleNext={() => setTwoFaFlowScreen('PASSCODE')}
        handleSkip={handleSkip}
      />
    ) : null,
    PASSCODE: (
      <Passcode
        handleVerifyPasscode={(enablePasscodeOtp) => {
          enable2fa(twoFARequest?._actions.enable2fa.uri || '', enablePasscodeOtp)
            ?.then((res) => {
              setTwoFAOTPBackupCode(res.data.otpBackupCode);
              storeStrongToken(res.data.strongToken);
              removeWeakToken();
              setTwoFaFlowScreen('SUCCESFULL_SETUP');
            })
            .catch((err) => {
              dispatch(createNotification(errorNotification(err.message, err.name), err));
            });
        }}
      />
    ),
    SUCCESFULL_SETUP: (
      <SuccessfullSetup
        handleFinishSetup={handleFinishSetup}
        twoFAOTPBackupCode={twoFAOTPBackupCode}
      />
    ),
  };

  return (
    <StyledLoginContainer>
      <Styled2FaSetupContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        {Setup2faScreens[twoFaFlowScreen]}
      </Styled2FaSetupContentWrapper>
    </StyledLoginContainer>
  );
};

export default Setup2FA;
