import { styled as MuiStyled, TableBody as MuiTableBody } from '@mui/material';

const TableBody = MuiStyled(MuiTableBody)(({ theme }) => ({
  whiteSpace: 'nowrap',
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightSemiBold,
  backgroundColor: theme.palette.customBackground.default.weak,
}));

export default TableBody;
