import Product from 'components/Product';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import Tag from 'components/Tag';
import { NavReviewItemWithProduct, NavReviewStateLabel } from 'utils/types/nav';
import { TableCellContent } from 'pages/Ledger/NavReviewPage/components/styles';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import { MuiStyledTableRow } from 'components/Table/TableRow';

type Props = {
  navReviewItem: NavReviewItemWithProduct;
  editAction: () => void;
};

export const NavReviewTableRow = ({ navReviewItem, editAction }: Props) => {
  return (
    <MuiStyledTableRow
      data-qa-id={`row-${navReviewItem._id}`}
      key={navReviewItem._id}
      iserror={navReviewItem.status === PortfolioCompositionStatus.PENDING ? 'true' : 'false'}
      onClick={() => editAction()}
    >
      <MuiStyledTableCell data-qa-id={`product-${navReviewItem.product.name}`} nowrap="true">
        <TableCellContent>
          <Product instrument={navReviewItem.product} />
        </TableCellContent>
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`constituent-${navReviewItem.ticker}`}>
        {navReviewItem.ticker}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`onyx-nav-${navReviewItem.ledgerBalance}`}>
        {navReviewItem.ledgerBalance}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`accountant-nav-${navReviewItem.fundAccountantBalance}`}>
        {navReviewItem.fundAccountantBalance}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`difference-${navReviewItem.difference}`}>
        {navReviewItem.difference}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`state-${navReviewItem.status}`}>
        <Tag
          size="small"
          label={NavReviewStateLabel[navReviewItem.status]}
          variant={navReviewItem.status === PortfolioCompositionStatus.APPROVED ? '03' : '04'}
        />
      </MuiStyledTableCell>
    </MuiStyledTableRow>
  );
};
