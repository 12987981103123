import { Order } from 'utils/types';
import { Deliverable } from 'utils/types';
import Table, { TableBody } from 'components/Table';
import DeliverableValue from 'pages/Orders/Orders/Order/components/OrderDetails/components/DeliverableValue';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { isEmpty } from 'lodash';
import TableHeader from 'shared/Tables/Table/TableHeader';

export interface ActualDeliverablesProps {
  order?: Order;
}

const ActualDeliverablesTableColumnsConfig = [
  { label: 'Ticker', propName: 'ticker', propType: 'string', width: '50%', sort: false },
  {
    label: 'Quantity',
    propName: 'quantity',
    propType: 'string',
    width: '50%',
    align: 'right',
    sort: false,
  },
];

const ActualDeliverablesList = ({ order }: ActualDeliverablesProps) => {
  const deliverables = order?.deliveries?.actual;
  const totalCoins = deliverables?.reduce((total, deliverable) => {
    return total + (deliverable.amount || 0);
  }, 0);

  if (isEmpty(deliverables)) {
    return null;
  }

  return (
    <Table noPadding>
      <TableHeader
        columns={ActualDeliverablesTableColumnsConfig}
        columnAlignment={{
          quantity: 'right',
        }}
        showActionsCell={false}
      />
      <TableBody>
        {deliverables?.map((deliverable: Deliverable) => (
          <MuiStyledTableRow key={`expected-deliverable-${deliverable.ticker}`}>
            <MuiStyledTableCell>{deliverable.ticker}</MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {order && <DeliverableValue deliverable={deliverable} order={order} />}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
        ))}
        <MuiStyledTableRow>
          <MuiStyledTableCell>Total</MuiStyledTableCell>
          <MuiStyledTableCell align="right">{totalCoins}</MuiStyledTableCell>
        </MuiStyledTableRow>
      </TableBody>
    </Table>
  );
};

export default ActualDeliverablesList;
