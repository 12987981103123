import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getTokenOrder, loadOrder as loadOrderRequest } from 'utils/api/orders';
import {
  getCurrentOrderQueryKey,
  getCurrentTokenOrderQueryKey,
} from 'utils/constants/reactQueries';

export const useCurrentOrder = (orderId: string) => {
  const dispatch = useAppDispatch();

  return useQuery([getCurrentOrderQueryKey, orderId], async () => {
    try {
      const order = await loadOrderRequest(orderId);
      return order;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    }
  });
};

export const useCurrentTokenOrder = (orderId: string) => {
  const dispatch = useAppDispatch();

  return useQuery([getCurrentTokenOrderQueryKey, orderId], async () => {
    try {
      const order = await getTokenOrder(orderId);
      return order;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    }
  });
};
