import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import { StyledCustomHeader } from 'pages/Orders/Register/RegisterDetails/RegisterDetails.styles';
import { PortfolioComposition } from 'utils/types/pcfs';
import ProductIcon from 'components/ProductIcon';
import PageTitle from 'components/PageTitle';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { BackButton } from 'shared/BackButton';
import { getPCFState } from 'shared/Tables/PCFTable/PCFTableRow';
import Tag from 'components/Tag';
import { StyledTagWrapper } from 'pages/Ledger/PCF/components/styles';

interface PCFDetailsHedearProps {
  pcf: PortfolioComposition | undefined;
}

export const PCFDetailsPageHedear = ({ pcf }: PCFDetailsHedearProps) => {
  return (
    <PageHeader
      customComponent={
        <StyledCustomHeader>
          <BackButton />
          <StyledTableCellContentWithIcon>
            {pcf && (
              <>
                <ProductIcon
                  className="product-icon"
                  iconUrl={pcf?.product.fundIcon}
                  ticker={pcf?.product.ticker}
                />
                <PageTitle>{pcf?.product.ticker} PCF Details</PageTitle>
                <StyledTagWrapper>
                  <Tag
                    label={getPCFState(pcf!.status, pcf!.isPendingUpload)[0]}
                    variant={getPCFState(pcf!.status, pcf!.isPendingUpload)[1]}
                    size="small"
                  />
                </StyledTagWrapper>
              </>
            )}
          </StyledTableCellContentWithIcon>
        </StyledCustomHeader>
      }
      customComponentPosition="start"
    />
  );
};
