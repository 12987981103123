export const contactListTypesToolTipText = {
  ORDER: {
    title: 'Orders',
    content:
      'Receive notifications for when an order is placed, confirmed, ready, settled, deliverables received, shares received, cancelled, and a daily order summary.',
  },
  PRODUCT: {
    title: 'Product',
    content:
      'Receive notifications for when a product is created, updated, and activated / deativated.',
  },
  NON_PRODUCT: {
    title: 'Non-Product',
    content: ' Any other type of email / non fund specific reporting.',
  },
  PCF: {
    title: 'PCF',
    content: 'This sends confirmed PCF Notifications to the contacts in this list.',
  },
  CONSOLIDATED_PCFS: {
    title: 'Consolidated PCFs',
    content: `This sends consolidated PCF's Notifications to the contacts in this list.`,
  },
};
