import BigNumber from 'bignumber.js';
import { Deliverable, Order } from 'utils/types';

interface DeliverableValueProps {
  cashValue?: boolean;
  deliverable?: Deliverable;
  order: Order;
  expectedTotalCash?: boolean;
}

const ZERO_UNITS = 0;

const DeliverableValue = ({
  cashValue,
  deliverable,
  order,
  expectedTotalCash,
}: DeliverableValueProps) => {
  let value = 0;
  if (deliverable && !deliverable.amount) {
    return <span>-</span>;
  }

  value = cashValue
    ? new BigNumber(deliverable?.totalInCurrency || ZERO_UNITS).toNumber()
    : new BigNumber(deliverable?.amount ?? ZERO_UNITS).toNumber();

  if (expectedTotalCash && order.deliveries) {
    value = order.deliveries?.expectedTotalCash;
  }

  return (
    <>
      {cashValue &&
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: order.deliveries?.expectedTotalCurrency ?? 'USD',
        }).format(value)}
      {!cashValue && value}
    </>
  );
};

export default DeliverableValue;
