import { useEffect } from 'react';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useUserPermissions } from 'store/user/selectors';
import { useNavigate } from 'react-router-dom';

// actions

import InstrumentForm from 'pages/Instruments/components/Form/ETPForm';
import { privateRoutesUrls } from 'router/constants';
import { instrumentsActions } from 'store/instruments/slice';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { ETPPageHeader } from 'pages/Instruments/components/ETPPageHeader';

function CreateInstrument() {
  const dispatch = useAppDispatch();
  const permissions = useUserPermissions();
  const navigate = useNavigate();
  const { instrument } = useCurrentInstrument();
  const { removeCurrentInstrument } = instrumentsActions;

  useEffect(() => {
    if (!permissions?.canCreateEtp) {
      navigate(privateRoutesUrls.dashboardRoutes.rootPath);
    }
  }, [navigate, permissions?.canCreateEtp]);

  useEffect(() => {
    return () => {
      dispatch(removeCurrentInstrument());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ETPPageHeader isNewProduct={true} instrument={instrument} />
      <InstrumentForm />
    </div>
  );
}

export default CreateInstrument;
