import GenericTabs from 'shared/GenericTabs';
import PartnersTable from 'shared/Tables/PartnersTable/PartnersTable';
import useAppModal from 'hooks/useAppModal';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { PARTNERS_TABS, PARTNERS_TAB_STATUS, PARTNER_STATUS_LABEL } from 'utils/constants/partners';
import { PARTNER_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { Partner } from 'utils/types';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { compileUrl } from 'utils/url';
import { privateRoutesUrls } from 'router/constants';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useCompanies } from 'pages/Partners/hooks/useCompanies';
import { useNavigate } from 'react-router-dom';
import { useUrlParams } from 'hooks/useUrlParams';
import { useUserPermissions } from 'store/user/selectors';

export const Partners = () => {
  const navigate = useNavigate();
  const permissions = useUserPermissions();

  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    { page: DEFAULT_PAGE, pageSize: DEFAULT_ITEMS_PER_PAGE, tab: PARTNERS_TAB_STATUS.ACTIVE },
    privateRoutesUrls.dashboardRoutes.partners
  );
  const { data, isLoading: loading, refetch } = useCompanies(urlParams);
  const { data: partners, ...paginationResponse } = data ?? {};
  const pagination = useBackendPagination(paginationResponse, setUrlParams);
  const openModal = useAppModal();

  const partnerEditAction = (partner: Partner) => {
    const editPartnerUrl = compileUrl(privateRoutesUrls.dashboardRoutes.partnersEdit, {
      label: 'id',
      value: String(partner._id!),
    });

    navigate(editPartnerUrl);
  };

  const openPartnerModal = (type: MODAL_ACTIONS, partner?: Partner) => {
    openModal(
      {
        modalName: PARTNER_MODAL,
        modalData: {
          data: partner,
          type: type,
        },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );
  };

  const createPartnerButton = permissions?.canCreateCompany && (
    <StyledTableTopButton
      data-qa-id="createPartnerButton"
      variant="interactive"
      size="medium"
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.partnersNew)}
    >
      <span>Create Partner</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  return (
    <>
      <PageHeader actionComponent={createPartnerButton} pageTitle={'Partners'} />
      <GenericTabs
        active={urlParams.tab as PARTNER_STATUS_LABEL}
        setUrlParamsWithDefaults={setUrlParamsWithDefaults}
        tabs={PARTNERS_TABS}
      >
        <PartnersTable
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          tableProperties={{
            pagination,
          }}
          partners={partners ?? []}
          loadingData={loading}
          editAction={partnerEditAction}
          deleteAction={(partner) => openPartnerModal(MODAL_ACTIONS.DELETE, partner)}
        />
      </GenericTabs>
    </>
  );
};
