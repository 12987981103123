import TableHeader from 'shared/Tables/Table/TableHeader';

import { StyledTable } from 'components/Table/Table.styles';
import { StyledTradeTableWrapper } from 'pages/Rebalance/RebalancePage.styles';
import { TableBody } from 'components/Table';
import { RebalanceWithDetails } from 'utils/types/rebalance';
import { TradesTableRow } from './TradesTableRow';
import { TradesTableColumnsConfig } from './tableConfig';

export interface TradeTableInterface {
  rebalance: RebalanceWithDetails;
}
export const TradesTable = ({ rebalance }: TradeTableInterface) => {
  return (
    <StyledTradeTableWrapper>
      <StyledTable>
        <TableHeader showActionsCell={false} columns={TradesTableColumnsConfig} />
        <TableBody>
          {rebalance.details.map((trade, index) => (
            <TradesTableRow
              index={index}
              trade={trade}
              key={`${index}_${rebalance.product?.ticker}_`}
            />
          ))}
        </TableBody>
      </StyledTable>
    </StyledTradeTableWrapper>
  );
};
