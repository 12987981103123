import { useState } from 'react';
import { format, isValid, startOfDay } from 'date-fns';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { CalendarIcon } from '@mui/x-date-pickers';

import { ClickAwayListener, FormLabel, IconButton, Stack, TextField } from '@mui/material';
import Button from 'components/Button';

import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { MuiStyledContainer, MuiStyledPopper } from 'components/Datepicker/DateRangePicker.styles';

export type DateValue = Date | null;

export interface DataSinglePickerProps {
  onChange?: (value: DateValue) => void;
  date?: Date | null;
  maxDate?: Date;
  minDate?: Date;
  inputDateFormat?: string;
  name?: string;
  label?: string;
  disableClearButton?: boolean;
  disableManualDateInput?: boolean;
}

const dateRegex = /^[\d/]*$/;
const parse = (value: string) => {
  const [day, month, year] = value.split('/');
  return Date.parse(`${month}/${day}/${year}`);
};

const getDefaultDate = (date?: Date | null) => {
  if (date !== null && typeof date !== 'undefined') {
    return startOfDay(date);
  }
  return null;
};

function DatepickerSingleDate({
  label,
  onChange,
  date: defaultDate,
  maxDate,
  minDate,
  inputDateFormat = DEFAULT_DATE_FORMAT,
  disableClearButton,
  ...props
}: DataSinglePickerProps) {
  const [date, setDate] = useState<Date | null>(() => getDefaultDate(defaultDate));

  const [inputDate, setInputDate] = useState(() => format(date ?? new Date(), inputDateFormat));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDateRangeEmpty = !Boolean(date);

  const handleApplyClick = () => {
    if (onChange && date) {
      onChange(date);
      setAnchorEl(null);
    }
  };

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
    setInputDate(format(newDate, inputDateFormat));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (dateRegex.test(event.target.value)) {
      setInputDate(event.target.value);
      setDate(new Date(event.target.value));
    }
  };

  const handleInputBlur = () => {
    const newDate = parse(inputDate);
    if (isNaN(newDate)) {
      setInputDate(format(newDate, inputDateFormat));
      return;
    }
    setDate(inputDate ? new Date(newDate) : null);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const target = event.target as HTMLInputElement;
      if (target.value && dateRegex.test(target.value)) {
        const parsedDate = new Date(parse(target.value));
        if (isValid(parsedDate)) {
          if (onChange) onChange(parsedDate);
          handleDateChange(parsedDate);
        }
      }
    }
  };

  const handlePoperClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const clearDatePicker = () => {
    setDate(null);
    if (onChange) {
      onChange(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Stack spacing="4px">
      {label && <FormLabel>{label}</FormLabel>}
      <TextField
        fullWidth={true}
        value={inputDate}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        onKeyDown={handleOnKeyDown}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handlePoperClick}>
              <CalendarIcon />
            </IconButton>
          ),
        }}
        className="datePickerTextField"
      />
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <MuiStyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <MuiStyledContainer>
            <DateCalendar
              maxDate={maxDate}
              minDate={minDate}
              defaultValue={defaultDate}
              value={date}
              onChange={handleDateChange}
              {...props}
            />
            <Stack direction="row" spacing={1}>
              <Button
                disabled={isDateRangeEmpty || disableClearButton}
                fullWidth
                onClick={clearDatePicker}
                size="small"
                variant="tertiary"
              >
                Clear
              </Button>
              <Button
                disabled={isDateRangeEmpty}
                fullWidth
                onClick={handleApplyClick}
                size="small"
                variant="interactive"
              >
                Apply
              </Button>
            </Stack>
          </MuiStyledContainer>
        </MuiStyledPopper>
      </ClickAwayListener>
    </Stack>
  );
}

export default DatepickerSingleDate;
