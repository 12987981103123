import useAppDispatch from 'hooks/useAppDispatch';
import { getSecuritiesRegisterProductData } from 'utils/api/securities-registry';
import { useQuery } from 'react-query';
import { getSingleRegisterQuery } from 'utils/constants/reactQueries';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';

export const useSingleRegisterQuery = (id: string) => {
  const dispatch = useAppDispatch();

  return useQuery(
    [getSingleRegisterQuery + id],
    async () => {
      try {
        const res = await getSecuritiesRegisterProductData(id);
        return res.data;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(id),
    }
  );
};
