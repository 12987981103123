import Button from 'components/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { ContactModalData } from 'shared/Modals/Contacts/types';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { contactDeactivatedNotifications } from 'shared/Notifications/contact.notifications';
import { createNotification } from 'store/notifications/actions';
import { deleteEntityApiCall } from 'utils/api/crudActions';
import { useMutation } from 'react-query';
import { Contact } from 'utils/types/contacts';
import Card from 'components/Card';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

const DeleteContactModal = ({ closeModal, data, onCloseModalAction }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const contactData = data.data as ContactModalData;
  const contact = contactData.contact;

  const deleteContactMutation = useMutation({
    mutationFn: (contactToDelete: Contact) => {
      return deleteEntityApiCall(contactToDelete._actions.delete.uri);
    },
    onSuccess: (_res, deletedContact) => {
      dispatch(
        createNotification(contactDeactivatedNotifications.success(`${deletedContact.email}`))
      );
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(contactDeactivatedNotifications.error(error.message), error));
    },
  });

  const handleSubmit = () => {
    contact && deleteContactMutation.mutate(contact);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="interactive"
        fullWidth
        onClick={handleSubmit}
        type="submit"
        data-qa-id="deleteButton"
        isLoading={deleteContactMutation.isLoading}
      >
        Delete
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        footer={Footer}
        onClose={closeModal}
        label="Contacts"
        title={`Are you sure you want to delete ${contact?.email} contact?`}
      />
    </CustomModal>
  );
};

export default DeleteContactModal;
