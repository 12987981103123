import { PortfolioComposition } from 'utils/types/pcfs';
import { renderValueOrDash } from 'utils/formatting';
import Card from 'components/Card';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { PCFDetailsListColumnConfig } from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/tableColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
export interface PCFEtpInformationProps {
  pcf?: PortfolioComposition;
  isLoading?: boolean;
}

function PCFEtpInformation({ pcf, isLoading }: PCFEtpInformationProps) {
  return (
    <Card title="ETP Information" subtitle={pcf?.product?.name}>
      <Table noPadding>
        <TableHeaderWithMultiSort columns={PCFDetailsListColumnConfig} />
        <TableBodyWithStates
          loadingData={isLoading}
          hasContent={!!pcf}
          noContentLabel="No PCF data."
        >
          <PCFEtpInformationRow pcf={pcf} />
        </TableBodyWithStates>
      </Table>
    </Card>
  );
}

export default PCFEtpInformation;

const PCFEtpInformationRow = ({ pcf }: { pcf?: PortfolioComposition }) => {
  return (
    <>
      <MuiStyledTableRow>
        <MuiStyledTableCell>Currency</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product.baseCurrency}</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product.baseCurrency}</MuiStyledTableCell>
      </MuiStyledTableRow>
      <MuiStyledTableRow>
        <MuiStyledTableCell>Product ISIN</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product.isin}</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product.isin}</MuiStyledTableCell>
      </MuiStyledTableRow>
      <MuiStyledTableRow>
        <MuiStyledTableCell>Product Name</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product.name}</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product.name}</MuiStyledTableCell>
      </MuiStyledTableRow>
      {pcf?.currencyPrices?.map((currencyPrice) => (
        <MuiStyledTableRow key={currencyPrice.baseCurrency}>
          <MuiStyledTableCell>Total NAV {currencyPrice.baseCurrency}</MuiStyledTableCell>
          <MuiStyledTableCell
            error={
              pcf.errors?.totalNav?.errors?.find(
                (error) => error.currency === currencyPrice.baseCurrency
              )
                ? 'error'
                : ''
            }
          >
            {renderValueOrDash(
              pcf?.synthetic?.totals?.find((total) => total.currency === currencyPrice.baseCurrency)
                ?.netAssetValue
            )}
          </MuiStyledTableCell>
          <MuiStyledTableCell
            error={
              pcf.errors?.totalNav?.errors?.find(
                (error) => error.currency === currencyPrice.baseCurrency
              )
                ? 'error'
                : ''
            }
          >
            {renderValueOrDash(
              pcf?.official?.totalNav?.find(
                (total) => total.currency === currencyPrice.baseCurrency
              )?.value
            )}
          </MuiStyledTableCell>
        </MuiStyledTableRow>
      ))}
      <MuiStyledTableRow>
        <MuiStyledTableCell>Total Units Outstanding</MuiStyledTableCell>
        <MuiStyledTableCell error={pcf?.errors?.totalUnitsOutstanding?.length ? 'error' : ''}>
          {pcf?.synthetic?.totalUnitsOutstanding || '-'}
        </MuiStyledTableCell>
        <MuiStyledTableCell error={pcf?.errors?.totalUnitsOutstanding?.length ? 'error' : ''}>
          {pcf?.official?.totalUnitsOutstanding || '-'}
        </MuiStyledTableCell>
      </MuiStyledTableRow>
      <MuiStyledTableRow>
        <MuiStyledTableCell>Creation Unit Size</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product?.unitSize}</MuiStyledTableCell>
        <MuiStyledTableCell>{pcf?.product?.unitSize}</MuiStyledTableCell>
      </MuiStyledTableRow>
    </>
  );
};
