import { variantType } from 'components/Search/Search.types';
import mergeRefs from 'utils/mergeRef';
import { ChangeEvent, ComponentPropsWithoutRef, forwardRef, useRef, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { MuiStyledTextField } from 'components/Search/Search.styles';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as TimesIcon } from 'assets/times.svg';
import { ColorSchema } from 'theme/types/ThemeTypes';

export interface SearchInputProps extends ComponentPropsWithoutRef<'input'> {
  variant?: variantType;
  onClear?: () => void;
}

const SearchInputComponent = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ disabled, placeholder = 'Search', variant, onClear, ...props }, ref) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const refs = mergeRefs<HTMLInputElement>(ref, innerRef);
    const [searchValue, setSearchValue] = useState('');

    const handleClear = () => {
      const nativeValueSetter = Object.getOwnPropertyDescriptor(
        HTMLInputElement.prototype,
        'value'
      )?.set;

      nativeValueSetter?.call(innerRef.current, '');

      var eventTrigger = new Event('input', { bubbles: true });
      innerRef.current?.dispatchEvent(eventTrigger);
      onClear && onClear();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <TextField
        disabled={disabled}
        {...props}
        color={props.color as ColorSchema}
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        size={variant === 'tall' ? 'medium' : 'small'}
        onChange={handleChange}
        InputProps={{
          endAdornment: searchValue && (
            <IconButton onClick={handleClear}>
              <TimesIcon />
            </IconButton>
          ),
          startAdornment: (
            <MuiStyledTextField position="start">
              <SearchIcon />
            </MuiStyledTextField>
          ),
        }}
        inputRef={refs}
      />
    );
  }
);

export default SearchInputComponent;
