import { ComponentPropsWithoutRef, ReactNode } from 'react';

//types
import { PaginationProps } from 'utils/types';

// components
import Pagination from 'components/Pagination';

import TableBody from 'components/Table/TableBody';
import TableCell from 'components/Table/TableCell';
import TableHead from 'components/Table/TableHead';
import TableRow from 'components/Table/TableRow';

// styles, assets
import {
  StyledTable as TableComponent,
  StyledTableHeader,
  StyledTableToolbar,
  StyledTableHeaderWrapper,
  StyledTableHeaderButtons,
} from 'components/Table/Table.styles';
import { Grid, Typography } from '@mui/material';

export interface TableProps extends Omit<ComponentPropsWithoutRef<'table'>, 'title'> {
  description?: string;
  pagination?: PaginationProps;
  title?: string | ReactNode;
  toolbarTools?: any[];
  buttons?: ReactNode;
  tabsComponent?: ReactNode;
  tabsComponentUnderToolbar?: Boolean;
  noPadding?: Boolean;
}

function Table({
  children,
  description,
  pagination,
  title,
  toolbarTools,
  buttons,
  tabsComponent,
  tabsComponentUnderToolbar,
  noPadding,
  ...props
}: TableProps) {
  return (
    // @TODO - use theme for background here
    <Grid container sx={{ background: 'white' }} borderRadius={1}>
      <StyledTableHeaderWrapper>
        {title && (
          <StyledTableHeader withDescription={Boolean(description)}>
            <Typography variant="subheadingMedium">{title}</Typography>
            {description && <span>{description}</span>}
          </StyledTableHeader>
        )}
        <StyledTableHeaderButtons>{buttons}</StyledTableHeaderButtons>
      </StyledTableHeaderWrapper>
      {!tabsComponentUnderToolbar && tabsComponent}
      {toolbarTools && <StyledTableToolbar tools={toolbarTools} />}
      {tabsComponentUnderToolbar && tabsComponent}
      <Grid item xs={12} padding={2} overflow={'auto'}>
        <TableComponent {...props}>{children}</TableComponent>
      </Grid>
      {pagination && (
        <Grid xs={12} display={'flex'} justifyContent={'flex-end'}>
          <Pagination {...pagination} />
        </Grid>
      )}
    </Grid>
  );
}

export { TableBody, TableCell, TableHead, TableRow };

export default Table;
