import { SecuritiesRegister } from './types';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { DateTime } from 'utils/datetime';

export const generateXlSXObject = (register: SecuritiesRegister) => {
  return {
    Name: register.name,
    ID: register._id,
    ISIN: register.isin,
    Ticker: register.ticker,
    Currency: register.currency,
    Maturity: register.maturity,
    Issuer: register.issuer.name,
    'Issuer Address': `${register.issuer.address.addressLineOne}, ${register.issuer.address.addressLineTwo}, ${register.issuer.address.city?.name}, ${register.issuer.address.country?.name}, ${register.issuer.address.postalCode}`,
  };
};

export const downloadXLS = (register: SecuritiesRegister) => {
  if (register) {
    const workbook = XLSX.utils.book_new();
    const infoWorksheet = XLSX.utils.json_to_sheet([generateXlSXObject(register)]);

    XLSX.utils.book_append_sheet(workbook, infoWorksheet, 'RegisterData');
    if (register.entries?.length) {
      const xlsEntries = register.entries.map((entry) => ({
        'Accumulated amount': entry.accumulatedAmount,
        'Change in securities': entry.changeInSecuritiesAmount,
        'Counter Party': entry.counterParty,
        'Deal date': entry.dealDate,
      }));
      const entriesWorksheet = XLSX.utils.json_to_sheet(xlsEntries);
      XLSX.utils.book_append_sheet(workbook, entriesWorksheet, 'EntriesSheet');
    }
    XLSX.writeFile(workbook, `${register.ticker}-${new DateTime().format('MM-dd-yyyy')}.xlsx`);
  }
};

export const downloadPdf = (register: SecuritiesRegister) => {
  if (register) {
    const PDF_MARGIN = 4;
    const details = document.querySelector(`#pdf-${register._id}`) as HTMLElement;

    if (details) {
      html2pdf()
        .set({
          filename: `${register.ticker}-${new DateTime().format('MM-dd-yyyy')}.pdf`,
          margin: PDF_MARGIN,
        })
        .from(details)
        .save();
    }
  }
};
