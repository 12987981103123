//constants
import {
  CALENDAR_EVENT_MODAL,
  CALENDAR_MODAL,
  CANCEL_ORDER_MODAL,
  PARTNER_MODAL,
  USER_MODAL,
  WALLET_MODAL,
  EXCHANGE_MODAL,
  CUSTODIAN_MODAL,
  TOUCHED_FIELDS_MODAL,
  PRODUCT_EXCHANGE_MODAL,
  CONSTITUENT_MODAL,
  YES_OR_NO_MODAL,
  CONTACT_MODAL,
  CONTACT_LIST_MODAL,
  NAV_REVIEW_MODAL,
  ORDERS_MODAL,
  ETP_MODAL,
  PCF_UPLOAD_MODAL,
  REBALANCE_MODAL,
  DEFER_ORDER_MODAL,
  TOKEN_MODAL,
} from 'shared/Modals/constants';
//utils and types
import { createSlice } from '@reduxjs/toolkit';
import { ModalsStateType } from 'store/modals/types';

const initialState: ModalsStateType = {
  active: null,
  modals: {
    [CANCEL_ORDER_MODAL]: null,
    [EXCHANGE_MODAL]: null,
    [CALENDAR_MODAL]: null,
    [CALENDAR_EVENT_MODAL]: null,
    [CONTACT_LIST_MODAL]: null,
    [PARTNER_MODAL]: null,
    [USER_MODAL]: null,
    [WALLET_MODAL]: null,
    [CUSTODIAN_MODAL]: null,
    [CONSTITUENT_MODAL]: null,
    [TOUCHED_FIELDS_MODAL]: null,
    [PRODUCT_EXCHANGE_MODAL]: null,
    [YES_OR_NO_MODAL]: null,
    [REBALANCE_MODAL]: null,
    [CONTACT_MODAL]: null,
    [NAV_REVIEW_MODAL]: null,
    [ORDERS_MODAL]: null,
    [DEFER_ORDER_MODAL]: null,
    [ETP_MODAL]: null,
    [TOKEN_MODAL]: null,
    [PCF_UPLOAD_MODAL]: null,
  },
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  extraReducers: () => {},
  reducers: {
    hideModal(state) {
      state.active = null;
      state.modals = {
        ...initialState.modals,
      };
    },
    showModal(state, action) {
      state.active = {
        name: action.payload.modalName,
        type: action.payload.modalData.type,
      };
      state.modals = {
        ...initialState.modals,
        [action.payload.modalName]: { ...action.payload.modalData },
      };
    },
  },
});

export const { hideModal, showModal } = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;

export default modalsSlice;
