import Button from 'components/Button';
import Card from 'components/Card';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'components/Table';
import useAppDispatch from 'hooks/useAppDispatch';
import { WalletsList } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import { WALLETS_INFO_STEP } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { CustodianModalData } from 'shared/Modals/Custodian/CustodianModal';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { ModalDescription } from 'shared/Modals/Custodian/CustodianModal.styles';
import { CustodianAccount, ProductStatus } from 'utils/types/product';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { useCurrentInstrument, useCurrentInstrumentFormData } from 'store/instruments/selectors';
import { useMutation } from 'react-query';
import { deleteEntityApiCall } from 'utils/api/crudActions';
import { MuiStyledFooterWrapper } from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';

const DeleteCustodianModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const { instrument } = useCurrentInstrument();
  const walletsInfoData = useCurrentInstrumentFormData(WALLETS_INFO_STEP) as WalletsList;
  const { custodian: custodianToDelete } = data.data as CustodianModalData;

  const updateCustodianListMutation = useMutation({
    mutationFn: (updatedCustodiansList: CustodianAccount) => {
      return deleteEntityApiCall(updatedCustodiansList?._actions?.deactivate.uri ?? '');
    },
    onSuccess: () => {
      dispatch(
        createNotification({
          message: 'Custodian Account has been deactivated.',
          title: 'Custodian List Updated',
          type: 'success',
        })
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(errorNotification(err.message ?? ''), err));
    },
  });

  const handleSubmit = () => {
    custodianToDelete && updateCustodianListMutation.mutate(custodianToDelete);
  };

  const deactivateCustodianWallets =
    walletsInfoData?.custodianWallets?.filter(
      (wallet) => wallet.custodianAccount === custodianToDelete?._id
    ) ?? [];

  const Footer = (
    <MuiStyledFooterWrapper>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="interactive"
        fullWidth
        onClick={handleSubmit}
        type="submit"
        data-qa-id="deleteButton"
        isLoading={updateCustodianListMutation.isLoading}
      >
        {instrument?.status === ProductStatus.ACTIVE ? 'Deactivate and Save ' : 'Deactivate'}
      </Button>
    </MuiStyledFooterWrapper>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        footer={Footer}
        label={'Custodian Account'}
        title={`Are you sure you want to deactivate ${custodianToDelete?.name}?`}
        onClose={closeModal}
      >
        {deactivateCustodianWallets.length > 0 && (
          <>
            <ModalDescription>
              You will be deactivating the following {deactivateCustodianWallets.length} custodian
              {deactivateCustodianWallets.length === 1 ? ' wallet' : ' wallets'} as a result:
            </ModalDescription>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell min-width="50%">Wallet Name</TableCell>
                  <TableCell width="25%">Type</TableCell>
                  <TableCell width="25%">Custodian</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deactivateCustodianWallets?.map((wallet, index) => (
                  <TableRow key={`${wallet.address + index}`}>
                    <TableCell>{wallet.description}</TableCell>
                    <TableCell>{wallet.chain}</TableCell>
                    <TableCell>{custodianToDelete?.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </Card>
    </CustomModal>
  );
};

export default DeleteCustodianModal;
