import TableHeader from 'shared/Tables/Table/TableHeader';
import { RebalancesCardTableColumnsConfig } from './tableConfig';
import { RebalancesCardTradeTableRow } from './RebalancesCardTableRow';
import {
  StyledRebalanceTable,
  StyledTradeTableWrapper,
} from 'pages/Rebalance/RebalancePage.styles';
import { TableBody } from 'components/Table';
import { RebalanceWithDetails } from 'utils/types/rebalance';

export interface TradeTableInterface {
  rebalance: RebalanceWithDetails;
}
export const RebalancesCardTable = ({ rebalance }: TradeTableInterface) => {
  return (
    <StyledTradeTableWrapper>
      <StyledRebalanceTable>
        <TableHeader showActionsCell={false} columns={RebalancesCardTableColumnsConfig} />
        <TableBody>
          {rebalance.details.map((trade, index) => (
            <RebalancesCardTradeTableRow
              index={index}
              trade={trade}
              key={`${index}_${rebalance.product?.ticker}_`}
            />
          ))}
        </TableBody>
      </StyledRebalanceTable>
    </StyledTradeTableWrapper>
  );
};
