import PageTitle from 'components/PageTitle';
import React from 'react';
import Tooltip from 'components/Tooltip';
import useAppModal from 'hooks/useAppModal';
import {
  CONTACT_LIST_TYPE_LABEL,
  Contact,
  ContactList,
  PartnerContact,
} from 'utils/types/contacts';
import {
  StyledEditContactsListViewPageHeader,
  StyledLeftContainer,
  StyledRightContainer,
  TitleContainer,
  TitleRow,
  IconsContainer,
  StyledHeaderSpan,
} from './EditContactsListView.styles';
import {
  StyledToolTipRowsContainer,
  StyledRow,
  StyledTitleDiv,
  StyledContentDiv,
} from 'shared/Tables/ContactsListsTable/ContactsList.styles';
import { DELETE_CONTACTS_FROM_CONTACT_LIST_MODAL } from 'shared/Modals/constants';
import { EditContactListTable } from 'shared/Tables/EditContactListTable/EditContactListTable';
import { MODAL_ACTIONS, CONTACT_LIST_MODAL } from 'shared/Modals/constants';
import { ParamsType } from 'hooks/useUrlParams';
import { ReactComponent as InfoSvg } from 'assets/info.svg';
import { ReactComponent as PencilIcon } from 'assets/pencil-alt.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { contactListTypesToolTipText } from 'pages/Admin/ContactsPage/ContactsPage.constants';
import { getContactList } from 'utils/api/contacts';
import { useContactsListsContactsQuery } from 'pages/Admin/ContactsPage/hooks/api/useContactsListsContactsQuery';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { BackButton } from 'shared/BackButton';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import useAppDispatch from 'hooks/useAppDispatch';

export const EditContactsListView = () => {
  const { id: contactsListId } = useParams();
  const [urlParams, setUrlParams] = useState<ParamsType>({});
  const openModal = useAppModal();
  const dispatch = useAppDispatch();

  const contactList = useQuery(
    ['contactList', contactsListId],
    async () => await getContactList(contactsListId).then(({ data }) => data),
    {
      onError: (err) => {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error?.message ?? ''), error));
      },
    }
  );

  const { data, isLoading, refetch, isRefetching } = useContactsListsContactsQuery(contactsListId);

  const contacts = data ?? {
    external: [],
    partners: [],
  };

  const openContactListsModal = (type: MODAL_ACTIONS, data?: ContactList) =>
    openModal(
      {
        modalName: CONTACT_LIST_MODAL,
        modalData: {
          type: type,
          data: data ?? null,
        },
      },
      {
        onCloseModalAction: () => {
          contactList.refetch();
          refetch();
        },
      }
    );

  const onContactDeleteModal = (data: { contact?: Contact; partner?: PartnerContact }) => {
    contactList.data &&
      openModal(
        {
          modalName: CONTACT_LIST_MODAL,
          modalData: {
            data: {
              contact: data.contact,
              partnerContacts: data.partner,
              contactList: contactList.data,
            },
            type: MODAL_ACTIONS.DELETE,
            custom: {
              type: DELETE_CONTACTS_FROM_CONTACT_LIST_MODAL,
            },
          },
        },
        {
          onCloseModalAction: () => refetch(),
        }
      );
  };

  const actions = contactList?.data?._actions;
  const typeLabel = contactList.data?.type && CONTACT_LIST_TYPE_LABEL[contactList.data.type];

  const toolTipText = contactList?.data?.type
    ? contactListTypesToolTipText[contactList?.data?.type]
    : null;

  const toolTipContent = toolTipText ? (
    <StyledToolTipRowsContainer>
      <React.Fragment>
        <StyledRow>
          <StyledTitleDiv>{toolTipText.title}</StyledTitleDiv>
          <StyledContentDiv>{toolTipText.content}</StyledContentDiv>
        </StyledRow>
      </React.Fragment>
    </StyledToolTipRowsContainer>
  ) : null;

  return (
    <>
      <StyledEditContactsListViewPageHeader>
        <StyledLeftContainer>
          <BackButton />
          <TitleContainer>
            <TitleRow>
              <PageTitle>{contactList.data?.name}</PageTitle>
              <IconsContainer>
                {actions?.update && (
                  <PencilIcon
                    onClick={() => openContactListsModal(MODAL_ACTIONS.EDIT, contactList.data)}
                    width={16}
                    height={16}
                    className="editIcons"
                    fill="white"
                  />
                )}
                {actions?.delete && (
                  <TrashIcon
                    onClick={() => openContactListsModal(MODAL_ACTIONS.DELETE, contactList.data)}
                    width={16}
                    height={16}
                    className="editIcons"
                    fill="white"
                  />
                )}
              </IconsContainer>
            </TitleRow>

            {contactList?.data?.type && (
              <StyledHeaderSpan>
                <Typography color="white">Distribution List Type: {typeLabel}</Typography>
                <Tooltip arrow placement="bottom" title={toolTipContent}>
                  <InfoSvg fontSize={16} fill="white" />
                </Tooltip>
              </StyledHeaderSpan>
            )}
          </TitleContainer>
        </StyledLeftContainer>
        <StyledRightContainer>
          <StyledTableTopButton
            onClick={() => openContactListsModal(MODAL_ACTIONS.CUSTOM, contactList.data)}
            type="button"
            data-qa-id="addContactButton"
            size="medium"
            key="toolbar-button"
            disabled={!Boolean(actions?.assign)}
          >
            <span>Add Contact</span>
            <PlusIcon />
          </StyledTableTopButton>
        </StyledRightContainer>
      </StyledEditContactsListViewPageHeader>
      <EditContactListTable
        key={contactList.data?._id}
        setUrlParams={setUrlParams}
        urlParams={urlParams}
        tableProperties={{
          title: 'Distribution List',
        }}
        deleteAction={onContactDeleteModal}
        isLoading={isLoading || contactList.isLoading || isRefetching || contactList.isRefetching}
        contacts={contacts}
        contactList={contactList.data}
      />
    </>
  );
};
