import { ProductStatus, TokenStandard } from 'utils/types/product';

export const TOKENS_STATUS_LABEL: Record<ProductStatus, string> = {
  [ProductStatus.ACTIVE]: 'Active',
  [ProductStatus.PENDING]: 'Draft',
  [ProductStatus.IN_REVIEW]: 'Pending Review',
  [ProductStatus.DELETED]: 'Deactivated',
  [ProductStatus.ARCHIVED]: 'Archived',
};

export const TOKENS_TAB_STATUS_LABEL: Record<ProductStatus, string> = {
  [ProductStatus.ACTIVE]: 'Active',
  [ProductStatus.PENDING]: 'Drafts',
  [ProductStatus.IN_REVIEW]: 'Pending Review',
  [ProductStatus.DELETED]: 'Inactive',
  [ProductStatus.ARCHIVED]: 'Archived',
};

export const TOKENS_TAB_STATUS = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  IN_REVIEW: 'inreview',
  DELETED: 'deleted',
  ARCHIVED: 'archived',
};

export type ProductTabType =
  | typeof TOKENS_TAB_STATUS.ACTIVE
  | typeof TOKENS_TAB_STATUS.DRAFT
  | typeof TOKENS_TAB_STATUS.IN_REVIEW
  | typeof TOKENS_TAB_STATUS.DELETED
  | typeof TOKENS_TAB_STATUS.ARCHIVED
  | undefined;

// Update when a new token standard is added to the BE
export const tokenStandardMapping: { [key in TokenStandard]: string } = {
  ERC_20: 'ERC-20',
  SPL_20: 'SPL-2020',
  SPL_22: 'SPL-2022',
};
