import Loader from 'components/Loader';
import PartnerTabs from 'pages/Partners/components/PartnerRelatedEntitiesTabs/PartnerTabs';
import { PartnerDetailsHedear } from 'pages/Partners/components/PartnerDetailsHeader';
import { StyledContainer } from 'pages/Partners/style';
import { privateRoutesUrls } from 'router/constants';
import { useCompanyQuery } from 'pages/Partners/hooks/useCompanyQuery';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserPermissions } from 'store/user/selectors';

export const PartnerEdit = () => {
  const { id: partnerId } = useParams();
  const navigate = useNavigate();
  const { data: partnerData, isLoading: loading } = useCompanyQuery(partnerId ?? '');
  const isNewPartner = Boolean(!partnerId);
  const permissions = useUserPermissions();

  useEffect(() => {
    if (isNewPartner) {
      if (!permissions?.canCreateCompany) navigate(privateRoutesUrls.dashboardRoutes.rootPath);
    } else if (partnerData && !partnerData?._actions?.update) {
      navigate(privateRoutesUrls.dashboardRoutes.rootPath);
    }
  }, [partnerData, navigate, isNewPartner, permissions?.canCreateCompany]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <PartnerDetailsHedear company={partnerData} />
      <StyledContainer>{(!partnerId || partnerData) && <PartnerTabs />}</StyledContainer>
    </>
  );
};
