// documentation of onyx design token system: https://www.figma.com/file/G4QZm8Wj1LuOGDOEwzxHFk/%F0%9F%9A%A7--ONYX-Design-System

// Use example:
// const StyledTest = MuiStyled(Box)`
//   background-color: ${(props) => props.theme.palette.teritary.dark};
//   color: ${(props) => props.theme.palette.secondary.main};
//   ${(props) => props.theme.fontTypes['label-01']};
// `;

// defining font in one line:
// font: font-style font-variant font-weight font-size/line-height font-family;
// font: `italic small-caps bold 13px/150% Arial, Helvetica, sans-serif`;

const mainFont = 'Inter';
const weightLight = '300';
const weightRegular = '400';
const weightSemiBold = '600';
const labelStyles = {
  font: `normal normal ${weightRegular} 0.75rem/1rem ${mainFont}`,
  'letter-spacing': '0.32px',
};

const typeTokens = {
  //global
  'label-01': labelStyles,
  'caption-01': labelStyles,
  'helper-text-01': labelStyles,
  // body
  'body-01': {
    font: `normal normal ${weightRegular} 0.875rem/1.25rem ${mainFont}`,
    'letter-spacing': '0.16px',
  },
  'body-02': {
    font: `normal normal ${weightRegular} 1rem/1.5rem ${mainFont}`,
    'letter-spacing': '0px',
  },
  'body-link-01': {
    font: `normal normal ${weightRegular} 0.875rem/1.25rem ${mainFont}`,
    'letter-spacing': '0.16px',
  },
  // headings
  'heading-01': {
    font: `normal normal ${weightSemiBold} 0.875rem/1.25rem ${mainFont}`,
    'letter-spacing': '0px',
  },
  'heading-02': {
    font: `normal normal ${weightSemiBold} 1rem/1.375rem ${mainFont}`,
    'letter-spacing': '0px',
  },
  'heading-03': {
    font: `normal normal ${weightRegular} 1.25rem/1.625rem ${mainFont}`,
    'letter-spacing': '0px',
  },
  'heading-04': {
    font: `normal normal ${weightRegular} 1.75rem/2.25rem ${mainFont}`,
    'letter-spacing': '0px',
  },
  'heading-05': {
    font: `normal normal ${weightRegular} 2rem/2.5rem ${mainFont}`,
    'letter-spacing': '0px',
  },
  'heading-06': {
    font: `normal normal ${weightLight} 2.625rem/3.125rem ${mainFont}`,
    'letter-spacing': '0px',
  },
  'heading-07': {
    font: `normal normal ${weightLight} 3.375rem/4rem ${mainFont}`,
    'letter-spacing': '0px',
  },
};

export default typeTokens;
