import Table, { TableHead, TableRow, TableCell, TableBody } from 'components/Table';
import {
  useCurrentInstrumentFormData,
  useDirtyInstrumentFormData,
  useFormDataDirtyFields,
} from 'store/instruments/selectors';
import formatFieldName from 'pages/Instruments/components/Form/EtpDetails/components/ReviewStep/utils/formatFieldName';
import { BENCHMARK_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';

export const BenchmarkDifferencesSection = () => {
  const { deleteUpdatedField } = useSaveETPHook();

  const instrumentFormsData = useCurrentInstrumentFormData(BENCHMARK_STEP);
  const dirtyInstrumentFormsData = useDirtyInstrumentFormData(BENCHMARK_STEP);
  const formDataDirtyFields = useFormDataDirtyFields(BENCHMARK_STEP);

  return formDataDirtyFields.length ? (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell min-width="20%">Name</TableCell>
            <TableCell width="35%">Old Value</TableCell>
            <TableCell width="35%">Proposed Value</TableCell>
            <TableCell width="32px"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formDataDirtyFields?.map((selectedValue, index) => (
            <TableRow key={`${selectedValue + index}`}>
              <TableCell>{formatFieldName(selectedValue)}</TableCell>
              <TableCell>
                {(instrumentFormsData as { [key: string]: string })[selectedValue]}
              </TableCell>
              <TableCell>
                {(dirtyInstrumentFormsData as { [key: string]: string })[selectedValue]}
              </TableCell>
              <TableCell>
                <TrashIcon
                  onClick={() => {
                    deleteUpdatedField(selectedValue);
                  }}
                  style={{ paddingLeft: 0 }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  ) : null;
};
