export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontWeightSemiBold: React.CSSProperties['fontWeight'];
    // HEADING
    headingLarge: React.CSSProperties;
    headingMedium: React.CSSProperties;
    headingSmall: React.CSSProperties;
    // SUBHEADING
    subheadingLarge: React.CSSProperties;
    subheadingMedium: React.CSSProperties;
    subheadingSmall: React.CSSProperties;
    // LABEL
    labelLarge: React.CSSProperties;
    labelMedium: React.CSSProperties;
    labelSmall: React.CSSProperties;
    // NUMBER
    numberXLarge: React.CSSProperties;
    numberLarge: React.CSSProperties;
    numberMedium: React.CSSProperties;
    numberSmall: React.CSSProperties;
    numberXSmall: React.CSSProperties;
    numberTiny: React.CSSProperties;
    // BODY
    bodyLarge: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodySmall: React.CSSProperties;
    // CAPTION
    captionMedium: React.CSSProperties;
    captionSmall: React.CSSProperties;
    captionTiny: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    fontWeightSemiBold?: React.CSSProperties['fontWeight'];
    // HEADING
    headingLarge: React.CSSProperties;
    headingMedium: React.CSSProperties;
    headingSmall: React.CSSProperties;
    // SUBHEADING
    subheadingLarge: React.CSSProperties;
    subheadingMedium: React.CSSProperties;
    subheadingSmall: React.CSSProperties;
    // LABEL
    labelLarge: React.CSSProperties;
    labelMedium: React.CSSProperties;
    labelSmall: React.CSSProperties;
    labelLargeStrong: React.CSSProperties;
    labelMediumStrong: React.CSSProperties;
    labelSmallStrong: React.CSSProperties;
    // NUMBER
    numberXLarge: React.CSSProperties;
    numberLarge: React.CSSProperties;
    numberMedium: React.CSSProperties;
    numberSmall: React.CSSProperties;
    numberXSmall: React.CSSProperties;
    numberTiny: React.CSSProperties;
    numberXLargeStrong: React.CSSProperties;
    numberLargeStrong: React.CSSProperties;
    numberMediumStrong: React.CSSProperties;
    numberSmallStrong: React.CSSProperties;
    numberXSmallStrong: React.CSSProperties;
    numberTinyStrong: React.CSSProperties;
    // BODY
    bodyLarge: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodySmall: React.CSSProperties;
    bodyLargeStrong: React.CSSProperties;
    bodyMediumStrong: React.CSSProperties;
    bodySmallStrong: React.CSSProperties;
    // CAPTION
    captionMedium: React.CSSProperties;
    captionSmall: React.CSSProperties;
    captionTiny: React.CSSProperties;
    captionMediumStrong: React.CSSProperties;
    captionSmallStrong: React.CSSProperties;
    captionTinyStrong: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    fontWeightSemiBold?: true;
    // HEADING
    headingLarge: true;
    headingMedium: true;
    headingSmall: true;
    // SUBHEADING
    subheadingLarge: true;
    subheadingMedium: true;
    subheadingSmall: true;
    // LABEL
    labelLarge: true;
    labelMedium: true;
    labelSmall: true;
    labelLargeStrong: true;
    labelMediumStrong: true;
    labelSmallStrong: true;
    // NUMBER
    numberXLarge: true;
    numberLarge: true;
    numberMedium: true;
    numberSmall: true;
    numberXSmall: true;
    numberTiny: true;
    numberXLargeStrong: true;
    numberLargeStrong: true;
    numberMediumStrong: true;
    numberSmallStrong: true;
    numberXSmallStrong: true;
    numberTinyStrong: true;
    // BODY
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
    bodyLargeStrong: true;
    bodyMediumStrong: true;
    bodySmallStrong: true;
    // CAPTION
    captionMedium: true;
    captionSmall: true;
    captionTiny: true;
    captionMediumStrong: true;
    captionSmallStrong: true;
    captionTinyStrong: true;
  }
}

const twentyOneCoTokens = {
  fontFamily: {
    sans: 'Inter, sans-serif',
    display: 'Inter Display',
    openDigits: 'Open Digits Selected',
  },
  fontSize: {
    75: '0.5rem', // 8px
    100: '0.75rem', // 12px
    200: '0.875rem', // 14px
    300: '1rem', // 16px
    350: '1.125rem', // 18px
    400: '1.25rem', // 20px
    500: '1.5rem', // 24px
    600: '1.75rem', // 28px
    700: '2rem', // 32px
    800: '2.5rem', // 40px
    900: '3rem', // 48px
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeight: {
    1: '1rem',
    2: '1.15rem',
    3: '1.25rem',
    4: '1.5rem',
    5: '1.75rem',
    6: '2rem',
  },
  letterSpacing: {
    1: '-3%',
    2: '-2%',
    3: '-1%',
    4: '-0.5%',
    5: '0%',
    6: '1%',
    7: '3%',
    8: '5%',
  },
};

const twentyOneCoVariants = {
  // HEADING
  headingLarge: {
    fontSize: twentyOneCoTokens.fontSize[900], // 48px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[3],
    fontWeight: twentyOneCoTokens.fontWeight.semibold,
  },
  headingMedium: {
    fontSize: twentyOneCoTokens.fontSize[800], // 40px
    lineHeight: twentyOneCoTokens.lineHeight[2],
    letterSpacing: twentyOneCoTokens.letterSpacing[3],
    fontWeight: twentyOneCoTokens.fontWeight.semibold,
  },
  headingSmall: {
    fontSize: twentyOneCoTokens.fontSize[700], // 32px
    lineHeight: twentyOneCoTokens.lineHeight[2],
    letterSpacing: twentyOneCoTokens.letterSpacing[4],
    fontWeight: twentyOneCoTokens.fontWeight.semibold,
  },
  // SUBHEADING
  subheadingLarge: {
    fontSize: twentyOneCoTokens.fontSize[500], // 24px
    lineHeight: twentyOneCoTokens.lineHeight[2],
    letterSpacing: twentyOneCoTokens.letterSpacing[1],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  subheadingMedium: {
    fontSize: twentyOneCoTokens.fontSize[400], // 20px
    lineHeight: twentyOneCoTokens.lineHeight[2],
    letterSpacing: twentyOneCoTokens.letterSpacing[2],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  subheadingSmall: {
    fontSize: twentyOneCoTokens.fontSize[350], // 18px
    lineHeight: twentyOneCoTokens.lineHeight[3],
    letterSpacing: twentyOneCoTokens.letterSpacing[3],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  // LABEL
  labelLarge: {
    fontSize: twentyOneCoTokens.fontSize[300], // 16px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[3],
    fontWeight: twentyOneCoTokens.fontWeight.regular,
  },
  labelMedium: {
    fontSize: twentyOneCoTokens.fontSize[200], // 14px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[3],
    fontWeight: twentyOneCoTokens.fontWeight.regular,
  },
  labelSmall: {
    fontSize: twentyOneCoTokens.fontSize[100], // 12px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[3],
    fontWeight: twentyOneCoTokens.fontWeight.regular,
  },
  labelLargeStrong: {
    fontSize: twentyOneCoTokens.fontSize[300], // 16px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[3],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  labelMediumStrong: {
    fontSize: twentyOneCoTokens.fontSize[200], // 14px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[3],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  labelSmallStrong: {
    fontSize: twentyOneCoTokens.fontSize[100], // 12px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[3],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  // NUMBER
  numberXLarge: {
    fontSize: twentyOneCoTokens.fontSize[800], // 40px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[1],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberLarge: {
    fontSize: twentyOneCoTokens.fontSize[700], // 32px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[1],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberMedium: {
    fontSize: twentyOneCoTokens.fontSize[500], // 24px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[1],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberSmall: {
    fontSize: twentyOneCoTokens.fontSize[300], // 16px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[1],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberXSmall: {
    fontSize: twentyOneCoTokens.fontSize[200], // 14px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[2],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberTiny: {
    fontSize: twentyOneCoTokens.fontSize[100], // 12px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[2],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberXLargeStrong: {
    fontSize: twentyOneCoTokens.fontSize[800], // 40px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[1],
    fontWeight: twentyOneCoTokens.fontWeight.semibold,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberLargeStrong: {
    fontSize: twentyOneCoTokens.fontSize[700], // 32px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[1],
    fontWeight: twentyOneCoTokens.fontWeight.semibold,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberMediumStrong: {
    fontSize: twentyOneCoTokens.fontSize[500], // 24px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[1],
    fontWeight: twentyOneCoTokens.fontWeight.semibold,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberSmallStrong: {
    fontSize: twentyOneCoTokens.fontSize[300], // 16px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[1],
    fontWeight: twentyOneCoTokens.fontWeight.semibold,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberXSmallStrong: {
    fontSize: twentyOneCoTokens.fontSize[200], // 14px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[2],
    fontWeight: twentyOneCoTokens.fontWeight.semibold,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  numberTinyStrong: {
    fontSize: twentyOneCoTokens.fontSize[100], // 12px
    lineHeight: twentyOneCoTokens.lineHeight[1],
    letterSpacing: twentyOneCoTokens.letterSpacing[2],
    fontWeight: twentyOneCoTokens.fontWeight.semibold,
    fontFamily: twentyOneCoTokens.fontFamily.openDigits,
  },
  // BODY
  bodyLarge: {
    fontSize: twentyOneCoTokens.fontSize[350], // 18px
    lineHeight: twentyOneCoTokens.lineHeight[4],
    letterSpacing: twentyOneCoTokens.letterSpacing[5],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  bodyMedium: {
    fontSize: twentyOneCoTokens.fontSize[200], // 14px
    lineHeight: twentyOneCoTokens.lineHeight[4],
    letterSpacing: twentyOneCoTokens.letterSpacing[5],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  bodySmall: {
    fontSize: twentyOneCoTokens.fontSize[100], // 12px
    lineHeight: twentyOneCoTokens.lineHeight[4],
    letterSpacing: twentyOneCoTokens.letterSpacing[5],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  bodyLargeStrong: {
    fontSize: twentyOneCoTokens.fontSize[350], // 18px
    lineHeight: twentyOneCoTokens.lineHeight[4],
    letterSpacing: twentyOneCoTokens.letterSpacing[5],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  bodyMediumStrong: {
    fontSize: twentyOneCoTokens.fontSize[200], // 14px
    lineHeight: twentyOneCoTokens.lineHeight[4],
    letterSpacing: twentyOneCoTokens.letterSpacing[5],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  bodySmallStrong: {
    fontSize: twentyOneCoTokens.fontSize[100], // 12px
    lineHeight: twentyOneCoTokens.lineHeight[4],
    letterSpacing: twentyOneCoTokens.letterSpacing[5],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  // CAPTION
  captionMedium: {
    fontSize: twentyOneCoTokens.fontSize[200], // 14px
    lineHeight: twentyOneCoTokens.lineHeight[5],
    letterSpacing: twentyOneCoTokens.letterSpacing[8],
    fontWeight: twentyOneCoTokens.fontWeight.regular,
  },
  captionSmall: {
    fontSize: twentyOneCoTokens.fontSize[100], // 12px
    lineHeight: twentyOneCoTokens.lineHeight[5],
    letterSpacing: twentyOneCoTokens.letterSpacing[7],
    fontWeight: twentyOneCoTokens.fontWeight.regular,
  },
  captionTiny: {
    fontSize: twentyOneCoTokens.fontSize[75], // 8px
    lineHeight: twentyOneCoTokens.lineHeight[5],
    letterSpacing: twentyOneCoTokens.letterSpacing[7],
    fontWeight: twentyOneCoTokens.fontWeight.regular,
  },
  captionMediumStrong: {
    fontSize: twentyOneCoTokens.fontSize[200], // 14px
    lineHeight: twentyOneCoTokens.lineHeight[5],
    letterSpacing: twentyOneCoTokens.letterSpacing[8],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  captionSmallStrong: {
    fontSize: twentyOneCoTokens.fontSize[100], // 12px
    lineHeight: twentyOneCoTokens.lineHeight[5],
    letterSpacing: twentyOneCoTokens.letterSpacing[7],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
  captionTinyStrong: {
    fontSize: twentyOneCoTokens.fontSize[75], // 8px
    lineHeight: twentyOneCoTokens.lineHeight[5],
    letterSpacing: twentyOneCoTokens.letterSpacing[7],
    fontWeight: twentyOneCoTokens.fontWeight.medium,
  },
};

export const typography = {
  fontFamily: twentyOneCoTokens.fontFamily.sans,
  fontWeightRegular: twentyOneCoTokens.fontWeight.regular,
  fontWeightMedium: twentyOneCoTokens.fontWeight.medium,
  fontWeightSemiBold: twentyOneCoTokens.fontWeight.semibold,
  fontWeightBold: twentyOneCoTokens.fontWeight.bold,
  // @TODO - remove when "new" Button component is added throughout the app
  button: {
    fontWeight: 500,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'unset', // Remove uppercase default for button text
  },
  // 21.co Variants
  ...twentyOneCoVariants,
} as const;
