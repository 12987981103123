import { DefaultValues } from 'react-hook-form';

// hooks
import { useInstrumentStepFormData } from 'store/instruments/selectors';

// types, constants
import {
  BenchmarkDetailsProps,
  GeneralDetailsProps,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import {
  BENCHMARK_STEP,
  GENERAL_DETAILS_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { instrumentBenchmarkDetailsSchemaUrl } from 'components/Form/formSchemas';

// components
import Form from 'components/Form/Form';
import Footer from 'pages/Instruments/components/Footer';

// styles
import Input from 'components/Input';
import Select from 'components/Select';
import useCurrencyOptions from 'pages/Instruments/hooks/useCurrencyOptions';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ETPStepSaveProgress } from 'pages/Instruments/components/SaveProgress';
import { RebalanceStrategy, RebalanceStrategyLabel } from 'utils/types/product';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';

interface BenchmarkDetailsStepProps {
  goBack: () => void;
  onSubmit: () => void;
}

function BenchmarkDetailsStep({ goBack, onSubmit }: BenchmarkDetailsStepProps) {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(BENCHMARK_STEP) as BenchmarkDetailsProps;
  const generalDetailsData = useInstrumentStepFormData(GENERAL_DETAILS_STEP) as GeneralDetailsProps;

  const isSingleAssetStrategy =
    formData.rebalancingStrategy === RebalanceStrategy.SHORT_STRATEGY ||
    formData.rebalancingStrategy === RebalanceStrategy.LEVERAGED_STRATEGY;

  const isIndexProduct = generalDetailsData.productType === 'Index';

  const saveValues = (data: DefaultValues<BenchmarkDetailsProps>) => {
    saveInstrumentDraft({
      [BENCHMARK_STEP]: data as BenchmarkDetailsProps,
    });
  };

  const currencyOptions = useCurrencyOptions();

  return (
    <Form<BenchmarkDetailsProps>
      initialValues={formData}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={instrumentBenchmarkDetailsSchemaUrl}
    >
      <FormGridContainer>
        <FormGridItem>
          <ProductConfigHeader title="Benchmark Details" />
        </FormGridItem>
        <FormGridItem display={'flex'} justifyContent={'right'}>
          <ETPStepSaveProgress />
        </FormGridItem>
        <FormGridItem>
          <Select
            name="rebalancingStrategy"
            mapOptions={(options) =>
              options
                .filter(
                  (option) =>
                    isIndexProduct ||
                    RebalanceStrategy.LEVERAGED_STRATEGY === option.value ||
                    RebalanceStrategy.SHORT_STRATEGY === option.value
                )
                .map((option) => ({
                  label:
                    RebalanceStrategyLabel[option.value as keyof typeof RebalanceStrategyLabel],
                  value: option.value,
                }))
            }
          />
        </FormGridItem>
        <FormGridItem>
          <Select name="rebalancingFrequency" />
        </FormGridItem>

        {!isSingleAssetStrategy && (
          <>
            <FormGridItem>
              <Select name="indexProvider" />
            </FormGridItem>

            <FormGridItem>
              <Input name="underlyingTicker" />
            </FormGridItem>

            <FormGridItem>
              <Input name="underlyingIsin" />
            </FormGridItem>

            <FormGridItem>
              <Input name="underlyingWkn" />
            </FormGridItem>

            <FormGridItem>
              <Select name="underlyingCurrency" options={currencyOptions} />
            </FormGridItem>

            <FormGridItem>
              <Input name="underlyingName" />
            </FormGridItem>

            <FormGridItem>
              <Input name="indexProviderSpecificId" />
            </FormGridItem>
          </>
        )}
        <Footer goBack={goBack} />
      </FormGridContainer>
    </Form>
  );
}

export default BenchmarkDetailsStep;
