import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';
import { getTokenDetailsUrl, getPartnerDetailsUrl } from 'pages/Tokens/components/Form/utils';
import TokenDetails from 'pages/Tokens/components/Form/TokenDetails/TokenDetails';
import TokenTabs, {
  TOKEN_DETAILS_TAB,
  PARTNER_DETAILS_TAB,
} from 'pages/Tokens/components/TokensTabs';
import PartnerDetails from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails';

function renderCurrentTab(activeTab: typeof TOKEN_DETAILS_TAB | typeof PARTNER_DETAILS_TAB) {
  const tabs = {
    [TOKEN_DETAILS_TAB]: TokenDetails,
    [PARTNER_DETAILS_TAB]: PartnerDetails,
  };
  const Tab = tabs[activeTab];
  return <Tab />;
}

function TokenForm() {
  const navigate = useNavigate();
  const params = useParams();
  const isCreateTokenDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.newTokenDetails)
  );
  const isEditTokenDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.editTokenDetails)
  );
  const isTokenDetailsRoute = isCreateTokenDetailsRoute || isEditTokenDetailsRoute;
  const activeTab = isTokenDetailsRoute ? TOKEN_DETAILS_TAB : PARTNER_DETAILS_TAB;

  const handleChangeTab = (tab: string) => {
    if (tab === TOKEN_DETAILS_TAB) {
      navigate(getTokenDetailsUrl(params.id));
    } else {
      navigate(getPartnerDetailsUrl(params.id));
    }
  };

  return (
    <TokenTabs active={activeTab} onChangeTab={handleChangeTab}>
      {renderCurrentTab(activeTab)}
    </TokenTabs>
  );
}

export default TokenForm;
