import { useParams } from 'react-router-dom';
import { ProductInformation } from './sections/ProductInformation';
import { RegisterInformation } from './sections/RegisterInformation';
import { useSingleRegisterQuery } from 'pages/Orders/Register/hooks/useSingleRegisterDetails';
import { CircularProgress } from '@mui/material';
import { LoadingWrapper } from 'pages/Admin/ContactsPage/ContactsPage.styles';
import { StyledContainer, StyledInformationWrapper, StyledText } from './RegisterDetails.styles';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import Button from 'components/Button';
import { StyledButtonRow } from 'pages/Orders/Register/Register.styles';
import { downloadPdf, downloadXLS } from 'pages/Orders/Register/utils';
import { RegisterPdf } from 'pages/Orders/Register/RegisterPdf/RegisterPdf';
import { CountryCode } from 'utils/constants/country';
import { RegisterDetailsHeader } from './components/RegisterDetailsHeader';

export const RegisterDetails = () => {
  // TODO: update generalTermsDate when available
  const generalTermsDate = 'December 13, 2023';
  // TODO: update finalTermsDate when available
  const finalTermsDate = 'December 13, 2023';
  const { id: registerId } = useParams();
  const {
    data: registerProductData,
    isLoading,
    isFetching,
  } = useSingleRegisterQuery(registerId ?? '');
  const isInformationVisible = registerProductData?.jurisdiction === CountryCode.CH;
  const information = `According to the General Terms and Conditions of the Issuer’s Exchange Traded Products Programme dated as of ${generalTermsDate}, as completed by the Final Terms dated ${finalTermsDate}.`;

  return (
    <div>
      <RegisterDetailsHeader registerProductData={registerProductData} />
      <StyledButtonRow>
        <>
          <Button
            variant="tertiary"
            onClick={() => registerProductData && downloadPdf(registerProductData)}
          >
            <span>PDF</span>
            <DownloadIcon />
          </Button>
          <Button
            variant="tertiary"
            onClick={() => registerProductData && downloadXLS(registerProductData)}
          >
            <span>XLS</span>
            <DownloadIcon />
          </Button>
        </>
      </StyledButtonRow>
      <StyledContainer>
        {isLoading || isFetching ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <>
            <ProductInformation register={registerProductData} />
            {isInformationVisible && (
              <StyledInformationWrapper>
                <StyledText>{information}</StyledText>
              </StyledInformationWrapper>
            )}
            <RegisterInformation register={registerProductData} />
          </>
        )}
      </StyledContainer>
      <RegisterPdf singleRegister={registerProductData} />
    </div>
  );
};
