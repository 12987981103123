import { Paper, styled as MuiStyled, Box } from '@mui/material';
import { TableCell } from 'components/Table';
import Row from 'components/Grid/Row';
import { StyledTableContainer } from 'components/Table/Table.styles';
import fontTypes from 'utils/theme/designTokens/fontTypes';

type StyledToollbarTopRowProps = {
  justifyContent?: 'end' | 'start';
};

export const StyledSectionHeader = MuiStyled('header')`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
  display: flex;
  height: 66px;
  padding: ${({ theme }) => `0 ${theme.spacers['spacing-16']}`};
`;

export const StyledSectionBody = MuiStyled(Box)`
  color: ${({ theme }) => theme.palette.secondary.light};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `0 ${theme.spacers['spacing-40']}`};
  padding: ${({ theme }) => `${theme.spacers['spacing-32']} ${theme.spacers['spacing-16']}`};
`;

export const SectionFooter = MuiStyled(Box)`
  color: ${({ theme }) => theme.palette.secondary.light};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacers['spacing-48']} 0`};

  & > div {
    flex: 1 auto;
  }
`;

export const StyledInformationContainer = MuiStyled(Box)`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  gap: 40px;
`;

export const StyledToolbarsWrapper = MuiStyled(Box)`
  width: 100%;
  gap: 8px;
`;

export const StyledToollbarBottomRow = MuiStyled(Row)`
  justify-content: space-between;
  padding-top: 8px;
  button {
    height: 40px;
  }
`;

export const StyledToollbarTopRow = MuiStyled(Row)<StyledToollbarTopRowProps>`
  justify-content: ${(props) => props.justifyContent ?? 'end'};
  gap: 8px;
`;

export const StyledBottomRowLeft = MuiStyled(Row)`
  width: auto;
  gap: 8px;
`;

export const StyledBottomRowRight = MuiStyled(Row)`
  width: auto;
`;

export const StyledTitle = MuiStyled(Box)`
  margin: 16px 0;
  gap: 8px;
  display: flex;
  > div {
    vertical-align: text-top;
  }
`;

export const StyledPrintContainer = MuiStyled(Box)`
  display: none;
`;

export const StyledPrintPageHeader = MuiStyled(Box)`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
  border-top: ${({ theme }) => `10px solid #F0A50F`};
  display: flex;
  height: 80px;
  margin-top: 2px;
  width: 100%;

  svg {
    margin-left: 50px;
    height: 40px;
    width: 100px;
  }
`;

export const StyledPrintTitle = MuiStyled(Box)`
  ${() => fontTypes['heading-03']};
  color: ${({ theme }) => theme.palette.secondary.light};
  text-align: center;
`;

export const StyledExpectedDeliverablesTableContainer = MuiStyled(StyledTableContainer)`
  width: 100%;
  overflow: hidden;
`;

export const StyledExpectedDeliverableColumn = MuiStyled(TableCell)`
  height: ${({ theme }) => theme.spacers['spacing-48']};
  width: 50%;

  input {
    height: ${({ theme }) => theme.spacers['spacing-32']};
    text-align: right;
  }
`;

export const StyledTermsFooter = MuiStyled(Box)`
  color: ${({ theme }) => theme.palette.secondary.light};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacers['spacing-16']};
  padding: ${({ theme }) => `${theme.spacers['spacing-48']} 0`};

  & > div {
    flex: 1 auto;
  }
`;

export const MuiStyledSectionHeader = MuiStyled(Paper)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  display: 'flex',
  height: '66px',
  padding: `0 ${theme.spacers['spacing-16']}`,
}));
