import {
  StyledBottomRowRight,
  StyledMultiSelectFilter,
  StyledToolbarsWrapper,
  StyledToollbarBottomRow,
} from 'pages/Admin/ContactsPage/ContactsPage.styles';
import { useEffect, useState } from 'react';
import Search from 'components/Search';

import { ParamsType } from 'hooks/useUrlParams';
import { CONTACT_LIST_TYPE, CONTACT_LIST_TYPE_LABEL } from 'utils/types/contacts';

type Props = {
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
};

const ContactsListToolbar = ({ setUrlParams, urlParams }: Props) => {
  const [search, setSearch] = useState(urlParams.search ?? '');
  const [searchInputValue, setSearchInputValue] = useState(urlParams.search ?? '');

  useEffect(() => {
    setUrlParams((prevState) => ({
      ...prevState,
      search: search,
    }));
  }, [search, setUrlParams]);

  return (
    <StyledToolbarsWrapper>
      <StyledToollbarBottomRow>
        <Search
          variant="short"
          placeholder="Search"
          value={searchInputValue}
          onChange={(event) => setSearchInputValue(event.target.value)}
          onBlur={() => setSearch(searchInputValue)}
          onKeyDown={(event) => event.key === 'Enter' && setSearch(searchInputValue)}
          onClear={() => setSearch('')}
        />
        <StyledBottomRowRight>
          <StyledMultiSelectFilter
            options={[
              { label: CONTACT_LIST_TYPE_LABEL.ORDER, value: CONTACT_LIST_TYPE.ORDER },
              { label: CONTACT_LIST_TYPE_LABEL.PRODUCT, value: CONTACT_LIST_TYPE.PRODUCT },
              { label: CONTACT_LIST_TYPE_LABEL.NON_PRODUCT, value: CONTACT_LIST_TYPE.NON_PRODUCT },
              { label: CONTACT_LIST_TYPE_LABEL.PCF, value: CONTACT_LIST_TYPE.PCF },
            ]}
            placeholder="All Types"
            onChange={(_, values) =>
              setUrlParams((prevState) => ({ ...prevState, type: (values as string[]) ?? [] }))
            }
            value={urlParams.type as string[]}
          />
        </StyledBottomRowRight>
      </StyledToollbarBottomRow>
    </StyledToolbarsWrapper>
  );
};

export default ContactsListToolbar;
