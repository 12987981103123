import { Box, styled as MuiStyled } from '@mui/material';

// components
import Toolbar from 'components/Toolbar';

interface TableHeaderProps {
  withDescription?: boolean;
}

export const StyledTableContainer = MuiStyled('section')<{ noPadding?: Boolean }>`
  background-color: white;
  border-radius: 16px;
`;

export const StyledTableHeader = MuiStyled('header')<TableHeaderProps>`
  display: flex;
  flex-direction: column;
  height: ${({ withDescription }) => (withDescription ? '84px' : '66px')};
  justify-content: center;
`;

export const StyledTable = MuiStyled('table')`
  min-width: 100%;
`;

export const StyledTableToolbar = MuiStyled(Toolbar)`
  width: 100%;
`;

export const StyledTableHeaderWrapper = MuiStyled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacers['spacing-16']}`};
  gap: ${({ theme }) => `${theme.spacers['spacing-08']}`};
`;
export const StyledTableHeaderButtons = MuiStyled(Box)`
  display: flex;
  gap: ${({ theme }) => `${theme.spacers['spacing-08']}`};
`;

export const StyledShowMore = MuiStyled('span')`
  margin-left: ${({ theme }) => `${theme.spacers['spacing-08']}`};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledShowMoreListElement = MuiStyled('li')`
  padding: ${({ theme }) => `${theme.spacers['spacing-08']} ${theme.spacers['spacing-16']}`};
`;
