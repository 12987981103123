import { useNavigate } from 'react-router-dom';
// hooks
import GenericTabs from 'shared/GenericTabs';
import OrdersTableToolbar from 'pages/Orders/Orders/components/OrdersToolbar';
import { ORDER_TABS, OrderTabType, defaultOrdersFiltersData } from 'utils/constants/orders';
import { OrdersTable } from 'shared/Tables/OrdersTable/OrdersTable';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { compileUrl } from 'utils/url';
import { privateRoutesUrls } from 'router/constants';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useGenerateOrdersCsvFile } from 'pages/Orders/Orders/hooks/useGenerateOrdersCsvFile'; // @TODO TOKENS - do we need csv downloads?
import { useUrlParams } from 'hooks/useUrlParams';
import { useUserPermissions } from 'store/user/selectors';
import { useLoadOrders } from 'pages/Orders/Orders/Order/hooks/useLoadOrders'; // @TODO TOKENS - change out for use "Tokens page" hook
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';

export const OrdersTokens = () => {
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    defaultOrdersFiltersData,
    privateRoutesUrls.dashboardRoutes.ordersTokens
  );
  const { data: ordersData, isLoading: loading } = useLoadOrders(urlParams, 'Token');
  const paginationResponse = ordersData?.pagination ?? {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
  };
  const orders = ordersData?.orders ?? [];
  const [generateCSVFile] = useGenerateOrdersCsvFile(urlParams, 'Token');
  const pagination = useBackendPagination(paginationResponse, setUrlParams);

  const navigate = useNavigate();
  const permissions = useUserPermissions();

  const generateOrdersAsXls = () => {
    generateCSVFile();
  };

  const OrdersToolbar = (
    <OrdersTableToolbar
      productType={'Token'}
      generateOrdersAsXls={generateOrdersAsXls}
      setUrlParams={setUrlParams}
      urlParams={urlParams}
      disableDownloadButton={!Boolean(orders?.length)}
    />
  );

  const createOwnOrderButton = permissions?.canCreateTokenOrder && (
    <StyledTableTopButton
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.ordersTokensNew)}
      type="button"
      data-qa-id="createOrderButton"
      size="medium"
      key="toolbar-button"
    >
      <span>Create Order</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  const delegateOrderButton = permissions?.canCreateTokenOrderDelegated && (
    <StyledTableTopButton
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.ordersTokensDelegated)}
      type="button"
      data-qa-id="createDelegatedOrderButton"
      size="medium"
      key="toolbar-button"
    >
      <span>New Delegated Order</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  const createOrderButton = delegateOrderButton || createOwnOrderButton;

  return (
    <>
      <PageHeader actionComponent={createOrderButton} pageTitle={'Token Orders'} />
      <GenericTabs
        active={urlParams.tab as OrderTabType}
        setUrlParamsWithDefaults={setUrlParamsWithDefaults}
        tabs={ORDER_TABS}
      >
        <OrdersTable
          productType="Token"
          tableProperties={{
            toolbarTools: [OrdersToolbar],
          }}
          pagination={pagination}
          orders={orders ?? []}
          loadingData={loading}
          setUrlParams={setUrlParams}
          urlParams={urlParams}
          // TODO: make sure the user has permissions to edit the order, move it to variable
          editAction={(order) => {
            const editOrderUrl = compileUrl(privateRoutesUrls.dashboardRoutes.ordersTokensDetails, {
              label: 'id',
              value: String(order._id!),
            });
            navigate(editOrderUrl);
          }}
          deleteAction={() => {}}
        />
      </GenericTabs>
    </>
  );
};

export default OrdersTokens;
