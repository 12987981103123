import Loader from 'components/Loader';
import OrderDetails from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails';
import downloadOrderCsv from 'utils/orders/downloadOrderCsv';
import { OrderPageHeader } from 'pages/Orders/Orders/Order/components/OrderPageHeader';
import { StyledOrderDetailsPageContainer } from 'pages/Orders/Orders/Order/Order.styles';
import { getCurrentOrderQueryKey } from 'utils/constants/reactQueries';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useCurrentOrder } from 'pages/Orders/Orders/Order/hooks/useCurrentOrder';
import { useOrdersActions } from 'pages/Orders/Orders/hooks/useOrdersActions';
import { useParams } from 'react-router-dom';

export const OrderView = () => {
  const { id: orderId } = useParams();
  const { user } = useAuthenticatedUser();

  const { isLoading: loading, data: order } = useCurrentOrder(orderId!);

  const { downloadPdf } = useOrdersActions(orderId);

  return loading ? (
    <Loader />
  ) : (
    <>
      <OrderPageHeader productType="ETP" order={order} />
      {order && (
        <StyledOrderDetailsPageContainer>
          <OrderDetails
            productType="ETP"
            loading={loading}
            user={user}
            order={order}
            orderFlow="confirm"
            downloadCsv={() => downloadOrderCsv(order, user!, 'ETP')}
            downloadPdf={() => downloadPdf(order)}
            refreshOrderQueryKey={getCurrentOrderQueryKey}
          />
        </StyledOrderDetailsPageContainer>
      )}
    </>
  );
};
