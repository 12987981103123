import CircularProgress from 'components/CircularProgress';
import EditContactsListViewToolbar from 'pages/Admin/ContactsPage/components/EditContactsListViewToolbar';
import Table, { TableBody, TableCell, TableProps } from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { Contact, ContactList, ContactListContacts, PartnerContact } from 'utils/types';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { EditContactListTablePartnerRow } from './EditContactListTablePartnerRow';
import { EditContactListTableRow } from './EditContactListTableRow';
import { EditContactsListColumnsConfig } from './tableColumnsConfig';
import { FULL_SPAN } from 'components/Table/TableCell';
import { ParamsType } from 'hooks/useUrlParams';
import { StyledEmptyTableRow } from 'shared/Tables/table.styles';
import { fieldFilter, getPaginationSliceValues, searchFilter } from 'utils/filters';
import { useBackendPagination } from 'hooks/useBackendPagination';

interface EditContactListTableProps {
  contactList: ContactList | undefined;
  contacts: ContactListContacts;
  deleteAction: (data: { contact?: Contact; partner?: PartnerContact }) => void;
  isLoading?: boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  tableProperties: TableProps;
  urlParams: ParamsType;
}

export const EditContactListTable = ({
  contactList,
  contacts,
  deleteAction,
  isLoading,
  setUrlParams,
  tableProperties,
  urlParams,
}: EditContactListTableProps) => {
  const externalContacts = contacts.external
    .filter((item) => fieldFilter(item.type, urlParams?.partner))
    .filter((item) => searchFilter(item, ['name', 'email'], urlParams?.search));

  const partnerContacts = contacts.partners
    .filter((item) => fieldFilter(item._id, urlParams?.partner))
    .filter((item) => searchFilter(item, ['name'], urlParams?.search));

  const mergedContacts = [
    ...externalContacts.map((contact) => ({ ...contact, isPartner: false })),
    ...partnerContacts.map((partner) => ({ ...partner, isPartner: true })),
  ];

  const pagination = useBackendPagination(
    {
      page: urlParams.page ?? DEFAULT_PAGE,
      pageSize: urlParams.pageSize ?? DEFAULT_ITEMS_PER_PAGE,
      total: mergedContacts.length ?? 0,
    },
    setUrlParams
  );

  const paginationValues = getPaginationSliceValues(
    pagination.page,
    pagination.itemsPerPage,
    mergedContacts.length
  );

  const mergedContactsWithPagination = mergedContacts.slice(...paginationValues);

  const toolbar = (
    <EditContactsListViewToolbar
      contactList={contactList}
      urlParams={urlParams}
      setUrlParams={setUrlParams}
      partnersOptions={
        contacts?.partners.map((item) => ({ label: item.name, value: item._id ?? item.name })) ?? []
      }
    />
  );

  return (
    <>
      <Table {...tableProperties} toolbarTools={[toolbar]} pagination={pagination}>
        <TableHeaderWithMultiSort
          defaultSortedColumns={urlParams.sort ?? []}
          columns={EditContactsListColumnsConfig}
          onColumnSort={(columns) => {
            setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
          }}
          showActionsCell={false}
          showChevronColumn
        />
        {isLoading ? (
          <TableBody>
            <StyledEmptyTableRow key={'loader'}>
              <TableCell colSpan={FULL_SPAN}>
                <CircularProgress />
              </TableCell>
            </StyledEmptyTableRow>
          </TableBody>
        ) : (
          <>
            {externalContacts?.length || partnerContacts?.length ? (
              <>
                {mergedContactsWithPagination.map((mergedContact, index) => {
                  if ((mergedContact as PartnerContact & { isPartner: boolean }).isPartner) {
                    const partner = mergedContact as PartnerContact;
                    return (
                      <TableBodyWithStates
                        loadingData={Boolean(isLoading)}
                        hasContent={!isEmpty(partner)}
                        data-qa-id={`${partner.name}-contactlist`}
                        key={index}
                      >
                        <EditContactListTablePartnerRow
                          key={index + partner.name}
                          partner={partner}
                          deleteAction={contactList?._actions?.assign && deleteAction}
                        />
                      </TableBodyWithStates>
                    );
                  } else {
                    const contact = mergedContact as Contact;
                    return (
                      <TableBodyWithStates
                        loadingData={Boolean(isLoading)}
                        hasContent={!isEmpty(contact)}
                        key={index}
                      >
                        <EditContactListTableRow
                          key={index + contact.email}
                          contact={contact}
                          deleteAction={contactList?._actions?.assign && deleteAction}
                        />
                      </TableBodyWithStates>
                    );
                  }
                })}
              </>
            ) : (
              <TableBody>
                <StyledEmptyTableRow key={'empty-row'}>
                  <TableCell colSpan={FULL_SPAN}>No contacts at the moment.</TableCell>
                </StyledEmptyTableRow>
              </TableBody>
            )}
          </>
        )}
      </Table>
    </>
  );
};
