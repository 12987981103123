import DeliverableValue from 'pages/Orders/Orders/Order/components/OrderDetails/components/DeliverableValue';
import Table, { TableBody } from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { Deliverable, Order } from 'utils/types';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { formatToCurrency } from 'utils/formatting';

export const ExpectedDeliverablesInfoTableColumnsConfig = [
  { label: 'Ticker', propName: 'ticker', propType: 'string', width: '38%' },
  { label: 'Market Value', propName: 'marketValue', propType: 'string', width: '38%' },
  { label: 'Quantity', propName: 'quantity', propType: 'string', width: '16%' },
];

interface ExpectedDeliverablesProps {
  order?: Order;
}

export const ExpectedDeliverablesInfoList = ({ order }: ExpectedDeliverablesProps) => {
  const deliverables = order?.deliveries?.expected;
  const totalCoins = deliverables?.reduce((total, deliverable) => {
    return total + (deliverable.amount ?? 0);
  }, 0);
  const totalCurrency = order?.deliveries?.expectedTotalCash;

  return (
    <Table noPadding={true}>
      <TableHeaderWithMultiSort columns={ExpectedDeliverablesInfoTableColumnsConfig} />
      <TableBody>
        {order &&
          deliverables?.map((deliverable: Deliverable) => (
            <MuiStyledTableRow key={`expected-deliverable-${deliverable.ticker}`}>
              <MuiStyledTableCell>{deliverable.ticker}</MuiStyledTableCell>
              <MuiStyledTableCell>
                <DeliverableValue cashValue deliverable={deliverable} order={order} />
              </MuiStyledTableCell>
              <MuiStyledTableCell>
                {order && <DeliverableValue deliverable={deliverable} order={order} />}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
        <MuiStyledTableRow>
          <MuiStyledTableCell>Total</MuiStyledTableCell>
          <MuiStyledTableCell>
            {formatToCurrency(totalCurrency, order?.deliveries?.expectedTotalCurrency ?? 'USD')}
          </MuiStyledTableCell>
          <MuiStyledTableCell>{totalCoins ? totalCoins : '-'}</MuiStyledTableCell>
        </MuiStyledTableRow>
      </TableBody>
    </Table>
  );
};
