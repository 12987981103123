import InputComponent from 'components/Input';
import TableRow, { MuiStyledTableRow } from 'components/Table/TableRow';
import { Box, styled as MuiStyled } from '@mui/material';

type TableRowProps = {
  iserror?: boolean;
};

export const StyledNavInfoBarWrapper = MuiStyled(Box)`
  width: 100%;
  min-height: 56px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  background: white;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

export const StyledNavInfoBarWrapperCounters = MuiStyled(Box)`
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: flex;
  height: inherit;
`;

export const StyledNavSingleCounterCardWrapper = MuiStyled(Box)`
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  color: red;
  height: inherit;
`;

export const StyledNavInfoBarWrapperFilters = MuiStyled(Box)`
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: flex-start;
`;

export const StyledCounterNumber = MuiStyled(Box)<{ color: string }>`
  color: ${(props) => (props.color ? props.color : `#4d4d4d`)};
  background: #f7f7f7;
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const StyledInputComponentWrapper = MuiStyled(Box)`
  width: 288px;
  margin-right: 16px;
`;

export const TableCellContent = MuiStyled(Box)`
  display: flex;
  gap: 8px;
`;

export const StyledTableRow = MuiStyled(TableRow)<TableRowProps>`
  background-color: ${(props) => (props.iserror ? props.theme.palette.red[200] : '')};
`;

export const StyledNavReviewPage = MuiStyled(Box)`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const StyledDiscerapancieRow = MuiStyled(TableRow)<{ iserror?: boolean }>`
  background-color: ${({ theme }) => theme.palette.tertiary.light};
  td {
    color: ${(props) =>
      props.iserror ? props.theme.palette.red[500] : props.theme.palette.secondary.main};
  }
  td:first-child {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  input {
    height: 32px;
    background: ${({ theme }) => theme.palette.tertiary.light};
  }
`;

export const StyledInputComponent = MuiStyled(InputComponent)`
  text-align: right;
  padding-right: 16px;
  height: 32px;
  &:focus,
  &:active {
    background: ${({ theme }) => theme.palette.common.white};
  }
`;

export const StyledClosingNavTableRow = MuiStyled(TableRow)`
  background: ${({ theme }) => theme.palette.tertiary.lighter};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 600;
`;

export const MuiStyledClosingNavTableRow = MuiStyled(MuiStyledTableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
}));
