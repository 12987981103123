/* eslint-disable max-lines */
import ContactsPage from 'pages/Admin/ContactsPage/ContactsPage';
import CreateInstrument from 'pages/Instruments/Create';
import CreateOrder from 'pages/Orders/Orders/Order/CreateOrder/CreateOrder';
import CreateOrderToken from 'pages/Orders/Tokens/CreateOrderToken/CreateOrderToken';
import DelegatedOrderToken from 'pages/Orders/Tokens/DelegateOrderToken/DelegatedOrderToken';
import DelegatedOrder from 'pages/Orders/Orders/Order/DelegateOrder/DelegatedOrder';
import ExchangesPage from 'pages/Admin/ExchangesPage/ExchangesPage';
import LoginRouteGuard from './guards/LoginRouteGuard';
import NotFoundPage from 'pages/NotFound/NotFoundPage';
import Products from 'pages/Instruments/Products';
import ResetPasswordPage from 'pages/SetPassword/SetPasswordPage';
import SetPasswordLandingPage from 'pages/SetPassword';
import Setup2FA from 'pages/Login/Setup2fa';
import TokensPage from 'pages/Tokens';
import Token from 'pages/Tokens/Token';
import UpdateInstrument from 'pages/Instruments/Update';
import WalletsPage from 'pages/Admin/WalletsPage/WalletsPage';
import { AppRoute } from 'router/types';
import { CalendarsPage } from 'pages/Admin/CalendarsPage/CalendarsPage';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { EditContactsListView } from 'pages/Admin/ContactsPage/EditContactsListView/EditContactsListView';
import { EnterOTPCode } from 'pages/Login/EnterOTPCode/EnterOTPCode';
import { LoggedOutPage } from 'pages/LoggedOut/LoggedOutPage';
import { NavReviewPage } from 'pages/Ledger/NavReviewPage/NavReviewPage';
import { OrderView } from 'pages/Orders/Orders/Order/OrderView/OrderView';
import { OrderTokenView } from 'pages/Orders/Tokens/OrderTokenView/OrderTokenView';
import { Orders } from 'pages/Orders/Orders/Orders';
import { OrdersTokens } from 'pages/Orders/Tokens/Orders';
import { PCFDetails } from 'pages/Ledger/PCF/PCFDetails/PCFDetails';
import { PCFPage } from 'pages/Ledger/PCF/PCFPage';
import { PartnerEdit } from 'pages/Partners/PartnerEdit/PartnerEdit';
import { Partners } from 'pages/Partners';
import { RebalanceDetailsPage } from 'pages/Rebalance/RebalanceDetailsPage/RebalanceDetailsPage';
import { RebalancePage } from 'pages/Rebalance/RebalancePage';
import { Register } from 'pages/Orders/Register/Register';
import { RegisterDetails } from 'pages/Orders/Register/RegisterDetails/RegisterDetails';
import { UpdatePassword } from 'pages/Login/UpdatePassword';
import { UserSettingsPage } from 'pages/UserSettings/UserSettingsPage';
import { UsersPage } from 'pages/Admin/UsersPage/UsersPage';
import {
  hideAdminContacts,
  hideNAV,
  hideOrders,
  hidePCF,
  hideTokens,
  hideRebalance,
  hideTokenOrders,
  hideRegister,
} from 'utils/env';
import { RootPage } from 'pages/RootPage/RootPage';
import { TermsOfUse } from 'pages/TermsOfUse/TermsOfUse';

export const publicRoutesUrls = {
  login: '/login',
  notFound: '/not-found',
  resetPasswordInvitation: '/reset-password/:token',
  loggedOut: '/logged-out',
  updatePassword: '/update-password',
  verifyUserInvitation: '/verify-invitation/:token',
  termsOfUse: '/terms',
};

export const privateRoutesUrls = {
  dashboardRoutes: {
    adminUsers: '/admin/users',
    adminExchanges: '/admin/exchanges',
    adminContacts: '/admin/contacts',
    adminWallets: '/admin/wallets',
    adminContactsListEdit: '/admin/contacts/list/edit/:id',
    calendars: '/admin/calendars',
    dashboard: '/dashboard',
    etps: '/products/etps',
    editEtpProductsEtpDetails: '/products/etps/:id/etp-details',
    editEtpProductsPartnerDetails: '/products/etps/:id/partner-details',
    newEtpProductsEtpDetails: '/products/etps/etp-details',
    newEtpProductsPartnerDetails: '/products/etps/partner-details',
    orders: '/orders',
    ordersDelegated: '/delegate-order',
    ordersNew: '/create-order',
    ordersDetails: '/orders/edit/:id',
    partners: '/partners',
    partnersNew: '/partners/new',
    partnersEdit: '/partners/edit/:id',
    rootPath: '/',
    register: '/securities-registry',
    registerDetails: '/securities-registry/:id',
    nav: '/nav',
    pcf: '/pcf',
    pcfNew: '/pcf/new',
    pcfDetails: '/pcf/:productId/:valuationDate',
    rebalance: '/rebalance',
    rebalanceViewDetails: '/rebalance/:productId/:date',
    userSettings: '/user-settings/profile',
    userSettingsSecurity: '/user-settings/security',
    /* ---
    TOKENS
    --- */
    tokens: '/products/tokens',
    newTokenDetails: '/products/tokens/token-details',
    newTokenPartnerDetails: '/products/tokens/partner-details',
    editTokenDetails: '/products/tokens/:id/token-details',
    editTokenPartnerDetails: '/products/tokens/:id/partner-details',
    ordersTokens: '/orders/tokens',
    ordersTokensDelegated: '/delegate-token-order',
    ordersTokensNew: '/create-token-order',
    ordersTokensDetails: '/orders/tokens/edit/:id',
  },
};

export const privateWeakRoutesUrls = {
  twoFa: '/2fa',
  twoFAsetup: '/2fa-setup',
  setPassword: '/set-password',
};

export const publicRoutes = [
  {
    path: publicRoutesUrls.verifyUserInvitation,
    component: SetPasswordLandingPage,
  },
  {
    path: publicRoutesUrls.resetPasswordInvitation,
    component: SetPasswordLandingPage,
  },
  {
    path: publicRoutesUrls.login,
    component: LoginRouteGuard,
  },
  {
    path: publicRoutesUrls.loggedOut,
    component: LoggedOutPage,
  },
  {
    path: publicRoutesUrls.notFound,
    component: NotFoundPage,
  },
  {
    path: publicRoutesUrls.updatePassword,
    component: UpdatePassword,
  },
  {
    path: publicRoutesUrls.termsOfUse,
    component: TermsOfUse,
  },
];

export const privateDashboardRoutes: AppRoute[] = [
  {
    path: privateRoutesUrls.dashboardRoutes.rootPath,
    component: RootPage,
    routeTitle: 'Dashboard',
    defaultPageHeader: true,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.dashboard,
    component: Dashboard,
    routeTitle: 'Dashboard',
    defaultPageHeader: true,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.nav,
    component: NavReviewPage,
    routeTitle: 'NAV Review',
    featureFlag: hideNAV,
    defaultPageHeader: true,
    permissions: ['canViewNav'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.pcf,
    component: PCFPage,
    routeTitle: 'PCF',
    featureFlag: hidePCF,
    permissions: ['canViewPcf'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.pcfDetails,
    component: PCFDetails,
    parentRoutePath: privateRoutesUrls.dashboardRoutes.pcf,
    routeTitle: 'PCF Details',
    featureFlag: hidePCF,
    defaultPageHeader: false,
    permissions: ['canViewPcf'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.orders,
    component: Orders,
    routeTitle: 'Orders',
    featureFlag: hideOrders,
    permissions: ['canViewOrderInformation'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.ordersDetails,
    component: OrderView,
    routeTitle: 'Order Details',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.orders,
    featureFlag: hideOrders,
    defaultPageHeader: false,
    permissions: ['canViewOrderInformation'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.ordersNew,
    component: CreateOrder,
    routeTitle: 'Orders',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.orders,
    featureFlag: hideOrders,
    defaultPageHeader: false,
    permissions: ['canViewOrderInformation'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.ordersDelegated,
    component: DelegatedOrder,
    routeTitle: 'Orders',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.orders,
    permissions: ['canViewOrdersHistorical'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.partners,
    component: Partners,
    routeTitle: 'Partners',
    permissions: ['canGetCompaniesAll'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.partnersNew,
    component: PartnerEdit,
    routeTitle: 'Create Partner',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.partners,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.partnersEdit,
    component: PartnerEdit,
    routeTitle: 'Edit Partner',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.partners,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.rebalance,
    component: RebalancePage,
    routeTitle: 'Rebalance',
    featureFlag: hideRebalance,
    defaultPageHeader: true,
    permissions: ['canViewRebalanceInfo'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.rebalanceViewDetails,
    parentRoutePath: privateRoutesUrls.dashboardRoutes.rebalance,
    component: RebalanceDetailsPage,
    routeTitle: 'Rebalance Details',
    featureFlag: hideRebalance,
    permissions: ['canViewRebalanceInfo'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.register,
    component: Register,
    routeTitle: 'Register',
    defaultPageHeader: false,
    featureFlag: hideRegister,
    permissions: ['canViewBook'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.registerDetails,
    component: RegisterDetails,
    routeTitle: 'Register Details',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.register,
    featureFlag: hideOrders || hideRegister,
    defaultPageHeader: false,
    permissions: ['canViewBook'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.etps,
    component: Products,
    routeTitle: 'ETPs',
    permissions: ['canCreateEtp'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.newEtpProductsEtpDetails,
    component: CreateInstrument,
    routeTitle: 'Create ETP',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.etps,
    defaultPageHeader: false,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.newEtpProductsPartnerDetails,
    component: CreateInstrument,
    routeTitle: 'Create ETP',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.etps,
    defaultPageHeader: false,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.editEtpProductsEtpDetails,
    component: UpdateInstrument,
    routeTitle: 'Update ETP',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.etps,
    defaultPageHeader: false,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.editEtpProductsPartnerDetails,
    component: UpdateInstrument,
    routeTitle: 'Update ETP',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.etps,
    defaultPageHeader: false,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.adminUsers,
    component: UsersPage,
    routeTitle: 'Users',
    permissions: ['isAdmin'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.calendars,
    component: CalendarsPage,
    routeTitle: 'Calendars',
    permissions: ['canEditSystemCalendar'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.adminExchanges,
    component: ExchangesPage,
    routeTitle: 'Exchanges',
    permissions: ['canEditSystemExchanges'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.adminContacts,
    component: ContactsPage,
    routeTitle: 'Contacts Management',
    permissions: ['canEditContacts'],
    featureFlag: hideAdminContacts,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.adminContacts,
    component: ContactsPage,
    routeTitle: 'Contacts Management',
    permissions: ['canViewContactLists'],
    featureFlag: hideAdminContacts,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.adminWallets,
    component: WalletsPage,
    routeTitle: 'Wallets',
    permissions: ['canEditCompanyWallets'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.adminContactsListEdit,
    component: EditContactsListView,
    routeTitle: 'Contacts list edit',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.adminContacts,
    featureFlag: hideAdminContacts,
    defaultPageHeader: false,
  },
  {
    path: privateRoutesUrls.dashboardRoutes.userSettings,
    component: UserSettingsPage,
    routeTitle: 'User Settings',
    defaultPageHeader: false,
  },
  /* --- 
  TOKENS
  --- */
  {
    path: privateRoutesUrls.dashboardRoutes.tokens,
    component: TokensPage,
    routeTitle: 'Tokens',
    featureFlag: hideTokens,
    permissions: ['canCreateToken'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.newTokenDetails,
    component: Token,
    routeTitle: 'Create Token',
    featureFlag: hideTokens,
    permissions: ['canCreateToken'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.newTokenPartnerDetails,
    component: Token,
    routeTitle: 'Create Token',
    featureFlag: hideTokens,
    permissions: ['canCreateToken'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.editTokenDetails,
    component: Token,
    routeTitle: 'Update Token',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.tokens,
    permissions: ['canCreateToken'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.editTokenPartnerDetails,
    component: Token,
    routeTitle: 'Update Token',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.tokens,
    permissions: ['canCreateToken'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.ordersTokens,
    component: OrdersTokens,
    routeTitle: 'Token Orders',
    featureFlag: hideTokens || hideTokenOrders,
    permissions: ['canViewTokenOrderInformation'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.ordersTokensDetails,
    component: OrderTokenView,
    routeTitle: 'Token Order Details',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.ordersTokens,
    featureFlag: hideTokens || hideTokenOrders,
    defaultPageHeader: false,
    permissions: ['canViewTokenOrderInformation'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.ordersTokensNew,
    component: CreateOrderToken,
    routeTitle: 'Token Orders',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.ordersTokens,
    featureFlag: hideTokens || hideTokenOrders,
    defaultPageHeader: false,
    permissions: ['canViewTokenOrderInformation'],
  },
  {
    path: privateRoutesUrls.dashboardRoutes.ordersTokensDelegated,
    component: DelegatedOrderToken,
    routeTitle: 'Token Orders',
    parentRoutePath: privateRoutesUrls.dashboardRoutes.ordersTokens,
    featureFlag: hideTokens || hideTokenOrders,
    permissions: ['canViewTokenOrdersHistorical'],
  },
];

export const privateWeakRoutes = [
  {
    path: privateWeakRoutesUrls.twoFa,
    component: EnterOTPCode,
  },
  {
    path: privateWeakRoutesUrls.twoFAsetup,
    component: Setup2FA,
  },
  {
    path: privateWeakRoutesUrls.setPassword,
    component: ResetPasswordPage,
  },
];
