import { PortfolioComposition } from 'utils/types/pcfs';

import Card from 'components/Card';
import Table, { TableBody, TableCell, TableRow } from 'components/Table';
import { StyledFxRate } from 'pages/Ledger/PCF/components/PCFStyles';

interface PCFFileInfoProps {
  pcf?: PortfolioComposition;
}

function PCFFileInfo({ pcf }: PCFFileInfoProps) {
  return (
    <Card title="Portfolio Composition File">
      <Table noPadding>
        <TableBody>
          <TableRow>
            <TableCell>Valuation Date</TableCell>
            <TableCell align="right">{pcf?.valuationDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Effective Date</TableCell>
            <TableCell align="right">{pcf?.effectiveDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Settlement Date</TableCell>
            <TableCell align="right">{pcf?.settlementDate}</TableCell>
          </TableRow>
          {pcf?.official?.totalProjectedNavPerUnit?.map((totalProjectedNavPerUnit) => (
            <TableRow key={`totalProjectedNavPerUnit${totalProjectedNavPerUnit.currency}`}>
              <TableCell>NAV per Unit {totalProjectedNavPerUnit.currency}</TableCell>
              <TableCell align="right">
                {totalProjectedNavPerUnit.value}
                {pcf?.official?.totalProjectedNavPerUnitFxRates?.find(
                  (fx) => fx.baseCurrency === totalProjectedNavPerUnit.currency
                ) && (
                  <StyledFxRate>
                    (fx rate:{' '}
                    {
                      pcf?.official?.totalProjectedNavPerUnitFxRates?.find(
                        (fx) => fx.baseCurrency === totalProjectedNavPerUnit.currency
                      )?.price
                    }
                    )
                  </StyledFxRate>
                )}
              </TableCell>
            </TableRow>
          ))}
          {pcf?.official?.totalProjectedNavCreationUnit?.map((totalProjectedNavCreationUnit) => (
            <TableRow
              key={`totalProjectedNavCreationUnit${totalProjectedNavCreationUnit.currency}`}
            >
              <TableCell>NAV per Creation Unit {totalProjectedNavCreationUnit.currency}</TableCell>
              <TableCell align="right">
                {totalProjectedNavCreationUnit.value}
                {pcf?.official?.totalProjectedNavCreationUnitFxRates?.find(
                  (fx) => fx.baseCurrency === totalProjectedNavCreationUnit.currency
                ) && (
                  <StyledFxRate>
                    (fx rate:{' '}
                    {
                      pcf?.official?.totalProjectedNavCreationUnitFxRates?.find(
                        (fx) => fx.baseCurrency === totalProjectedNavCreationUnit.currency
                      )?.price
                    }
                    )
                  </StyledFxRate>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

export default PCFFileInfo;
