import Button from 'components/Button';
import Card from 'components/Card';
import useAppDispatch from 'hooks/useAppDispatch';
import { Calendar } from 'utils/types/calendar';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { apiUrls } from 'utils/constants/apiUrls';
import { calendarDeletedNotification } from 'shared/Notifications/calendars.notifications';
import { createNotification } from 'store/notifications/actions';
import { deleteEntityApiCall } from 'utils/api/crudActions';
import { useMutation } from 'react-query';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Typography } from '@mui/material';

export const CalendarDeleteModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const calendar = data.data as Calendar;
  const { isAdmin } = data;
  const uriPath = isAdmin
    ? `${apiUrls.admin}${apiUrls.calendars}/id=${calendar._id}`
    : calendar._actions?.delete.uri;

  const calendarDeleteMutation = useMutation({
    mutationFn: (uriPath: string) => {
      return deleteEntityApiCall<Calendar>(uriPath, 'Error while deleting calendar');
    },
    onSuccess: (res) => {
      dispatch(createNotification(calendarDeletedNotification.success(`${calendar.name}`)));
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(calendarDeletedNotification.error(err.message), err));
    },
  });

  const handleDelete = () => {
    uriPath && calendarDeleteMutation.mutate(uriPath);
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        title="Calendar"
        onClose={closeModal}
        footer={
          <MuiStyledModalFooterButtons>
            <Button
              data-qa-id="cancelButton"
              variant="secondary"
              fullWidth
              onClick={closeModal}
              type="button"
            >
              Cancel
            </Button>
            <Button
              data-qa-id="deleteButton"
              variant="danger"
              fullWidth
              onClick={handleDelete}
              type="submit"
              isLoading={calendarDeleteMutation.isLoading}
            >
              Delete
            </Button>
          </MuiStyledModalFooterButtons>
        }
      >
        <Typography>{`Are you sure you want to permanently delete ${calendar.name} as an calendar? 123`}</Typography>
      </Card>
    </CustomModal>
  );
};
