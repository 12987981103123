import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { StyledCustomHeader } from 'pages/Orders/Register/RegisterDetails/RegisterDetails.styles';
import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import PageTitle from 'components/PageTitle';
import ProductIcon from 'components/ProductIcon';
import { StyledStatusTag } from 'pages/Instruments/Instruments.styles';
import { Order } from 'utils/types/orders';
import { capitalize } from 'lodash';
import { orderStatusTagVariants } from 'utils/constants/orders';
import { variantTypes } from 'components/Tag/Tag.types';
import { BackButton } from 'shared/BackButton';
import { isDeferred } from 'utils/orders';
import { ProductType } from 'utils/types/product';

export interface OrderPageHeaderProps {
  productType: ProductType;
  order?: Order | null;
  isNewOrder?: boolean;
  isDelegatedOrder?: boolean;
}

export const OrderPageHeader = ({
  productType,
  order,
  isNewOrder,
  isDelegatedOrder,
}: OrderPageHeaderProps) => {
  return (
    <PageHeader
      customComponent={
        <StyledCustomHeader>
          <BackButton />
          {order?.status ? (
            <StyledTableCellContentWithIcon>
              <ProductIcon
                className="product-icon"
                iconUrl={order?.product.fundIcon}
                ticker={order?.product.ticker ?? ''}
              />
              <PageTitle>{order?.product.ticker}</PageTitle>
              <PageTitle>{' Order Details'}</PageTitle>
              <StyledStatusTag
                label={capitalize(order.status)}
                variant={orderStatusTagVariants[order.status] as variantTypes}
              />
              {isDeferred(order) && <StyledStatusTag label="Deferred" variant="01" />}
            </StyledTableCellContentWithIcon>
          ) : (
            isNewOrder && (
              <StyledTableCellContentWithIcon>
                <PageTitle>
                  {isDelegatedOrder
                    ? `Create Delegated ${productType} Order`
                    : `Create ${productType} Order`}
                </PageTitle>
              </StyledTableCellContentWithIcon>
            )
          )}
        </StyledCustomHeader>
      }
      customComponentPosition="start"
    />
  );
};
