import ContactsTableToolbar from 'pages/Admin/ContactsPage/components/ContactsToolbar';
import useAppModal from 'hooks/useAppModal';
import { COMPANY_CONTACT_TYPE, Contact } from 'utils/types/contacts';
import { CONTACTS_TAB_VALUE } from 'utils/constants/contacts';
import { CONTACT_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { ContactsTable } from 'shared/Tables/ContactsTable/ContactsTable';
import { ParamsType } from 'hooks/useUrlParams';
import { queryClient } from 'utils/api/queries/queryClient';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useEffect, useMemo } from 'react';
import { useRequestCompaniesMappedQuery } from 'pages/Admin/ContactsPage/hooks/api/useRequestCompaniesMappedQuery';
import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  UNLIMITED_ITEMS_PER_PAGE,
} from 'shared/Tables/table.utils';
import { useContactsListsQuery } from 'pages/Admin/ContactsPage/hooks/api/useContactsListsQuery';
import { useAllPartnerContactsQuery } from 'pages/Admin/ContactsPage/hooks/api/useAllPartnerContacts';
import {
  getAllExternalContacts,
  getAllContacts,
  getAllPartnerContacts,
} from 'utils/constants/reactQueries';
import { useContactsTabData } from 'pages/Admin/ContactsPage/hooks/useContactsTabData';

interface ContactsTabProps {
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
  setUrlParamsWithDefaults: (params: ParamsType) => void;
}

const ContactsTab = ({ urlParams, setUrlParams, setUrlParamsWithDefaults }: ContactsTabProps) => {
  const openModal = useAppModal();

  const { data: partnerContacts, isLoading: isLoadingPartnerContacts } = useAllPartnerContactsQuery(
    urlParams,
    urlParams.subtab === CONTACTS_TAB_VALUE.PARTNER
  );

  const { data: partnerOptions, isLoading: isLoadingPartners } = useRequestCompaniesMappedQuery(
    { page: DEFAULT_PAGE, pageSize: UNLIMITED_ITEMS_PER_PAGE },
    urlParams.subtab === CONTACTS_TAB_VALUE.PARTNER
  );

  const { data: contactListsData, isLoading: isLoadingLists } = useContactsListsQuery(
    {
      pageSize: UNLIMITED_ITEMS_PER_PAGE,
      page: DEFAULT_PAGE,
    },
    true
  );
  const contactsList = contactListsData?.data;
  const listsOptions = contactsList?.map((list) => ({ label: list.name, value: list._id })) ?? [];

  const contactsDataResponse = useContactsTabData(urlParams);

  const contactsPagination = useBackendPagination(
    {
      page: urlParams.page ?? DEFAULT_PAGE,
      pageSize: urlParams.pageSize ?? DEFAULT_ITEMS_PER_PAGE,
      total: contactsDataResponse?.data?.contacts.length ?? 0,
    },
    setUrlParams
  );

  const partnerContactsPagination = useBackendPagination(
    {
      page: urlParams.page ?? DEFAULT_PAGE,
      pageSize: urlParams.pageSize ?? DEFAULT_ITEMS_PER_PAGE,
      total: partnerContacts?.partners?.length ?? 0,
    },
    setUrlParams
  );

  const queryKey = useMemo(() => {
    if (urlParams.subtab === CONTACTS_TAB_VALUE.EXTERNAL) return getAllExternalContacts;
    if (urlParams.subtab === CONTACTS_TAB_VALUE.YOUR) return getAllContacts;
    return getAllPartnerContacts;
  }, [urlParams.subtab]);

  const openContactModal = (
    type: MODAL_ACTIONS,
    apiUri: string,
    contact?: Contact,
    contactTypeFlow?: COMPANY_CONTACT_TYPE
  ) => {
    openModal(
      {
        modalName: CONTACT_MODAL,
        modalData: {
          data: {
            contact,
            contactTypeFlow: contactTypeFlow ?? COMPANY_CONTACT_TYPE.UNAFFILIATED,
            apiUri: apiUri,
          },
          type: type,
        },
      },
      {
        onCloseModalAction: () => queryClient.invalidateQueries({ queryKey: [queryKey] }),
      }
    );
  };

  const ContactsToolbar = (
    <ContactsTableToolbar
      setUrlParams={setUrlParams}
      urlParams={urlParams}
      partnerOptions={partnerOptions}
      listsOptions={listsOptions}
      loading={isLoadingPartners || isLoadingLists}
    />
  );

  useEffect(() => {
    if (!urlParams.subtab) {
      setUrlParams((params) => ({ ...params, subtab: CONTACTS_TAB_VALUE.YOUR }));
    }
  }, [setUrlParams, urlParams.subtab]);

  return (
    <>
      <ContactsTable
        partners={partnerContacts?.partners ?? []}
        contacts={contactsDataResponse?.data?.contacts ?? []}
        isLoading={Boolean(
          contactsDataResponse?.isLoading ||
            isLoadingPartnerContacts ||
            isLoadingPartners ||
            isLoadingLists
        )}
        urlParams={urlParams}
        setUrlParams={setUrlParams}
        editAction={(contact, apiUri, contactTypeFlow) =>
          openContactModal(MODAL_ACTIONS.EDIT, apiUri, contact, contactTypeFlow)
        }
        deleteAction={(contact) => openContactModal(MODAL_ACTIONS.DELETE, '', contact)}
        tableProperties={{
          toolbarTools: [ContactsToolbar],
        }}
        pagination={
          urlParams.subtab === CONTACTS_TAB_VALUE.PARTNER
            ? partnerContactsPagination
            : contactsPagination
        }
        setUrlParamsWithDefaults={setUrlParamsWithDefaults}
      />
    </>
  );
};

export default ContactsTab;
