import { ComponentProps, Fragment, ReactNode } from 'react';

// styles
import { StyledContainer } from 'components/Toolbar/Toolbar.styles';

interface ToolbarProps extends ComponentProps<'div'> {
  tools: ReactNode[];
}
const Toolbar = ({ tools, className }: ToolbarProps) => (
  <StyledContainer className={className}>
    {tools.map((tool, index) => (
      <Fragment key={index}>{tool}</Fragment>
    ))}
  </StyledContainer>
);

export default Toolbar;
