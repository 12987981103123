import Loader from 'components/Loader';
import OrderDetails from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails';
import downloadOrderCsv from 'utils/orders/downloadOrderCsv';
import { OrderPageHeader } from 'pages/Orders/Orders/Order/components/OrderPageHeader';
import { StyledOrderDetailsPageContainer } from 'pages/Orders/Orders/Order/Order.styles';
import { getCurrentTokenOrderQueryKey } from 'utils/constants/reactQueries';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useCurrentTokenOrder } from 'pages/Orders/Orders/Order/hooks/useCurrentOrder';
import { useOrdersActions } from 'pages/Orders/Orders/hooks/useOrdersActions';
import { useParams } from 'react-router-dom';

export const OrderTokenView = () => {
  const { id: orderId } = useParams();
  const { user } = useAuthenticatedUser();

  const { isLoading: loading, data: order } = useCurrentTokenOrder(orderId!);

  const { downloadPdf } = useOrdersActions(orderId);

  return loading ? (
    <Loader />
  ) : (
    <>
      <OrderPageHeader productType="Token" order={order} />
      {order && (
        <StyledOrderDetailsPageContainer>
          <OrderDetails
            productType="Token"
            loading={loading}
            user={user}
            order={order}
            orderFlow="confirm"
            downloadCsv={() => downloadOrderCsv(order, user!, 'Token')}
            downloadPdf={() => downloadPdf(order)}
            refreshOrderQueryKey={getCurrentTokenOrderQueryKey}
          />
        </StyledOrderDetailsPageContainer>
      )}
    </>
  );
};
