/* eslint-disable complexity */
import Table, { TableBody, TableCell, TableHead, TableRow } from 'components/Table';
import { Deliverable, Order, OrderTrade } from 'utils/types/orders';
import { formatToCurrency } from 'utils/formatting';
// import { StyledLightTableHead } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { ProductType } from 'utils/types/product';
import TableHeader from 'shared/Tables/Table/TableHeader';

interface ExpectedDeliverablesSettlementTableProps {
  productType: ProductType;
  order?: Order;
  deliverables?: Deliverable[];
}

export const ActualDeliverablesTable = ({
  productType,
  order,
}: ExpectedDeliverablesSettlementTableProps) => {
  const ActualDeliverablesTableColumnsConfig = [
    { label: 'Trade Date', propName: '', propType: 'string', sort: false },
    { label: 'Coin', propName: '', propType: 'string', sort: false },
    { label: 'Quantity', propName: '', propType: 'string', sort: false },
    { label: 'Price', propName: '', propType: 'string', sort: false },
    { label: 'Value', propName: '', propType: 'string', sort: false },
    { label: 'FX rate to USD', propName: '', propType: 'string', sort: false },
    { label: 'Price (USD)', propName: '', propType: 'string', sort: false },
    { label: 'Value (USD)', propName: '', propType: 'string', sort: false },
    { label: 'Creation Fees', propName: '', propType: 'string', sort: false },
    { label: 'Total Cost', propName: '', propType: 'string', sort: false },
    { label: 'Slippage', propName: '', propType: 'string', sort: false },
  ];

  return (
    <>
      {productType === 'ETP' && (
        <>
          <Table noPadding>
            <TableHeader columns={ActualDeliverablesTableColumnsConfig} />
            <TableBody>
              {order &&
                order?.trades?.map((trade: OrderTrade, index: number) => (
                  <TableRow key={`expected-deliverable-${index}`}>
                    <TableCell key="tradeDate">{order.dealDate}</TableCell>
                    <TableCell key="coin">{trade.baseCurrency}</TableCell>
                    <TableCell key="executedQty">{trade.quantity}</TableCell>
                    <TableCell key="price">
                      {formatToCurrency(trade.price, trade.quoteCurrency)}
                    </TableCell>
                    <TableCell key="value">
                      {formatToCurrency(trade.total, trade.quoteCurrency)}
                    </TableCell>
                    {/* TODO: this will be updated */}
                    <TableCell key="fxRateToUSD">1</TableCell>
                    <TableCell key="priceUSD">
                      {formatToCurrency(trade.price, trade.quoteCurrency)}
                    </TableCell>
                    <TableCell key="valueUSD">
                      {formatToCurrency(trade.total, trade.quoteCurrency)}
                    </TableCell>
                    <TableCell key="creationFees">
                      {formatToCurrency(trade.executionFee, trade.quoteCurrency)}
                    </TableCell>
                    <TableCell key="totalCost">
                      {formatToCurrency(trade.totalWithFees, trade.quoteCurrency)}
                    </TableCell>
                    <TableCell key="slippage">
                      {formatToCurrency(trade.slippage, trade.quoteCurrency)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <br></br>
          <Table noPadding>
            <TableHead>
              <TableRow>
                <TableCell>Total Cost of Trades</TableCell>
                <TableCell align="right">
                  {/* TODO: this will be updated */}
                  {formatToCurrency(
                    order?.tradesTotalWithExecutionFees,
                    order?.deliveries?.expectedTotalCurrency ?? 'USD'
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Admin fee</TableCell>
                <TableCell align="right">
                  {formatToCurrency(
                    order?.tradesAdminFee,
                    order?.deliveries?.expectedTotalCurrency ?? 'USD'
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table noPadding>
            <TableHead>
              <TableRow>
                <TableCell>Total Actual Deliverable</TableCell>
                <TableCell align="right">
                  {formatToCurrency(
                    order?.tradesTotalWithTotalFee,
                    order?.deliveries?.expectedTotalCurrency ?? 'USD'
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {order?.type === 'REDEMPTION' && order.deliveryType === 'CASH'
                    ? 'Estimated Deliverable'
                    : 'Amount Pre-Funded'}
                </TableCell>
                <TableCell align="right">
                  {formatToCurrency(
                    order?.deliveries?.expectedTotalCash,
                    order?.deliveries?.expectedTotalCurrency
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table noPadding>
            <TableHead>
              <TableRow>
                <TableCell>Amount to Settle</TableCell>
                <TableCell align="right">
                  {order?.type === 'REDEMPTION' && order.deliveryType === 'CASH'
                    ? formatToCurrency(order?.tradesTotalWithTotalFee, 'USD')
                    : formatToCurrency(order?.slippage, 'USD')}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </>
      )}
      {productType === 'Token' && (
        <Table noPadding>
          <TableHead>
            <TableRow>
              <TableCell>Ticker</TableCell>
              <TableCell align="right">Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order &&
              order?.trades?.map((trade: OrderTrade, index: number) => (
                <TableRow key={`expected-deliverable-${index}`}>
                  <TableCell key="tradeDate">{order.product.ticker}</TableCell>

                  <TableCell align="right" key="executedQty">
                    {trade.quantity}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
