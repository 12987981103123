import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { StyledCustomHeader } from 'pages/Orders/Register/RegisterDetails/RegisterDetails.styles';
import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import PageTitle from 'components/PageTitle';
import { INSTRUMENTS_STATUS_LABEL } from 'pages/Instruments/Instruments.constants';
import { Instrument, ProductStatus } from 'utils/types/product';
import ProductIcon from 'components/ProductIcon';
import { StyledStatusTag } from 'pages/Instruments/Instruments.styles';
import { BackButton } from 'shared/BackButton';

export interface ETPPageHeaderProps {
  instrument?: Instrument | null;
  isNewProduct?: boolean;
}

export const ETPPageHeader = ({ instrument, isNewProduct }: ETPPageHeaderProps) => {
  return (
    <PageHeader
      customComponent={
        <StyledCustomHeader>
          <BackButton />
          {instrument ? (
            <StyledTableCellContentWithIcon>
              <ProductIcon
                className="product-icon"
                iconUrl={instrument?.fundIcon}
                ticker={instrument?.ticker ?? ''}
              />
              <PageTitle>
                {instrument?.ticker}
                {' / '}
                {instrument?.name}
              </PageTitle>
              <StyledStatusTag
                label={
                  INSTRUMENTS_STATUS_LABEL[instrument.status.toUpperCase() as ProductStatus] ??
                  INSTRUMENTS_STATUS_LABEL.PENDING
                }
                variant={ProductStatus.ACTIVE === instrument.status.toUpperCase() ? '03' : '01'}
              />
            </StyledTableCellContentWithIcon>
          ) : (
            isNewProduct && (
              <StyledTableCellContentWithIcon>
                <PageTitle>Create ETP</PageTitle>
              </StyledTableCellContentWithIcon>
            )
          )}
        </StyledCustomHeader>
      }
      customComponentPosition="start"
    />
  );
};
