// import fontTypes from 'utils/theme/designTokens/fontTypes';
import { styled as MuiStyled } from '@mui/material';
import { TableHead as MuiTableHead } from '@mui/material';

const TableHead = MuiStyled(MuiTableHead)(({ theme }) => ({
  height: theme.spacers['spacing-40'],
  '& .MuiTableRow-root': {
    borderRadius: theme.spacers['spacing-08'],
  },
  '& .MuiTableCell-root': {
    whiteSpace: 'nowrap',
    color: theme.palette.text.default.standard,
    fontWeight: 500,
    backgroundColor: theme.customPalette.static.background.default.strong,
  },
}));

export default TableHead;
