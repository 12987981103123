import Table, { TableProps } from 'components/Table';

import { ParamsType } from 'hooks/useUrlParams';
import GenericTabs from 'shared/GenericTabs';
import { CONTACTS_TAB_VALUE, getContactsTabs } from 'utils/constants/contacts';
import { TAB_TYPE } from 'components/TabNavigation/TabNavigation.types';
import { ExternalContactsTable } from './components/ExternalContactsTable/ExternalContactsTable';
import { PartnerContactsTable } from './components/PartnerContactsTable/PartnerContactsTable';
import { COMPANY_CONTACT_TYPE, Contact, PartnerContact } from 'utils/types/contacts';
import { YourContactsTable } from './components/YourContactsTable/YourContactsTable';
import { PaginationProps } from 'utils/types';
import { useAuthenticatedUser } from 'store/user/selectors';

interface ContactsTableProps {
  contacts: Contact[];
  partners: PartnerContact[];
  isLoading: Boolean;
  tableProperties: TableProps;
  editAction: (contact: Contact, apiUri: string, contactTypeFlow: COMPANY_CONTACT_TYPE) => void;
  deleteAction: (contact: Contact) => void;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  setUrlParamsWithDefaults: (params: ParamsType) => void;
  urlParams: ParamsType;
  pagination: PaginationProps;
}

export const ContactsTable = ({
  contacts,
  isLoading,
  partners,
  tableProperties,
  editAction,
  deleteAction,
  urlParams,
  setUrlParams,
  setUrlParamsWithDefaults,
  pagination,
}: ContactsTableProps) => {
  const { user } = useAuthenticatedUser();

  return (
    <Table
      {...tableProperties}
      pagination={pagination}
      tabsComponent={
        <GenericTabs
          active={urlParams.subtab as CONTACTS_TAB_VALUE}
          setUrlParamsWithDefaults={setUrlParamsWithDefaults}
          tabs={getContactsTabs(user?.organization.name)}
          type={TAB_TYPE.DEFAULT_NO_BORDER}
          rootTab="contacts"
        />
      }
    >
      {urlParams.subtab === CONTACTS_TAB_VALUE.YOUR && (
        <YourContactsTable
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          editAction={(contact) => {
            editAction(contact, contact._actions.update.uri, COMPANY_CONTACT_TYPE.IMPLICIT);
          }}
          deleteAction={deleteAction}
          contacts={contacts}
          isLoading={isLoading}
          pagination={pagination}
        />
      )}
      {urlParams.subtab === CONTACTS_TAB_VALUE.PARTNER && (
        <PartnerContactsTable
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          partners={partners}
          isLoading={isLoading}
          editContactAction={(contact) =>
            editAction(contact, contact._actions.update.uri, COMPANY_CONTACT_TYPE.AFFILIATED)
          }
          deleteAction={deleteAction}
        />
      )}
      {urlParams.subtab === CONTACTS_TAB_VALUE.EXTERNAL && (
        <ExternalContactsTable
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          editAction={(contact) => {
            editAction(contact, contact._actions.update.uri, COMPANY_CONTACT_TYPE.UNAFFILIATED);
          }}
          deleteAction={deleteAction}
          contacts={contacts}
          isLoading={isLoading}
          pagination={pagination}
        />
      )}
    </Table>
  );
};
