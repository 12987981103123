import Button from 'components/Button';
import Card from 'components/Card';
import { Box, styled as MuiStyled } from '@mui/material';

export const OrderFormContainer = MuiStyled(Box)`
  align-self: flex-start;
  flex: 1 auto;
  min-width: 325px;
  position: sticky;
  top: 0;
`;

export const CreateOrderContainer = MuiStyled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacers['spacing-32']};
  margin-top: ${({ theme }) => theme.spacers['spacing-32']};
`;

export const StyledOrderDetailsPageContainer = MuiStyled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacers['spacing-32']};
  margin-top: ${({ theme }) => theme.spacers['spacing-32']};
`;

export const StyledCutoffContainer = MuiStyled(Box)`
  padding: ${({ theme }) => `${theme.spacers['spacing-16']} 0;`};
`;

export const StyledDelegateButton = MuiStyled(Button)`
  display: flex;
  justify-content: 'space-between';
  gap: 16px;
`;

export const StyledOrderForm = MuiStyled(Card)`
  form {
    gap: 32px;
  }
`;
