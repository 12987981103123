import { PortfolioComposition } from 'utils/types/pcfs';

import Card from 'components/Card';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'components/Table';
import { ProductConstituentAsset } from 'utils/types';
import { keyBy } from 'lodash';

interface PCFCompositionProps {
  pcf?: PortfolioComposition & { constituentAssets?: ProductConstituentAsset[] };
}

function PCFComposition({ pcf }: PCFCompositionProps) {
  const productConstituents = keyBy(pcf?.constituentAssets, 'ticker');

  return (
    <Card title="Portfolio Composition" maxBodyHeight="fit-content" maxCardHeight="fit-content">
      <Table noPadding>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Ticker</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Weight</TableCell>
            <TableCell align="right">Deliverable per Creation Unit</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {pcf?.official?.constituents?.map((constituent) => (
            <TableRow key={constituent.ticker}>
              <TableCell>
                {productConstituents[constituent.ticker]?.isLeveraged
                  ? `${constituent.name} Exposure`
                  : constituent.name}
              </TableCell>
              <TableCell>{constituent.ticker}</TableCell>
              <TableCell align="right">{constituent.price}</TableCell>
              <TableCell align="right">{constituent.weight}</TableCell>
              <TableCell align="right">{constituent.amountPerCreationUnit}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

export default PCFComposition;
