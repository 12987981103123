import Table, { TableBody } from 'components/Table';
import { Chain, ProductType } from 'utils/types/product';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { ORDER_IN_KIND } from 'utils/constants';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import AddressLink from 'components/AddressLink';
import { Order } from 'utils/types/orders';

const SettlementInfoColumnsConfig = [
  {
    label: 'Primary Settlement Location AP',
    propName: 'primarySettlementLocationAP',
    propType: 'string',
    width: '60%',
  },
  { label: '', propName: 'actions', propType: 'actions', width: '40%' },
];
const PrimarySettlementLocationIssuerColumnsConfig = [
  {
    label: 'Primary Settlement Location Issuer',
    propName: 'primarySettlementLocationIssuer',
    propType: 'string',
    width: '60%',
  },
  { label: '', propName: 'actions', propType: 'actions', width: '40%' },
];
const TokensColumnsConfig = [
  {
    label: 'Destination Wallets',
    propName: 'destinationWallets',
    propType: 'string',
    width: '60%',
  },
  {
    label: 'Wallet Address',
    propName: 'walletAddress',
    propType: 'actions',
    width: '40%',
    columnAlignment: 'right',
  },
];

interface SettlementInformationProps {
  productType: ProductType;
  className?: string;
  order?: Order;
}

const SettlementInformation = ({ productType, className, order }: SettlementInformationProps) => {
  return (
    <Box className={className} component="section">
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Settlement Information</Typography>
      </MuiStyledSectionHeader>
      {productType === 'ETP' && (
        <>
          <Table noPadding={true}>
            <TableHeaderWithMultiSort columns={SettlementInfoColumnsConfig} />
            <TableBody>
              <MuiStyledTableRow>
                <MuiStyledTableCell>BP ID (SIX SIS AG)</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.settlement?.authorizedParticipantBPID}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Delivery/Receive</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.deliveryType === ORDER_IN_KIND ? 'RECEIVE' : 'DELIVERY'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            </TableBody>
          </Table>

          <Table noPadding={true}>
            <TableHeaderWithMultiSort columns={PrimarySettlementLocationIssuerColumnsConfig} />
            <TableBody>
              <MuiStyledTableRow>
                <MuiStyledTableCell>BP ID (SIX SIS AG)</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {process.env.REACT_APP_SETTLEMENT_BPID}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Delivery/Receive</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.deliveryType === ORDER_IN_KIND ? 'DELIVERY' : 'RECEIVE'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            </TableBody>
          </Table>
        </>
      )}
      {productType === 'Token' && (
        <>
          <Table noPadding={true}>
            <TableHeaderWithMultiSort
              columns={TokensColumnsConfig}
              columnAlignment={{ walletAddress: 'right' }}
            />
            <TableBody>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Copper</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  <AddressLink
                    destinationChain={order?.product?.destinationChain || Chain.ETHEREUM}
                    address={order?.product?.contractAddress || ''}
                  />
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default SettlementInformation;
