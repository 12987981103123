// components
import Button from 'components/Button';
import { CalendarCountryTitles } from 'utils/constants/calendar';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { TablePopoverMenu } from 'shared/Tables/Table/components/TablePopoverMenu/TablePopoverMenu';
// hooks
import { useState } from 'react';
//icons
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
//styles
import { MuiStyledActionsTableCell } from 'shared/Tables/table.styles';
import { StyledIcon } from 'layouts/DashboardContainer/SideNavigationMenu.style';
import {
  StyledButton,
  MuiStyledTableCellWithChevron,
  MuiStyledCalendarEventRow,
  MuiStyledAddButtonRowWrapper,
  MuiCalendarTableCell,
} from 'shared/Tables/CalendarsTable/CalendarsTable.styles';
//types
import { Calendar, CalendarEvent } from 'utils/types/calendar';
//utils
import { format, parseISO } from 'date-fns';
import { dailyTableDateFormat } from 'shared/Tables//table.utils';

type CalendarsTableProps = {
  calendar: Calendar;
  deleteAction: () => void;
  editAction: () => void;
  subitemEditAction: (calendarEvent?: CalendarEvent) => void;
  subitemDeleteAction: (calendarEvent: CalendarEvent) => void;
  showActionsCell: boolean;
};

export const CalendarsTableRow = ({
  calendar,
  editAction,
  deleteAction,
  subitemEditAction,
  subitemDeleteAction,
  showActionsCell,
}: CalendarsTableProps) => {
  const [expanded, setExpanded] = useState(false);
  const onRowClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <MuiStyledTableRow
        data-qa-id={`${calendar.name}-row`}
        key={calendar._id}
        onClick={onRowClick}
      >
        <MuiStyledTableCellWithChevron data-qa-id={`${calendar.name}-name`}>
          <StyledIcon>{expanded ? <ChevronUp /> : <ChevronDown />}</StyledIcon>
          {calendar.name}
        </MuiStyledTableCellWithChevron>
        <MuiCalendarTableCell data-qa-id="country">
          {CalendarCountryTitles[calendar.country]}
        </MuiCalendarTableCell>
        <MuiCalendarTableCell data-qa-id="year">{calendar.year}</MuiCalendarTableCell>
        <MuiCalendarTableCell data-qa-id="timezone">{calendar.timezone}</MuiCalendarTableCell>
        <MuiCalendarTableCell data-qa-id="lastUpdated">
          {calendar.updatedAt && format(parseISO(calendar.updatedAt), dailyTableDateFormat)}
        </MuiCalendarTableCell>
        <MuiStyledActionsTableCell data-qa-id="editIcons">
          {showActionsCell && (
            <>
              <TablePopoverMenu id={calendar._id ?? calendar.name} className="actionsCell">
                <ul>
                  {calendar._actions?.update && (
                    <li>
                      <StyledButton onClick={editAction} fullWidth variant="ghost">
                        Edit Calendar
                      </StyledButton>
                    </li>
                  )}
                  {calendar._actions?.delete && (
                    <li>
                      <StyledButton onClick={deleteAction} fullWidth variant="ghost">
                        Delete Calendar
                      </StyledButton>
                    </li>
                  )}
                </ul>
              </TablePopoverMenu>
            </>
          )}
        </MuiStyledActionsTableCell>
      </MuiStyledTableRow>
      {expanded && (
        <>
          {calendar.events.map((calendarEvent, index) => {
            return (
              <MuiStyledCalendarEventRow
                data-qa-id={`${calendar.name}-holiday`}
                key={calendarEvent.date + index}
                onClick={() => {
                  subitemEditAction(calendarEvent);
                }}
              >
                <MuiStyledTableCell
                  data-qa-id={`${calendar.name}-${calendarEvent.description}-holidayName`}
                  colSpan={3}
                >
                  {calendarEvent.description}
                </MuiStyledTableCell>
                <MuiStyledTableCell
                  data-qa-id={`${calendar.name}-${calendarEvent.description}-holidayDate`}
                  colSpan={2}
                >
                  {format(parseISO(calendarEvent.date), dailyTableDateFormat)}
                </MuiStyledTableCell>
                {showActionsCell && (
                  <MuiStyledActionsTableCell data-qa-id="editIcons">
                    <TablePopoverMenu id={calendar._id ?? calendar.name} className="actionsCell">
                      <Button
                        onClick={() => {
                          subitemEditAction(calendarEvent);
                        }}
                        fullWidth
                        variant="ghost"
                      >
                        Edit Holiday
                      </Button>
                      <Button
                        onClick={() => {
                          subitemDeleteAction(calendarEvent);
                        }}
                        fullWidth
                        variant="ghost"
                      >
                        Delete Holiday
                      </Button>
                    </TablePopoverMenu>
                  </MuiStyledActionsTableCell>
                )}
              </MuiStyledCalendarEventRow>
            );
          })}
          <MuiStyledTableRow>
            <MuiStyledAddButtonRowWrapper colSpan={6}>
              <Button
                data-qa-id="addHolidayButton"
                variant="tertiary"
                size="small"
                onClick={() => subitemEditAction()}
              >
                <PlusIcon />
                Add Holiday
              </Button>
            </MuiStyledAddButtonRowWrapper>
          </MuiStyledTableRow>
        </>
      )}
    </>
  );
};
