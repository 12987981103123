import { DateValue } from 'components/DatepickerSingleDate';
import colors from 'utils/theme/designTokens/colors';
import {
  ProductSelectFilter,
  StyledNavInfoBarContainer,
  StyledNavInfoBarWrapper,
  StyledNavInfoBarWrapperCounters,
} from 'pages/Ledger/PCF/components/PCFStyles';
import { PCFOverviewStatusBarInfo } from 'utils/types/pcfs';
import { PCFSingleCounterCard } from 'pages/Ledger/PCF/components/PCFSingleCounterCard';
import { PCFTabType, PCF_TAB_STATUS } from 'utils/constants/pcf';
import { ParamsType, SetURLParams } from 'hooks/useUrlParams';
import { getUtcDateObject } from 'utils/date';
import { Box } from '@mui/material';
import DateRangePicker from 'components/Datepicker';
import { useAuthenticatedUser } from 'store/user/selectors';
import { PartnerType } from 'utils/types/partner';

type PCFInfoBarProps = {
  urlParams: ParamsType;
  setUrlParams: SetURLParams;
  children?: React.ReactNode;
  overviewBarInfoData?: PCFOverviewStatusBarInfo;
  tab: PCFTabType;
  onDateChange: (startDate: DateValue, endDate: DateValue) => void;
  date: string | null;
};

export const PCFInforBar = ({
  setUrlParams,
  urlParams,
  children,
  overviewBarInfoData,
  onDateChange,
  tab,
}: PCFInfoBarProps) => {
  const user = useAuthenticatedUser();
  const isIssuerOrSysAdmin =
    user.user?.permissions?.isIssuer || user.user?.permissions?.isSystemAdmin;
  const currentField =
    user.user?.organization.type === PartnerType.AUTHORIZED_PARTICIPANT
      ? 'effectiveDate'
      : 'valuationDate';
  const startDate = (urlParams[currentField] as string) ?? '';
  const endDate = (urlParams[`${currentField}end`] as string) ?? '';
  return (
    <StyledNavInfoBarContainer>
      {children}
      <StyledNavInfoBarWrapper
        sx={{
          ...(!isIssuerOrSysAdmin && { flexDirection: 'column', alignItems: 'flex-end' }),
        }}
      >
        {isIssuerOrSysAdmin && (
          <StyledNavInfoBarWrapperCounters>
            <PCFSingleCounterCard
              number={overviewBarInfoData?.total}
              numberColor={colors['color-inverse-hover']}
              label={'Total PCFs'}
            />
            <PCFSingleCounterCard
              number={overviewBarInfoData?.totalConfirmed}
              numberColor={colors['color-inverse-success']}
              label={'Confirmed'}
            />
            <PCFSingleCounterCard
              number={overviewBarInfoData?.totalPendingApproval}
              numberColor={colors['color-active-primary']}
              label={'Pending Approval'}
            />
            <PCFSingleCounterCard
              number={overviewBarInfoData?.totalPendingUpload}
              numberColor={colors['color-danger']}
              label={'Pending Upload'}
            />
          </StyledNavInfoBarWrapperCounters>
        )}
        <Box
          display={'flex'}
          gap={1}
          sx={{
            '.datePickerTextField .MuiInputBase-root': {
              height: '48px',
            },
            '.date-picker-calendar-button': {
              height: '100%',
              svg: {
                width: '24px',
                height: '24px',
              },
            },
          }}
        >
          <ProductSelectFilter
            placeholder="All instruments"
            clearable
            onChange={(product) =>
              setUrlParams((prevState) => ({ ...prevState, productId: product ?? '' }))
            }
            value={String(urlParams.productId)}
          />
          {tab === PCF_TAB_STATUS.PAST && (
            <DateRangePicker
              onChange={(val) => {
                onDateChange(val[0], val[1]);
              }}
              startDate={startDate ? getUtcDateObject(startDate) : null}
              endDate={endDate ? getUtcDateObject(endDate) : null}
              maxDate={new Date()}
            />
          )}
        </Box>
      </StyledNavInfoBarWrapper>
    </StyledNavInfoBarContainer>
  );
};
