import BigNumber from 'bignumber.js';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, NAV_REVIEW_MODAL } from 'shared/Modals/constants';
import { NavReviewInforBar } from './components/NavReviewInforBar';
import { ParamsType } from 'hooks/useUrlParams';
import { StyledNavReviewPage } from 'pages/Ledger/NavReviewPage/components/styles';
import { format } from 'date-fns';
import { useNavReviewDataQuery } from './hooks/useNavReviewDataQuery';
import { useMemo, useState } from 'react';
import { NavReviewItem, NavReviewItemWithProduct, NavReviewResponseDto } from 'utils/types/nav';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import { NavStatusBarInfo } from 'utils/types/nav';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import NavReviewTable from 'shared/Tables/NavReviewTable/NavReviewTable';

const mapNavReviewItems = (navReviewItems: NavReviewResponseDto[]): NavReviewItemWithProduct[] => {
  return navReviewItems
    .map((navItem) => {
      return navItem.netAssetValueComparison.map((item) => {
        return {
          ...item,
          rawDifference: new BigNumber(item.difference).toNumber(),
          product: navItem.product,
          portfolioId: navItem._id,
        };
      });
    })
    .flat();
};

const getInforBarCountData = (navReviewItems: NavReviewItemWithProduct[]): NavStatusBarInfo => ({
  total: navReviewItems.length,
  totalApproved: navReviewItems.filter(
    (curr) => curr.status === PortfolioCompositionStatus.APPROVED
  ).length,
  totalWithError: navReviewItems.filter(
    (curr) => curr.status === PortfolioCompositionStatus.PENDING
  ).length,
});

export const NavReviewPage = () => {
  const openModal = useAppModal();
  const [queryParams, setQueryParams] = useState<ParamsType>({
    valuationDate: format(new Date(), DEFAULT_DATE_FORMAT),
  });
  const {
    data: navPaginatedData,
    isLoading: loading,
    isFetching: fetching,
    refetch,
  } = useNavReviewDataQuery({ ...queryParams, pageSize: 1000 }); // TODO: Paginate nav-review
  const navReviewItems = useMemo(
    () => mapNavReviewItems(navPaginatedData?.data ?? []),
    [navPaginatedData?.data]
  );

  const openNavReviewModal = (type: MODAL_ACTIONS, navReviewItem?: NavReviewItem) => {
    openModal(
      {
        modalName: NAV_REVIEW_MODAL,
        modalData: {
          data: navReviewItem as unknown as NavReviewItem,
          type: type,
        },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );
  };

  const reviewBarInfoData = useMemo(
    () => getInforBarCountData(navReviewItems ?? []),
    [navReviewItems]
  );

  return (
    <StyledNavReviewPage>
      <NavReviewInforBar
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        overviewBarInfoData={navReviewItems.length ? reviewBarInfoData : null}
      />
      <NavReviewTable
        navReviewItems={navReviewItems ?? []}
        editAction={(navReviewItem) => openNavReviewModal(MODAL_ACTIONS.EDIT, navReviewItem)}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        loading={loading || fetching}
      />
    </StyledNavReviewPage>
  );
};
