import { NOTIFICATION_TIME } from 'shared/Notifications/Notifications.constants';
import Box from '@mui/material/Box';
import { styled as MuiStyled, keyframes as MuiKeyframes } from '@mui/material';
import { alpha } from '@mui/material/styles';
import fontTypes from 'utils/theme/designTokens/fontTypes';
import { ColorSchema } from 'theme/types/ThemeTypes';

export type NotificationColorSchema = Exclude<ColorSchema, 'secondary'>;
interface NotificationProps {
  multiline?: 'false' | 'true';
  highcontrast?: 'true' | 'false';
  withcountdown?: 'true' | 'false';
  variant?: NotificationColorSchema;
  actionText?: string;
  emphasis?: 'high' | 'low';
}

interface NotificationMessageProps {
  maxWidth: boolean;
}

interface MuiNotificationMessageProps {
  maxWidth?: string;
  gapBelow?: number;
  multiline?: string;
  variant?: NotificationColorSchema;
  emphasis?: 'high' | 'low';
}

const notificationTypeIconWidth = '18px';

const countdownAnimation = MuiKeyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
`;

export const StyledNotification = MuiStyled(Box)<NotificationProps>(({ theme, withcountdown }) => ({
  borderLeft: '4px solid',
  display: 'flex',
  flexDirection: 'row',

  '& .type-icon': {
    padding: theme.spacing(2), // Use theme.spacing for spacing values
    minWidth: notificationTypeIconWidth,
    paddingRight: 0,
  },

  '& .no-hover-button': {
    marginLeft: 'auto',

    '&:hover': {
      backgroundColor: 'inherit',
    },

    '&:focus': {
      border: 'none',
    },

    '& svg': {
      color: ({ highcontrast = 'false' }) =>
        highcontrast === 'true'
          ? alpha(theme.palette.text.primary, 0.85) // Use alpha for high contrast color
          : 'inherit',
    },
  },

  // Conditional styles based on the withCountdown prop
  ...(withcountdown === 'true' && {
    '&::after': {
      content: '""', // Double quotes for empty content
      display: 'block',
      height: '2px',
      width: '100%',
      opacity: 0.5,
      position: 'absolute',
      bottom: 0,
      left: 0,
      animation: `${countdownAnimation} ${NOTIFICATION_TIME}ms linear forwards`, // Dynamic animation name (ternary operator can be used too)
    },
  }),
}));

export const StyledDescription = MuiStyled(Box)<NotificationProps>`
  padding: ${({ theme }) => theme.spacers['spacing-16']};
  ${({ multiline = 'false', theme }) => {
    const isMultiline = multiline === 'true';
    return (
      !isMultiline && {
        display: 'flex',
        flexDirection: 'row',
        [`${StyledTitle}`]: { marginRight: theme.spacers['spacing-04'] },
      }
    );
  }};
`;

export const StyledTitle = MuiStyled(Box)<NotificationMessageProps>`
  ${() => fontTypes['heading-01']}
  :first-letter {
    text-transform: capitalize;
  }

  max-width: ${({ maxWidth }) => (maxWidth ? 'max-content' : '340px')};
  word-wrap: break-word;
`;
export const StyledMessage = MuiStyled(Box)<NotificationMessageProps>`
  ${() => fontTypes['body-01']};
  max-width: ${({ maxWidth }) => (maxWidth ? 'max-content' : '340px')};
  word-wrap: break-word;

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
`;

interface typeStylesProps {
  theme: any;
  highcontrast?: 'true' | 'false';
  type?: string;
}

const typeStyles = ({ theme, highcontrast = 'false', type = 'info' }: typeStylesProps) => ({
  borderColor:
    theme.palette.notificationsColorRange.border[highcontrast === 'true' ? 'secondary' : 'main'][
      type
    ],
  color: highcontrast === 'true' ? theme.palette.notificationsColorRange.color : 'inherit',
  background:
    highcontrast === 'true'
      ? theme.palette.notificationsColorRange.backgroundColor.main
      : theme.palette.notificationsColorRange.backgroundColor[type],
  '.type-icon': {
    fill: theme.palette.notificationsColorRange.border[
      highcontrast === 'true' ? 'secondary' : 'main'
    ][type],
  },
});

export const StyledInfoNotification = MuiStyled(StyledNotification)`
  ${({ theme }) => typeStyles({ theme, type: 'info' })}
  ::after {
    background-color: ${({ theme }) => theme.palette.blue[500]};
  }
`;
export const StyledSuccessNotification = MuiStyled(StyledNotification)`
  ${({ theme }) => typeStyles({ theme, type: 'success' })}
  ::after {
    background-color: ${({ theme }) =>
      theme.palette.notificationsColorRange.border.secondary.success};
  }
`;
export const StyledWarningNotification = MuiStyled(StyledNotification)`
  ${({ theme }) => typeStyles({ theme: theme, type: 'warning' })}
  ::after {
    background-color: ${({ theme }) =>
      theme.palette.notificationsColorRange.border.secondary.warning};
  }
`;
export const StyledErrorNotification = MuiStyled(StyledNotification)`
${({ theme }) => typeStyles({ theme: theme, type: 'error' })}
  ::after {
    background-color: ${({ theme }) =>
      theme.palette.notificationsColorRange.border.secondary.error};
  }
`;

export const StyledNotificationPopUpContainer = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacers['spacing-16']};
  position: fixed;
  right: 0;
  top: calc(${({ theme }) => theme.spacers['spacing-08']});
  z-index: 2000; // MUI pop-ups z-index is 1300. Giving this a big enough value not to be behind MUI pop-ups
`;

export const MuiStyledNotification = MuiStyled(Box)<NotificationProps>(
  ({
    theme,
    withcountdown = 'true',
    variant = 'info',
    multiline = 'false',
    actionText,
    emphasis = 'high',
  }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacers['spacing-16'],
    gap: theme.spacers['spacing-08'],
    minWidth: multiline === 'true' ? '400px' : '546px',
    borderRadius: '8px',
    borderBottom: theme.customComponents.navigation[variant][`${emphasis}Emphasis`].border,
    color: theme.customComponents.navigation[variant][`${emphasis}Emphasis`].color,
    background: theme.customComponents.navigation[variant][`${emphasis}Emphasis`].backgroundColor,
    alignItems: multiline === 'false' ? 'center' : 'flex-start',
    position: 'relative',
    ...(withcountdown === 'true' && {
      '&::after': {
        content: '""',
        display: 'block',
        height: '2px',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        animation: `${countdownAnimation} ${NOTIFICATION_TIME}ms linear forwards`,
      },
    }),
    '.type-icon': {
      padding: theme.spacers['spacing-08'],
      minWidth: '18px',
      svg: {
        fill:
          variant && theme.customComponents.navigation[variant][`${emphasis}Emphasis`].iconColor,
        width: '12px',
        height: '12px',
      },
      height: '24px',
      width: '24px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor:
        variant &&
        theme.customComponents.navigation[variant][`${emphasis}Emphasis`].iconBackgroundColor,
    },
    '.no-hover-button': {
      marginLeft:
        multiline === 'true' || (multiline === 'false' && !Boolean(actionText)) ? 'auto' : '0',
      alignItems: multiline === 'true' ? 'flex-start' : 'center',
      '&:hover': {
        backgroundColor: 'inherit',
      },
      '&:focus': {
        border: 'none',
      },
      svg: {
        fill: variant && theme.customComponents.navigation[variant][`${emphasis}Emphasis`].color,
      },
    },
    '.action-link': {
      marginLeft: multiline === 'true' ? '0' : 'auto',
    },
  })
);

export const MuiStyledDescription = MuiStyled(Box)<
  Omit<NotificationProps, 'highcontrast' | 'withcountdown'>
>(({ theme, multiline = 'false', actionText }) => ({
  padding: theme.spacers['spacing-04'],
  display: 'flex',
  gap: multiline === 'true' ? '8px' : '16px',
  flexDirection: multiline === 'true' ? 'column' : 'row',
  alignItems: multiline === 'true' ? '' : 'center',
  justifyContent: multiline === 'true' ? '' : 'center',
}));

export const MuiStyledTitle = MuiStyled(Box)<MuiNotificationMessageProps>(
  ({ theme, variant = 'info', maxWidth, multiline = 'false', emphasis = 'high' }) => ({
    paddingTop: multiline === 'true' ? theme.spacers['spacing-04'] : 0,
    color: theme.customComponents.navigation[variant][`${emphasis}Emphasis`].color,
    textTransform: 'capitalize',
    maxWidth: maxWidth,
    wordBreak: 'break-word',
    margin: '0 0',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '100%',
  })
);
export const MuiStyledMessage = MuiStyled(Box)<MuiNotificationMessageProps>(
  ({ theme, variant = 'primary', maxWidth, multiline = 'false', emphasis = 'high' }) => ({
    color: theme.customComponents.navigation[variant][`${emphasis}Emphasis`].color,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    maxWidth: maxWidth || '340px',
    wordBreak: 'break-word',
    marginBottom: multiline === 'true' ? theme.spacers['spacing-08'] : '',
    b: {
      fontWeight: theme.typography.fontWeightBold,
    },
    i: {
      fontStyle: 'italic',
    },
  })
);
