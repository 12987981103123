import { PropsWithChildren } from 'react';

import TabNavigation from 'components/TabNavigation';
import { TAB_TYPE } from 'components/TabNavigation/TabNavigation.types';

export const TOKEN_DETAILS_TAB = 'Token Details';
export const PARTNER_DETAILS_TAB = 'Partner Details';

interface TokensTabProps extends PropsWithChildren {
  active: string;
  onChangeTab?: (activeTab: string) => void;
}

function TokensTabs({ active, children, onChangeTab }: TokensTabProps) {
  const tabs = [
    {
      name: TOKEN_DETAILS_TAB,
      onSelect: () => {
        if (onChangeTab) {
          onChangeTab(TOKEN_DETAILS_TAB);
        }
      },
    },
    {
      name: PARTNER_DETAILS_TAB,
      onSelect: () => {
        if (onChangeTab) {
          onChangeTab(PARTNER_DETAILS_TAB);
        }
      },
    },
  ];

  return (
    <TabNavigation active={active} tabs={tabs} type={TAB_TYPE.CONTAINER}>
      {children}
    </TabNavigation>
  );
}

export default TokensTabs;
