import { Theme } from '@mui/material/styles';

export function card(theme: Theme) {
  return {
    // Card
    MuiCard: {
      styleOverrides: {
        root: {
          background: 'transparent',
          border: '1.25px solid #98ABB833',
          borderRadius: theme.spacing(2),
          position: 'relative',
        },
      },
    },
    // Header
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottom: '1.25px solid #98ABB833',
          padding: theme.spacing(2),
        },
      },
    },
    // Body
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
        },
      },
    },
    // Footer
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: '1.25px solid #98ABB833',
          padding: theme.spacing(2),
        },
      },
    },
  };
}
