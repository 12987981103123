import { Box, styled as MuiStyled } from '@mui/material';

export const StyledToolbar = MuiStyled(Box)`
  display: flex;
  align-content: flex-start;
  width: 100%;
  height: 56px;
`;

export const StyledButtonRow = MuiStyled(Box)`
  display: flex;
  width: 100%;
  gap: 8px;
  margin-bottom: 16px;
  justify-content: end;
`;
