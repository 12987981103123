import Loader from 'components/Loader';
import useAppDispatch from 'hooks/useAppDispatch';
import { DashboardContainer } from 'layouts/DashboardContainer/DashboardContainer';
import { FC, useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { fetchUser } from 'store/user/action';
import { useAuthenticatedUser } from 'store/user/selectors';
import { getUserLoggedOut, isLoggedIn } from 'utils/user/storage';
import { ReactComponent as IconClose } from 'assets/Navigation/close.svg';
import { StyledCloseRouteButton } from 'router/guards/styles';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { AppRoute } from 'router/types';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';

interface PrivateDashboardRouteProps {
  children: JSX.Element;
  route: AppRoute;
}

const PrivateDashboardRouteGuard: FC<PrivateDashboardRouteProps> = ({ children, route }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, loading, error } = useAuthenticatedUser();
  const isAuthenticated = isLoggedIn();
  const userDataLoading = useRef(false);

  useEffect(() => {
    if (!userDataLoading.current && isAuthenticated && !user) {
      userDataLoading.current = true;
      dispatch(fetchUser()).finally(() => {
        userDataLoading.current = false;
      });
    }
  }, [user, dispatch, isAuthenticated]);

  useEffect(() => {
    if (error) {
      const err = error as Error;
      dispatch(
        createNotification(
          errorNotification(typeof error === 'string' ? error : error?.message),
          err
        )
      );
    }
  }, [dispatch, error]);

  const element = isLoggedIn() ? (
    <DashboardContainer>
      {route.defaultPageHeader && (
        <PageHeader
          actionComponent={
            route.parentRoutePath ? (
              <StyledCloseRouteButton
                variant="tertiary"
                onClick={() => navigate(route.parentRoutePath ?? '')}
              >
                <IconClose />
              </StyledCloseRouteButton>
            ) : null
          }
          pageTitle={route.routeTitle}
        />
      )}
      {children}
    </DashboardContainer>
  ) : (
    <Navigate to={getUserLoggedOut() ? '/logged-out' : '/login'} replace />
  );

  return loading ? <Loader /> : element;
};

export default PrivateDashboardRouteGuard;
