/* eslint-disable complexity */
import Table, { TableHead, TableRow, TableCell } from 'components/Table';
import { CompanySuggestionDto } from 'utils/types';
import { capitalize } from 'lodash';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import formatFieldName from 'pages/Instruments/components/Form/EtpDetails/components/ReviewStep/utils/formatFieldName';
import { useTokenContext } from 'contexts/tokens';
import { renderPartners } from 'shared/Modals/TouchedProductModal/helpers';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { ProductConstituentAssetsProps, TokenConstituentAssetsProps } from 'utils/types/product';
import {
  PartnersStepDataProps,
  CustodiansListProps,
  WalletsList,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';
import { GeneralDetailsProps } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.types';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';

type StepsUnion = GeneralDetailsProps | PartnersStepDataProps;

const diffTableRow = (
  fieldName: string,
  deleteUpdatedField: (fieldName: string) => void,
  oldData: string = '-',
  newData: string = '-'
) => {
  return (
    <TableRow key={fieldName}>
      <TableCell>{formatFieldName(fieldName)}</TableCell>
      <TableCell>{oldData}</TableCell>
      <TableCell>{newData}</TableCell>
      <TableCell>
        <TrashIcon
          width={'32px'}
          onClick={() => {
            deleteUpdatedField(fieldName);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const getTokenPartnerTableRow = (
  fieldName: string,
  partners: CompanySuggestionDto[],
  oldFormData: StepsUnion,
  newFormData: StepsUnion,
  deleteUpdatedField: (fieldName: string) => void
) => {
  switch (fieldName) {
    case 'issuer':
      return diffTableRow(
        fieldName,
        deleteUpdatedField,
        partners?.find((partner) => partner._id === (oldFormData as PartnersStepDataProps)?.issuer)
          ?.name,
        partners?.find((partner) => partner._id === (newFormData as PartnersStepDataProps)?.issuer)
          ?.name
      );
    case 'authorizedParticipants':
      return diffTableRow(
        fieldName,
        deleteUpdatedField,
        renderPartners(
          (oldFormData as PartnersStepDataProps)?.authorizedParticipants,
          partners ?? []
        ),
        renderPartners(
          (newFormData as PartnersStepDataProps)?.authorizedParticipants,
          partners ?? []
        )
      );
    case 'custodians':
      return diffTableRow(
        fieldName,
        deleteUpdatedField,
        (oldFormData as PartnersStepDataProps)?.custodians?.map(capitalize).join(';'),
        (newFormData as PartnersStepDataProps)?.custodians?.map(capitalize).join(';')
      );
  }
};

export const TokenPartnerDetailsDifferencesSection = ({
  formDataDirtyFields,
  initialStepFormData,
  deleteUpdatedField,
}: {
  formDataDirtyFields: string[];
  initialStepFormData:
    | {}
    | ProductConstituentAssetsProps
    | TokenConstituentAssetsProps
    | GeneralDetailsProps
    | PartnersStepDataProps
    | CustodiansListProps
    | WalletsList
    | undefined;

  deleteUpdatedField: (fieldName: string) => void;
}) => {
  const { data: partnersData, isLoading: isLoadingPartners } = useCompaniesSuggestion();
  const { tokenFormsData, tokenActiveStep } = useTokenContext();

  return formDataDirtyFields.length ? (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell min-width="20%">Name</TableCell>
            <TableCell width="35%">Old Value</TableCell>
            <TableCell width="35%">Proposed Value</TableCell>
            <TableCell width="10%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBodyWithStates
          loadingData={isLoadingPartners}
          hasContent={Boolean(formDataDirtyFields.length)}
        >
          {formDataDirtyFields?.map(
            (selectedValue, index) =>
              initialStepFormData &&
              getTokenPartnerTableRow(
                selectedValue,
                partnersData ?? [],
                initialStepFormData as StepsUnion,
                tokenFormsData[tokenActiveStep] as StepsUnion,
                deleteUpdatedField
              )
          )}
        </TableBodyWithStates>
      </Table>
    </>
  ) : null;
};
