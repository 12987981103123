import GenericTabs from 'shared/GenericTabs';
import PCFTable from 'shared/Tables/PCFTable/PCFTable';
import UploadPCFModal from 'shared/Modals/PCF/UploadPCFModal';
import isFeatureEnabled, { FeatureFlags } from 'utils/featureFlag';
import useAppModal from 'hooks/useAppModal';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { DateTime } from 'utils/datetime';
import { MODAL_ACTIONS, PCF_UPLOAD_MODAL } from 'shared/Modals/constants';
import { PCFInforBar } from 'pages/Ledger/PCF/components/PCFInforBar';
import { PCFTabType, PCF_TABS, PCF_TAB_STATUS } from 'utils/constants/pcf';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { privateRoutesUrls } from 'router/constants';
import { useAuthenticatedUser, useUserPermissions } from 'store/user/selectors';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useNavigate, generatePath } from 'react-router-dom';
import { usePCFSQuery } from './usePCFSQuery';
import { useUrlParams } from 'hooks/useUrlParams';
import { PartnerType } from 'utils/types/partner';

const defaultParams = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_ITEMS_PER_PAGE,
  tab: PCF_TAB_STATUS.CURRENT,
  productId: null,
  sort: null,
};

export const PCFPage = () => {
  const openModal = useAppModal();
  const user = useAuthenticatedUser();

  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    defaultParams,
    privateRoutesUrls.dashboardRoutes.pcf
  );

  const { data, isLoading, refetch, isRefetching } = usePCFSQuery(urlParams, Boolean(user.user));

  const navigate = useNavigate();
  const permissions = useUserPermissions();
  const openUploadPCFModal = () => {
    openModal({
      modalName: PCF_UPLOAD_MODAL,
      modalData: {
        type: MODAL_ACTIONS.CREATE,
        data: null,
      },
    });
  };

  const tableTopButton = isFeatureEnabled(FeatureFlags.PCF_UPLOAD) && permissions?.canCreatePcf && (
    <>
      <StyledTableTopButton
        data-qa-id="createPcfButton"
        variant="interactive"
        size="medium"
        onClick={() => openUploadPCFModal()}
      >
        <span>Upload PCF</span>
        <PlusIcon />
      </StyledTableTopButton>
    </>
  );

  const pagination = useBackendPagination(data?.pagination ?? null, setUrlParams);

  return (
    <>
      <PageHeader actionComponent={tableTopButton} pageTitle={'Portfolio Composition Files'} />
      <GenericTabs
        active={urlParams.tab as string}
        setUrlParamsWithDefaults={setUrlParamsWithDefaults}
        tabs={PCF_TABS}
      >
        <PCFInforBar
          setUrlParams={setUrlParams}
          urlParams={urlParams}
          overviewBarInfoData={data?.overviewBarInfo}
          tab={urlParams.tab as PCFTabType}
          onDateChange={(startDate, endDate) => {
            const currentField =
              user.user?.organization.type === PartnerType.AUTHORIZED_PARTICIPANT
                ? 'effectiveDate'
                : 'valuationDate';
            const newURLParams = { ...urlParams };
            if (startDate) {
              newURLParams[`${currentField}Start`] = new DateTime(startDate).toDateString();
            } else {
              delete newURLParams[`${currentField}Start`];
            }
            if (endDate) {
              newURLParams[`${currentField}End`] = new DateTime(endDate).toDateString();
            } else {
              delete newURLParams[`${currentField}End`];
            }
            setUrlParams(newURLParams);
          }}
          date={urlParams.date as string}
        />
        <PCFTable
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          tableProperties={{
            pagination,
          }}
          pcfs={data?.data ?? []}
          loadingData={isLoading || isRefetching}
          editAction={(pcf) =>
            navigate(
              generatePath(privateRoutesUrls.dashboardRoutes.pcfDetails, {
                productId: pcf.product._id,
                valuationDate: pcf.valuationDate,
              })
            )
          }
          trading={data?.trading}
          notTrading={data?.notTrading}
        />
      </GenericTabs>
      <UploadPCFModal refetch={refetch} />
    </>
  );
};
