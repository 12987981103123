import { useEffect, useMemo } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

//types, constants
import { privateRoutesUrls } from 'router/constants';

// utils
import parseInstrumentToFormData from 'pages/Instruments/helpers/parseInstrumentToFormData';
import { getEtpDetailsUrl, getPartnerDetailsUrl } from 'pages/Instruments/components/Form/utils';

// components
import CircularProgress from 'components/CircularProgress';
import EtpDetails from 'pages/Instruments/components/Form/EtpDetails/ETPDetails';
import InstrumentsTabs, {
  INSTRUMENT_DETAILS_TAB,
  PARTNER_DETAILS_TAB,
} from 'pages/Instruments/components/ETPTabs/ETPTabs';
import PartnerDetails from 'pages/Instruments/components/Form/PartnerDetails';
import { Column, Row } from 'components/Grid';

// hooks
import { useCurrentInstrument } from 'store/instruments/selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { instrumentsActions } from 'store/instruments/slice';
import { GENERAL_DETAILS_STEP } from './EtpDetails/EtpDetails.steps';
import { PARTNERS_STEP } from './PartnerDetails/PartnerDetails.steps';

function renderCurrentTab(activeTab: typeof INSTRUMENT_DETAILS_TAB | typeof PARTNER_DETAILS_TAB) {
  const tabs = {
    [INSTRUMENT_DETAILS_TAB]: EtpDetails,
    [PARTNER_DETAILS_TAB]: PartnerDetails,
  };
  const Tab = tabs[activeTab];
  return <Tab />;
}

function ETPForm() {
  const { instrument, loading } = useCurrentInstrument();
  const dispatch = useAppDispatch();
  const { updateActiveStep } = instrumentsActions;
  const navigate = useNavigate();
  const params = useParams();
  const insturmentFormsData = useMemo(() => parseInstrumentToFormData(instrument), [instrument]);
  const isCreateEtpDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.newEtpProductsEtpDetails)
  );
  const isEditEtpDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.editEtpProductsEtpDetails)
  );
  const isEtpDetailsRoute = isCreateEtpDetailsRoute || isEditEtpDetailsRoute;
  const isLoadingFormData = loading || (!isEmpty(instrument) && isEmpty(insturmentFormsData));
  const activeTab = isEtpDetailsRoute ? INSTRUMENT_DETAILS_TAB : PARTNER_DETAILS_TAB;

  useEffect(() => {
    if (activeTab === INSTRUMENT_DETAILS_TAB) {
      dispatch(updateActiveStep(GENERAL_DETAILS_STEP));
    } else {
      dispatch(updateActiveStep(PARTNERS_STEP));
    }
  }, [activeTab, dispatch, updateActiveStep]);

  const handleChangeTab = (tab: string) => {
    if (tab === INSTRUMENT_DETAILS_TAB) {
      navigate(getEtpDetailsUrl(params.id));
    } else {
      navigate(getPartnerDetailsUrl(params.id));
    }
  };

  return (
    <InstrumentsTabs active={activeTab} onChangeTab={handleChangeTab}>
      {isLoadingFormData ? (
        <Row>
          <Column offset={5}>
            <CircularProgress />
          </Column>
        </Row>
      ) : (
        renderCurrentTab(activeTab)
      )}
    </InstrumentsTabs>
  );
}

export default ETPForm;
