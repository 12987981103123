import { useEffect, useState } from 'react';
import PusherClient from 'utils/adapters/pusher';
import useAppDispatch from 'hooks/useAppDispatch';
import { PusherNotificationMessage } from 'utils/types/pusher';
import { transform } from 'shared/Notifications/pusher.notifications';
import { createNotification } from 'store/notifications/actions';
import { isLoggedIn } from 'utils/user/storage';
import { useLocation } from 'react-router-dom';

export const usePusherInitializer = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isNotificationServiceConnected, setIsNotificationServiceConnected] =
    useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn() && !PusherClient.isConnected()) {
      PusherClient.initialize(
        () => setIsNotificationServiceConnected(true),
        (msg: PusherNotificationMessage) => dispatch(createNotification(transform(msg)))
      );
      console.log('Listening for notifications...');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationServiceConnected, location]);
};
