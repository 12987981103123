import GenericTabs from 'shared/GenericTabs';
import { generatePath, useNavigate } from 'react-router-dom';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ProductsTable } from 'shared/Tables/ProductsTable/ProductsTable';
import { Instrument, ProductStatus, Token } from 'utils/types/product';
import {
  INSTRUMENTS_TAB_STATUS,
  INSTRUMENTS_TAB_STATUS_LABEL,
  ProductTabType,
} from 'pages/Instruments/Instruments.constants';
import { PageHeader } from 'layouts/DashboardContainer/components/PageLayout/PageHeader';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { privateRoutesUrls } from 'router/constants';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useETPsBaseDataQuery } from 'pages/Instruments/hooks/useETPsBaseDataQuery';
import { useUrlParams } from 'hooks/useUrlParams';
import { useUserPermissions } from 'store/user/selectors';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import useAppModal from 'hooks/useAppModal';
import { ETP_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { getProductStatus } from './helpers/getProductStatus';
import { formatETPTableURLparams } from './helpers/formatETPTableURLparams';

function Products() {
  const navigate = useNavigate();
  const openModal = useAppModal();
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    {
      tab: INSTRUMENTS_TAB_STATUS.ACTIVE,
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_ITEMS_PER_PAGE,
      status: ProductStatus.ACTIVE,
    },
    privateRoutesUrls.dashboardRoutes.etps
  );
  const {
    data: ETPsRes,
    isLoading: loading,
    refetch,
  } = useETPsBaseDataQuery(formatETPTableURLparams(urlParams));
  const ETPs = ETPsRes?.data;
  const permissions = useUserPermissions();
  const pagination = useBackendPagination(ETPsRes?.pagination ?? null, setUrlParams);
  const openEdit = async (product: Instrument | Token) => {
    navigate(
      generatePath(privateRoutesUrls.dashboardRoutes.editEtpProductsEtpDetails, {
        id: product._id,
      })
    );
  };

  const openETPModal = (type: MODAL_ACTIONS, ETP?: Instrument, archive?: Boolean) => {
    openModal(
      {
        modalName: ETP_MODAL,
        modalData: {
          data: ETP,
          type: type,
          custom: {
            type: archive ? ProductStatus.ARCHIVED : null,
          },
        },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );
  };

  const createETPButton = [
    permissions?.canCreateEtp && (
      <StyledTableTopButton
        onClick={() => navigate(privateRoutesUrls.dashboardRoutes.newEtpProductsEtpDetails)}
        type="button"
        data-qa-id="createETPButton"
        size="medium"
        key="toolbar-button"
      >
        <span>New ETP</span>
        <PlusIcon />
      </StyledTableTopButton>
    ),
  ];

  return (
    <>
      <PageHeader actionComponent={createETPButton} pageTitle={'ETPs'} />
      <GenericTabs
        active={urlParams.tab as string}
        setUrlParamsWithDefaults={(params) => {
          setUrlParamsWithDefaults({
            status: getProductStatus(params.tab as ProductTabType),
            ...params,
          });
        }}
        tabs={[
          [INSTRUMENTS_TAB_STATUS_LABEL.ACTIVE, INSTRUMENTS_TAB_STATUS.ACTIVE],
          [INSTRUMENTS_TAB_STATUS_LABEL.IN_REVIEW, INSTRUMENTS_TAB_STATUS.IN_REVIEW],
          [INSTRUMENTS_TAB_STATUS_LABEL.PENDING, INSTRUMENTS_TAB_STATUS.DRAFT],
          [INSTRUMENTS_TAB_STATUS_LABEL.DELETED, INSTRUMENTS_TAB_STATUS.DELETED],
        ]}
      >
        <ProductsTable
          productType="ETP"
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          pagination={pagination}
          editAction={openEdit}
          activateAction={(etp, createSeedOrder) =>
            openETPModal(
              createSeedOrder ? MODAL_ACTIONS.CUSTOM : MODAL_ACTIONS.CREATE,
              etp as Instrument
            )
          }
          deleteAction={(ETP) => openETPModal(MODAL_ACTIONS.DELETE, ETP as Instrument)}
          archiveAction={(ETP) => openETPModal(MODAL_ACTIONS.DELETE, ETP as Instrument, true)}
          productList={ETPs ?? []}
          loading={loading}
        />
      </GenericTabs>
    </>
  );
}

export default Products;
