export const getCalendarsQuery = 'getCalendarsQuery';
export const getCitiesOptionsQuery = 'getCitiesOptionsQuery';
export const getCompaniesQuery = 'requestCompanies';
export const getCompanyQuery = 'requestCompany';
export const getContactListContactsQuery = 'contactListContacts';
export const getContactsListsQuery = 'getContactListsQuery';
export const getCountriesOptionsQuery = 'getCountriesOptionsQuery';
export const getCurrentOrderQueryKey = 'getCurrentOrderQueryKey';
export const getEtpsBaseDataQuery = 'getEtpsBaseDataQuery';
export const getNavReviewDataQuery = 'getNavReviewDataQuery';
export const getNavReviewItemProductQuery = 'getNavReviewItemProductQuery';
export const getNavReviewItemQuery = 'getNavReviewItemQuery';
export const getNavReviewItemRebookEstimation = 'getNavReviewItemRebookEstimation';
export const getOrderDetailsQueryKey = 'getOrderDetailsQueryKey';
export const getOrdersQueryKey = 'getOrdersQueryKey';
export const getPortfolioCompositionQuery = 'getPortfolioCompositionQuery';
export const getRegistryQuery = 'getRegistryQuery';
export const getSingleRegisterQuery = 'getSingleRegisterQuery';
export const getUsersPerCompanyQuery = 'getUsersPerCompanyQuery';
export const getWalletsPerCompanyQuery = 'getWalletsPerCompanyQuery';
export const getWalletsQuery = 'getWalletsQuery';
export const instrumentCustodiansQuery = 'instrumentCustodiansQuery';
export const instrumentWalletsQuery = 'instrumentWalletsQuery';
export const postNavReviewItemQuery = 'postNavReviewItemQuery';
export const getETPExchanges = 'getETPExchanges';
export const usePCFsQuery = 'usePCFsQuery';
export const getAllContacts = 'getAllContacts';
export const getAllExternalContacts = 'getAllExternalContacts';
export const getAllPartnerContacts = 'getAllPartnerContacts';
export const getAllRebalances = 'getAllRebalances';
export const getRebalanceDetails = 'getRebalanceDetails';
export const getExternalConstituentAssets = 'getExternalConstituentAssets';
export const getExistingAssets = 'getExistingAssets';
export const ETPsQuery = 'ETPsQuery';
export const getCompanySuggestionQuery = 'getCompanySuggestionQuery';

/* ---
TOKENS
--- */
export const getTokensQuery = 'getTokensQuery';
export const postTokenQuery = 'postTokenQuery';
export const patchTokenQuery = 'patchTokenQuery';
export const getTokenQueryString = 'getTokenQueryString';
export const tokenWalletsQuery = 'tokenWalletsQuery';
export const tokenCustodiansQuery = 'tokenCustodiansQuery';
export const getTokenOrdersQueryKey = 'getTokenOrdersQueryKey';
export const getTokenOrderDetailsQueryKey = 'getTokenOrderDetailsQueryKey';
export const getTokenAuthorizedMerchantsQueryKey = 'getTokenAuthorizedMerchantsQueryKey';
export const getCurrentTokenOrderQueryKey = 'getCurrentTokenOrderQueryKey';
