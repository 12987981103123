//constants
import { userCreatedNotifications } from 'shared/Notifications/users.notifications';
//hooks
import useAppDispatch from 'hooks/useAppDispatch';
//store
import { createNotification } from 'store/notifications/actions';
//types
import { IModal } from 'shared/Modals/types';
import { createEntityApiCallWithCustomResponse } from 'utils/api/crudActions';
import { useCallback, useState } from 'react';
import { SetupQRCode } from 'shared/CompositeComponents/2FASetupFlow/SetupQRCode';
import { Passcode } from 'shared/CompositeComponents/2FASetupFlow/Passcode';
import { Request2FAResponse } from 'utils/types/auth';
import { ResetOwn2FAInitialScreen } from 'shared/CompositeComponents/2FASetupFlow/ResetOwn2FAInitialScreen';
import { SuccessfullSetup } from 'shared/CompositeComponents/2FASetupFlow/SuccessfullSetup';
import { Styled2FAResetModalWrapper } from './ResetOwn2FAModal.styles';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { enable2fa } from 'utils/api/auth';
import { storeStrongToken } from 'utils/user/storage';

export interface UserForm {
  firstName: string;
  lastName: string;
  email: string;
  role?: string;
  replaceContact?: Boolean;
}

type Reset2FaFlowScreens = 'BACKUP_CODE' | 'SETUP_QR_CODE' | 'PASSCODE' | 'SUCCESFULL_SETUP';

export const ResetOwn2FAModal = ({ onCloseModalAction }: IModal) => {
  const dispatch = useAppDispatch();
  const [twoFARequest, setTwoFARequest] = useState<Request2FAResponse>();
  const [twoFaFlowScreen, setTwoFaFlowScreen] = useState<Reset2FaFlowScreens>('BACKUP_CODE');
  const [twoFAOTPBackupCode, setTwoFAOTPBackupCode] = useState<string>('');

  const closeModal = () => {
    onCloseModalAction && onCloseModalAction();
  };

  const handleFinishSetup = () => {
    dispatch(
      createNotification(successNotification(`Your 2FA has been successfully reset!`, '2FA reset'))
    );
    onCloseModalAction && onCloseModalAction();
  };

  const handleBackupCodeRequestSent = useCallback(
    (otpVal: string) => {
      createEntityApiCallWithCustomResponse<{ otpBackupCode: string }, Request2FAResponse>(
        'auth/2fa/reset',
        { otpBackupCode: otpVal }
      )
        .then((res) => {
          setTwoFARequest(res.data);
          setTwoFaFlowScreen('SETUP_QR_CODE');
        })
        .catch((error) => {
          dispatch(createNotification(userCreatedNotifications.error(error.message), error));
        });
    },
    [dispatch, onCloseModalAction]
  );

  const handleQRPasscodeSaved = () => {
    setTwoFaFlowScreen('PASSCODE');
  };

  const handleVerifyPasscode = useCallback(
    (enablePasscodeOtp: number) => {
      enable2fa(twoFARequest?._actions.enable2fa.uri || '', enablePasscodeOtp)
        ?.then((res) => {
          setTwoFAOTPBackupCode(res.data.otpBackupCode);
          storeStrongToken(res.data.strongToken);
          setTwoFaFlowScreen('SUCCESFULL_SETUP');
        })
        .catch((err) => {
          dispatch(createNotification(errorNotification(err.message, err.name), err));
        });
    },
    [dispatch, twoFARequest?._actions.enable2fa.uri]
  );

  const Reset2faScreens = {
    BACKUP_CODE: (
      <ResetOwn2FAInitialScreen
        handleClose={() => closeModal()}
        handleUpdate={(val) => {
          handleBackupCodeRequestSent(val);
        }}
      />
    ),
    SETUP_QR_CODE: twoFARequest ? (
      <SetupQRCode
        twoFARequest={twoFARequest}
        handleNext={handleQRPasscodeSaved}
        handleSkip={closeModal}
      />
    ) : null,
    PASSCODE: <Passcode handleVerifyPasscode={handleVerifyPasscode} />,
    SUCCESFULL_SETUP: (
      <SuccessfullSetup
        handleFinishSetup={handleFinishSetup}
        twoFAOTPBackupCode={twoFAOTPBackupCode}
      />
    ),
  };

  return (
    <Styled2FAResetModalWrapper
      open
      onCloseModal={() => twoFaFlowScreen === 'BACKUP_CODE' && closeModal()}
    >
      {Reset2faScreens[twoFaFlowScreen]}
    </Styled2FAResetModalWrapper>
  );
};
