// components
import Button from 'components/Button';

import Tooltip from 'components/Tooltip';
// assets
import { ReactComponent as SvgXls } from 'assets/file-xls.svg';
// styles
import {
  StyledBottomRowRight,
  StyledToolbarsWrapper,
  StyledToollbarBottomRow,
} from 'pages/Orders/Orders/Orders.styles';
import { useEffect, useState } from 'react';
import Search from 'components/Search';
import { format, isValid, parse } from 'date-fns';
import DateRangePicker, { DateRangeValue } from 'components/Datepicker';

import { OrdersFiltersData } from 'utils/constants/orders';
import { OrdersFilters } from './OrdersFilters';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { ProductType } from 'utils/types/product';
import { Box } from '@mui/material';

type Props = {
  productType: ProductType;
  generateOrdersAsXls: (filteredBy: any) => void;
  setUrlParams: React.Dispatch<React.SetStateAction<OrdersFiltersData>>;
  urlParams: OrdersFiltersData;
  disableDownloadButton: boolean;
};

const OrdersTableToolbar = ({
  productType,
  generateOrdersAsXls,
  setUrlParams,
  urlParams,
  disableDownloadButton,
}: Props) => {
  const [search, setSearch] = useState(urlParams.search ?? '');
  const [searchInputValue, setSearchInputValue] = useState(urlParams.search ?? '');
  const [dateFilter, setDateFilter] = useState<DateRangeValue>([
    urlParams.startDate ? parse(urlParams.startDate, DEFAULT_DATE_FORMAT, new Date()) : null,
    urlParams.endDate && parse(urlParams.endDate, DEFAULT_DATE_FORMAT, new Date())
      ? parse(urlParams.endDate, DEFAULT_DATE_FORMAT, new Date())
      : null,
  ]);
  const [startDate, endDate] = dateFilter;

  useEffect(() => {
    setUrlParams((prevState) => ({
      ...prevState,
      search: search,
      startDate: startDate && isValid(startDate) ? format(startDate, DEFAULT_DATE_FORMAT) : null,
      endDate: endDate && isValid(endDate) ? format(endDate, DEFAULT_DATE_FORMAT) : null,
    }));
  }, [dateFilter, search, setUrlParams]);

  return (
    <StyledToolbarsWrapper>
      <StyledToollbarBottomRow>
        <Search
          variant="short"
          placeholder="Search after order-ID"
          value={searchInputValue}
          onChange={(event) => setSearchInputValue(event.target.value)}
          onBlur={() => setSearch(searchInputValue)}
          onKeyDown={(event) => event.key === 'Enter' && setSearch(searchInputValue)}
          onClear={() => setSearch('')}
        />
        <StyledBottomRowRight>
          <OrdersFilters
            productType={productType}
            setUrlParams={setUrlParams}
            urlParams={urlParams}
          />
          <DateRangePicker
            onChange={setDateFilter}
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
          />
          <Tooltip
            placement="top"
            title={<Box sx={{ padding: 1, color: 'white' }}>Download selected orders XLS</Box>}
          >
            <Button
              onClick={() => generateOrdersAsXls(urlParams?.status)}
              variant="ghost"
              disabled={disableDownloadButton}
            >
              <SvgXls />
            </Button>
          </Tooltip>
        </StyledBottomRowRight>
      </StyledToollbarBottomRow>
    </StyledToolbarsWrapper>
  );
};

export default OrdersTableToolbar;
