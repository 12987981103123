import { Actions, BaseEntry, Link, Order } from 'utils/types';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';

export const NavReviewStateLabel: Record<string, string> = {
  APPROVED: 'Approved',
  PENDING: 'Discrepancies',
};

export interface PortfolioCompositionProduct {
  _id: string;
  issuer: {
    id: string;
  };
  fundIcon?: string;
  baseCurrency: string;
  isin: string;
  name: string;
  ticker: string;
  unitSize: number;
}

export interface NavReviewItem {
  readonly _id: string;
  readonly openOrders?: Order[];
  readonly ticker: string;
  readonly ledgerBalance: number;
  readonly fundAccountantBalance: number;
  readonly difference: number;
  readonly status: PortfolioCompositionStatus;
  readonly transactions?: TransactionLedger[];
  readonly totals?: NetAssetValueTotals;
  readonly fundAccountantTotals?: NetAssetValueTotals;
  readonly _links?: {
    readonly self?: Link;
  };
  readonly _actions?: {
    readonly approve?: Actions;
    readonly calculateNetAssetValue?: Actions;
    readonly rebook?: Actions;
    readonly rebookEstimation: Actions;
  };
  isRebalanceApproved?: boolean;
}

export interface NavReviewResponseDto {
  netAssetValueComparison: NavReviewItem[];
  product: PortfolioCompositionProduct;
  _id: string;
}

export interface NavReviewItemWithProduct extends NavReviewItem {
  readonly product: PortfolioCompositionProduct;
  readonly portfolioId: string;
}

export interface CompositionBalance extends BaseEntry {
  ticker: string;
  balance: number;
  readonly approvedAt: string;
}

export enum TransactionLedgerType {
  STAKE = 'STAKE',
  ORDER_CREATION = 'ORDER_CREATION',
  ORDER_REDEMPTION = 'ORDER_REDEMPTION',
  FEE = 'FEE',
}

export enum TransactionSource {
  ONYX_ORDERS = 'onyx-orders',
  ONYX_STAKING = 'onyx-staking',
  ONYX_FEES = 'onyx-fees',
  REBALANCE = 'rebalance',
  REBOOK = 'rebook',
}

export interface OnyxMetadata {
  orderId?: string;
  tradeId?: string;
  productId?: string;
  rewardId?: string;
  cancelledAt?: Date;
  cancels?: string;
  reason?: string;
  rewardCustodian?: string;
}

export interface RebookEstimation {
  overrideBalance: string | number;
  reason: string;
}

export interface TransactionLedger extends BaseEntry {
  accountId: string;
  amount: string;
  createdAt: string;
  currency: string;
  externalId: string;
  orderId?: string;
  source: TransactionSource;
  type: TransactionType;
  onyx: OnyxMetadata;
  _id: string;
}

export interface NetAssetValueTotals {
  readonly deltaNetAssetValue?: number;
  readonly managementFee?: number;
  readonly netOrders?: number;
  readonly openBalance?: number;
  readonly tradeValue?: number;
  readonly stakingFees?: number;
  readonly stakingRewards?: number;
}

export enum TransactionType {
  ETP_CREATION = 'ETP_CREATION',
  ETP_REDEMPTION = 'ETP_REDEMPTION',
  EARN_REWARD = 'EARN_REWARD',
  EARN_FEE = 'EARN_FEE',
  REBOOK_BALANCE_ADJUST = 'REBOOK_BALANCE_ADJUST',
}

export enum NavReviewItemsRows {
  OPENING_NAV = 'Opening NAV',
  MANAGEMENT_FEE = 'Management Fees',
  NET_ORDERS = 'Net Orders',
  STAKING_FEES = 'Staking Fees',
  STAKING_REWARDS = 'Staking Rewards',
  REBALANCE_TRADES = 'Rebalance Trades',
  REBOOK_BALANCE_ADJUST = 'NAV Review Adjustment',
}

export interface NavStatusBarInfo {
  total: number;
  totalApproved: number;
  totalWithError: number;
}
