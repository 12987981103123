import Button from 'components/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { IModalWithData } from 'shared/Modals/types';
import { ProductExchangeModalData } from 'shared/Modals/ProductExchange/ProductExchangeModal';
import { ProductStatus } from 'utils/types/product';
import { useCurrentInstrument, useCurrentInstrumentFormData } from 'store/instruments/selectors';
import { useMutation } from 'react-query';
import { saveInstrumentApiCall } from 'utils/api/instruments';
import { EXCHANGE_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { instrumentsActions } from 'store/instruments/slice';
import {
  ExchangeType,
  ExchangesProps,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import Card from 'components/Card';
import { CustomModal } from 'shared/Modals/Modal';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';

export const DeleteProductExchangeModal = ({
  onCloseModalAction,
  closeModal,
  data,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const { instrument } = useCurrentInstrument();
  const productExchangesFormData = useCurrentInstrumentFormData(EXCHANGE_STEP) as ExchangesProps;
  const { exchangeFormData: exchangeToDelete } = data?.data as ProductExchangeModalData;
  const { populateCurrentInstrument } = instrumentsActions;

  const updateProductExchangeListMutation = useMutation({
    mutationFn: (updatedExchangesList: ExchangeType[]) => {
      return saveInstrumentApiCall(instrument?._id ?? '', {
        [EXCHANGE_STEP]: updatedExchangesList as ExchangeType[],
      });
    },
    onSuccess: (res) => {
      dispatch(populateCurrentInstrument(res));
      dispatch(
        createNotification({
          message: 'Product exchange has been removed from the product.',
          title: 'Product Exchanges List Updated',
          type: 'success',
        })
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(errorNotification(err.message ?? ''), err));
    },
  });

  const handleSubmit = () => {
    if (exchangeToDelete?.isPrimary) {
      dispatch(
        createNotification({
          message: 'You can not delete Primary Exchange.',
          title: 'Product Exchanges List Update',
          type: 'error',
        })
      );
      return;
    }
    const updatedExchangesList = productExchangesFormData.filter((el) => {
      return el.id !== String(exchangeToDelete?.id);
    });

    if (!updatedExchangesList.length && instrument?.status === ProductStatus.ACTIVE) {
      dispatch(
        createNotification({
          message: 'Product needs to have at least one exchange',
          title: 'Product Exchanges List Update',
          type: 'error',
        })
      );
      return;
    }

    updateProductExchangeListMutation.mutate(updatedExchangesList);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
      <Button
        fullWidth
        onClick={handleSubmit}
        type="button"
        variant="interactive"
        isLoading={updateProductExchangeListMutation.isLoading}
      >
        {instrument?.status === ProductStatus.ACTIVE ? 'Remove and Save' : 'Remove'}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        footer={Footer}
        title="Are you sure you want to permanently remove this exchange and all associated tickers?"
        label={exchangeToDelete?.name}
        onClose={closeModal}
      />
    </CustomModal>
  );
};
