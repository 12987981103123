import { isPast } from 'date-fns';

// constants, types
import { DEFER_ORDER_MODAL, ORDERS_MODAL } from 'shared/Modals/constants';
import { ActiveModalsType } from 'shared/Modals/types';
import { Deliverable, Order, OrderStatus } from 'utils/types/orders';
import { variantType } from 'components/Button/types';

// hooks
import { useOrdersActions } from 'pages/Orders/Orders/hooks/useOrdersActions';
import { useUserPermissions } from 'store/user/selectors';

// utils
import getCloseTime from 'pages/Orders/Orders/utils/getCloseTime';
import { ProductType } from 'utils/types/product';

export type Action = {
  callback: (actualDeliverables?: Deliverable[]) => void;
  label: string;
  modal: ActiveModalsType;
  permission: boolean;
  status: OrderStatus;
  variant?: variantType;
};

export const useButtonsActions = (
  order: Order,
  refreshQueryKey?: string,
  productType: ProductType = 'ETP'
) => {
  const userPermissions = useUserPermissions();
  const { confirmAction, deferAction, readyAction, settleAction, cancelAction } = useOrdersActions(
    order._id,
    refreshQueryKey,
    productType
  );
  const closeTime = getCloseTime(
    order?.product?.cutoffs,
    order?.product?.timezone,
    order?.deliveryType
  );
  const isClosed = isPast(closeTime);

  const isApMarketOpen = !userPermissions?.isAuthorizedParticipant || !isClosed;

  const actions: Action[] = [
    {
      modal: ORDERS_MODAL,
      permission: Boolean(order?._actions?.confirm),
      callback: confirmAction,
      label: 'Confirm',
      status: OrderStatus.CONFIRMED,
    },
    {
      modal: ORDERS_MODAL,
      permission: Boolean(order?._actions?.ready),
      callback: readyAction,
      label: 'Ready',
      status: OrderStatus.READY,
    },
    {
      modal: ORDERS_MODAL,
      permission: Boolean(order?._actions?.settle),
      callback: settleAction,
      label: 'Settle',
      status: OrderStatus.SETTLED,
    },
    {
      modal: ORDERS_MODAL,
      permission: Boolean(order?._actions?.cancel) && isApMarketOpen,
      callback: cancelAction,
      variant: 'tertiary',
      label: 'Cancel Order',
      status: OrderStatus.CANCELED,
    },
    {
      modal: DEFER_ORDER_MODAL,
      permission: Boolean(order?._actions?.defer) && !Boolean(order?.bookedAt),
      callback: deferAction,
      variant: 'tertiary',
      label: 'Defer Settlement',
      status: OrderStatus.CANCELED,
    },
  ];

  return actions;
};
